.ms-password-reset-verification {
    &__heading {
        @extend %heading-b2c;
    }

    &__email-verification-buttons {
        margin-top: $margin-y-button-b2c * 2;
        margin-bottom: $margin-y-button-b2c;
    }

    &__continue-button {
        @extend %button-b2c-primary;
        margin-top: $margin-y-button-b2c;
    }

    &__email-verification-button,
    &__cancel-button {
        @extend %button-b2c-secondary;
    }

    &__verify-email-edit {
        display: none !important;
    }
}
