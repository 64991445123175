.msc-order-summary {
    &__header,
    &__heading {
        @extend %heading-order-summary;
    }

    &__items {
        @extend %text-order-summary-items;

        > * {
            display: flex;
            margin-bottom: 0;

            > *:first-child {
                flex: 1;
                margin-right: $margin-right-container-order-summary-left;
            }

            > *:last-child {
                @include flex-width($width-container-order-summary-right);
                text-align: $text-align-container-order-summary-right;
            }
        }
    }

    &__line-total {
        @extend %text-order-summary-total;
        margin-top: $margin-top-text-order-summary-total;
    }

    &__shipment-warning {
        @extend %text-order-summary-warning;
    }

    &__line-conditions {
        @extend %text-order-summary-conditions;
        margin-top: $margin-top-text-order-summary-conditions;
    }
}
