@mixin input(
    $padding: $padding-input,
    $border-width: $border-width-input,
    $border-style: $border-style-input,
    $border-radius: $border-radius-input,

    $color__border: $color-input__border,
    $color__background: $color-input__background,
    $color__text: $color-input__text,

    $font-family: $font-family-input,
    $font-size: $font-size-input,
    $font-size__tablet: $font-size-input__tablet,
    $font-size__mobile: $font-size-input__mobile,
    $font-weight: $font-weight-input,
    $font-style: $font-style-input,
    $letter-spacing: $letter-spacing-input,
    $line-height: $line-height-input,
    $line-height__tablet: $line-height-input__tablet,
    $line-height__mobile: $line-height-input__mobile,
    $text-transform: $text-transform-input
) {
    @extend .form-control;
    height: auto;
    padding: $padding;
    border-radius: $border-radius;
    border-width: $border-width;
    border-style: $border-style;
    border-color: $color__border;
    background-color: $color__background;
    background-clip: border-box;
    color: $color__text;
    font-family: $font-family;
    font-size: $font-size__mobile;
    font-weight: $font-weight;
    font-style: $font-style;
    letter-spacing: $letter-spacing;
    line-height: $line-height__mobile;
    text-transform: $text-transform;

    @include media-breakpoint-up(sm) {
        font-size: $font-size__tablet;
        line-height: $line-height__tablet;
    }

    @include media-breakpoint-up(xl) {
        font-size: $font-size;
        line-height: $line-height;
    }

    /* Prevents auto-zoom on iOS */
    @supports (-webkit-overflow-scrolling: touch) {
        font-size: 16px !important;
    }

    &:focus {
        background-color: $color__background;
        color: $color__text;
    }

    &::placeholder {
        color: $color-darkbrown;
    }
}

@mixin input-padding($padding) {
    padding: $padding;
}

@mixin input-border(
    $color__border,
    $border-width: 1px,
    $border-style: solid,
) {
    border-width: $border-width;
    border-style: $border-style;
    border-color: $color__border;
}

@mixin input-size(
    $padding,
    $border-width,
    $font-size,
    $font-size__tablet: $font-size,
    $font-size__mobile: $font-size
) {
    padding: $padding;
    border-width: $border-width;
    font-size: $font-size__mobile;

    @include media-breakpoint-up(sm) {
        font-size: $font-size__tablet;
    }

    @include media-breakpoint-up(xl) {
        font-size: $font-size;
    }
}

@mixin input-width($width) {
    width: $width;
}

@mixin input-width-auto {
    width: auto;
}

@mixin input-max-width($max-width) {
    max-width: $max-width;
}

@mixin input-height($height) {
    height: $height;
}

@mixin input-font-weight($font-weight) {
    font-weight: $font-weight;
}

@mixin input-line-height($line-height, $line-height__tablet: $line-height, $line-height__mobile: $line-height) {
    line-height: $line-height__mobile;

    @include media-breakpoint-up(sm) {
        line-height: $line-height__tablet;
    }

    @include media-breakpoint-up(xl) {
        line-height: $line-height;
    }
}

@mixin input-text-align($text-align) {
    text-align: $text-align;
}
