// Carousel Dismiss
$icon-carousel-dismiss: $icon-cancel;
$color-border-carousel-dismiss: $color-black;
$color-background-carousel-dismiss: $color-black;
$color-icon-carousel-dismiss: $color-white;
$font-size-icon-carousel-dismiss: 20px;
$vertical-align-icon-carousel-dismiss: text-bottom;
$text-align-icon-carousel-dismiss: center;

// Carousel Transition
$transition-duration-carousel: 0.6s;
$transition-carousel: transform $transition-duration-carousel ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
$transition-carousel-base: all 0.2s ease-in-out;
$transition-carousel-fade: opacity 0.15s linear;
$transition-carousel-collapse: height 0.35s ease;
$transition-carousel-enabled: true;
$prefers-reduced-motion-media-query-carousel-enabled: true;

@mixin transition-carousel($transition...) {
    @if $transition-carousel-enabled {
        @if length($transition) == 0 {
            transition: $transition-carousel-base;
        } @else {
            transition: $transition;
        }
    }

    @if $prefers-reduced-motion-media-query-carousel-enabled {
        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }
}

// Carousel Control
$z-index-container-carousel-control: 16;
$top-container-carousel-control: calc(50% - 24px);
$width-container-carousel-control: 48px;
$height-container-carousel-control: $width-container-carousel-control;
$margin-x-container-carousel-control: 2px;
$outline-container-carousel-control--hover: 1px solid $color-black;
$outline-container-carousel-control-before--hover: 1px solid $color-black;
$opacity-container-carousel-control: 1;
$opacity-container-carousel-control--hover: 0.9;
$color-container-carousel-control: $color-white;
$color-container-carousel-control--hover: $color-white;
$text-align-container-carousel-control: center;
$transition-container-carousel-control: 0.15s ease;

// Carousel Control Icons
$width-icon-carousel-control: 20px;
$height-icon-carousel-control: $width-icon-carousel-control;
$margin-x-icon-carousel-control: 15px;
$color-icon-carousel-control: $color-white;
$color-icon-carousel-control-inverse: $color-silver;
$icon-carousel-control-prev: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$color-icon-carousel-control}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"), "#", "%23");
$icon-carousel-control-next: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$color-icon-carousel-control}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"), "#", "%23");
$icon-carousel-control-prev-inverse: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$color-icon-carousel-control-inverse}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"), "#", "%23");
$icon-carousel-control-next-inverse: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$color-icon-carousel-control-inverse}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"), "#", "%23");

// Carousel Caption
$z-index-container-carousel-caption: 10;
$width-container-carousel-caption: 70%;
$bottom-container-carousel-caption: 20px;
$left-container-carousel-caption: (100% - $width-container-carousel-caption) / 2;
$right-container-carousel-caption: (100% - $width-container-carousel-caption) / 2;
$padding-top-container-carousel-caption: 20px;
$padding-bottom-container-carousel-caption: 20px;
$color-container-carousel-caption: $color-white;
$text-align-container-carousel-caption: center;

// Carousel Indicators
$z-index-container-carousel-indicators: 15;
$left-container-carousel-indicators: 0;
$right-container-carousel-indicators: 0;
$bottom-container-carousel-indicators: 20px;
$bottom-container-carousel-indicators__mobile: 20px;
$max-width-container-carousel-indicators__mobile: 450px;
$margin-x-container-carousel-indicators: 5px;

// Carousel Indicator
$width-icon-carousel-indicator: 10px;
$height-icon-carousel-indicator: $width-icon-carousel-indicator;
$height-hit-area-carousel-indicator: 11px;
$margin-x-icon-carousel-indicator: calc(25px / 2);
$border-icon-carousel-indicator: 2px solid $color-black;
$border-radius-icon-carousel-indicator: 50%;
$opacity-icon-carousel-indicator: 0.5;
$opacity-icon-carousel-indicator--active: 1;
$color-icon-carousel-indicator: $color-white;
$transition-icon-carousel-indicator: opacity .6s ease;
