%button-club {
    @include button(
        $padding-button-primary,
        2px,
        $border-style-button-primary,
        $border-radius-button-primary,
        #767676,
        transparent,
        #767676,
        transparentize($color-text, 0.8),
        transparent,
        transparentize($color-text, 0.7),
        $font-family-button-primary,
        $font-size-button-primary,
        $font-size-button-primary__tablet,
        $font-size-button-primary__mobile,
        $font-weight-button-primary,
        $font-style-button-primary,
        $letter-spacing-button-primary,
        $line-height-button-primary,
        $line-height-button-primary__tablet,
        $line-height-button-primary__mobile,
        $text-transform-button-primary,
        $text-decoration-button-primary,
        $text-decoration-button-primary--hover
    );

    &-active {
        @include button-color(
            $color-black,
            transparent,
            $color-black
        );
    }
}

%icon-club-signup-selected {
    $icon-club-signup-selected: $asset-icon-selected;
    $size-icon-club-signup-selected: 20px;

    @include icon-image($icon-club-signup-selected, before, $size-icon-club-signup-selected);
}

$margin-icon-club-signup-benefits: 10px auto -1px;
$icon-club-signup-benefits-expand: $asset-icon-dropdown-expand;
$icon-club-signup-benefits-collapse: $asset-icon-dropdown-collapse;
$width-icon-club-signup-benefits: 24px;
$height-icon-club-signup-benefits: 15px;

%icon-club-signup-benefits {
    @include transition;

    &-expand {
        @include icon-image($icon-club-signup-benefits-expand, after, $width-icon-club-signup-benefits, $height-icon-club-signup-benefits);
    }

    &-collapse {
        @include icon-image($icon-club-signup-benefits-collapse, after, $width-icon-club-signup-benefits, $height-icon-club-signup-benefits);
    }

    &-expand,
    &-collapse {
        @extend %icon-club-signup-benefits;
    }

    &::after {
        margin: $margin-icon-club-signup-benefits;
    }
}
