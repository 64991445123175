@mixin border(
    $color,
    $width: 1px,
    $style: solid
) {
    border: {
        width: $width;
        style: $style;
        color: $color;
    }
}
