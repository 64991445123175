body {
    @extend %text;
    width: 100%;
    min-width: $min-width-page;
    background-color: $color-background;
    overflow-wrap: break-word;

    @include media-breakpoint-up(sm) {
        overflow-wrap: normal;
    }
}

p,
ul,
ol {
    margin-bottom: $margin-bottom-text;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
    margin-bottom: $margin-bottom-heading;
}

h6,
.h6 {
    margin-bottom: $margin-bottom-subheading;
}

p,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    &:last-child {
        margin-bottom: 0;
    }

    &:empty {
        display: none;
    }
}

p,
ul,
ol {
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6,
    + .h1,
    + .h2,
    + .h3,
    + .h4,
    + .h5,
    + .h6 {
        margin-top: $margin-top-heading;
    }
}

p + ul {
    margin-top: $margin-top-list - $margin-top-text;
}

h1,
.h1 {
    @extend %heading-h1;
}

h2,
.h2 {
    @extend %heading-h2;
}

h3,
.h3 {
    @extend %heading-h3;
}

h4,
.h4 {
    @extend %heading-h4;
}

h5,
.h5 {
    @extend %heading-h5;
}

h6,
.h6 {
    @extend %heading-h6;
}

a {
    @extend %link;
}

ul,
ol {
    padding-left: $padding-left-list;

    li {
        margin-bottom: $margin-bottom-list-item;
    }
}
