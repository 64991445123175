.youtube-modal {
    .msc-modal {
        &__dialog,
        &__content {
            background-color: transparent;
        }

        &__dialog {
            max-width: $max-width-container-youtube-modal-dialog;
            height: $height-container-youtube-modal-dialog;
            max-height: $max-height-container-youtube-modal-dialog;

            @include media-breakpoint-up(lg) {
                width: 100%;
            }

            &:focus {
                outline: none;
            }
        }

        &__content {
            height: 100%;
        }

        &__header {
            padding: 0;
            margin-bottom: $margin-bottom-container-youtube-modal-header__mobile;

            @include media-breakpoint-up(lg) {
                margin-bottom: $margin-bottom-container-youtube-modal-header;
            }
        }

        &__body {
            padding: 0;
        }

        &__close-button {
            @include image-invert;
        }
    }

    iframe {
        width: 100%;
        height: 100%;
    }
}
