.gift-card-balance-container {
    @extend %width-shop-with-breadcrumb;
    @extend %width-shop-with-header;

    > .container-with-header-slots {
        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
        }

        > * {
            &:first-child {
                @include media-breakpoint-up(md) {
                    @include flex-width($width-image-gift-card-balance__desktop-only);
                    margin-right: $margin-right-image-gift-card-balance__desktop-only;
                }
            }

            &:last-child {
                @include media-breakpoint-up(md) {
                    flex: 1;
                }
            }
        }
    }

    .container-with-header-text{
        *{
            color: #797676 !important;
        }
    }
}
