.shop-carousel {
    > .container-with-header-header {
        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 20px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 50px;
        }

        > .container-with-header-title {
            margin-bottom: 10px;

            @include media-breakpoint-up(xl) {
                margin-bottom: 15px;
            }
        }
    }

    &.layout__altleft .msc-carousel__item:nth-child(odd) .ms-content-block,
    &.layout__altright .msc-carousel__item:nth-child(even) .ms-content-block {
        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }
    }

    &.layout__altleft .msc-carousel__item:nth-child(even) .ms-content-block,
    &.layout__altright .msc-carousel__item:nth-child(odd) .ms-content-block {
        @include media-breakpoint-up(sm) {
            flex-direction: row-reverse;
        }
    }

    .msc-carousel {
        &__item {
            @include media-breakpoint-up(sm) {
                margin: $margin-container-alt-content__tablet;
            }

            @include media-breakpoint-up(md) {
                margin: $margin-container-alt-content__desktop;
            }

            @include media-breakpoint-up(xl) {
                margin: $margin-container-alt-content;
            }

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(sm) {
                transition: none;
                display: block;
                position: static;
                float: none;
                margin-right: 0;
                z-index: unset;
                opacity: 1;
                backface-visibility: visible;
                transform: none;
            }
        }

        &__control__prev,
        &__control__next {
            display: none;
        }

        &__indicators {
            bottom: unset;
            top: 60vw;
            margin-bottom: 0;

            @include media-breakpoint-up(sm) {
                display: none;
            }
        }
    }

    .ms-content-block {
        @include media-breakpoint-down(xs) {
            &.colortheme__light {
                background-color: $color-background-hero-content-inverse;
            }

            &.colortheme__dark {
                background-color: $color-background-hero-content;
                color: $color-text-hero-content;

                .ms-content-block__cta > * {
                    @include button-color(
                        $color-button-inverse__border,
                        $color-button-inverse__background,
                        $color-button-inverse__text,
                        $color-button-inverse__border--hover,
                        $color-button-inverse__background--hover,
                        $color-button-inverse__text--hover
                    );
                }

                &.ctastyle__primary > .ms-content-block__details > .ms-content-block__cta > * {
                    @include button-color(
                        $color-button-primary__border,
                        $color-button-primary__background,
                        $color-button-primary__text,
                        $color-button-primary__border--hover,
                        $color-button-primary__background--hover,
                        $color-button-primary__text--hover,
                    );
                }

                &.ctastyle__secondary > .ms-content-block__details > .ms-content-block__cta > * {
                    @include button-color(
                        $color-button-secondary-inverse__border,
                        $color-button-secondary-inverse__background,
                        $color-button-secondary-inverse__text,
                        $color-button-secondary-inverse__border--hover,
                        $color-button-secondary-inverse__background--hover,
                        $color-button-secondary-inverse__text--hover
                    );
                }
            }
        }

        @include media-breakpoint-up(sm) {
            &.colortheme__custom {
                background-color: transparent !important;
                color: $color-text !important;

                .ms-content-block__cta > * {
                    @include button-color(
                        $color-button__border,
                        $color-button__background,
                        $color-button__text,
                        $color-button__border--hover,
                        $color-button__background--hover,
                        $color-button__text--hover
                    );
                }

                &.ctastyle__primary > .ms-content-block__details > .ms-content-block__cta > * {
                    @include button-color(
                        $color-button-primary__border,
                        $color-button-primary__background,
                        $color-button-primary__text,
                        $color-button-primary__border--hover,
                        $color-button-primary__background--hover,
                        $color-button-primary__text--hover,
                    );
                }

                &.ctastyle__secondary > .ms-content-block__details > .ms-content-block__cta > * {
                    @include button-color(
                        $color-button-secondary__border,
                        $color-button-secondary__background,
                        $color-button-secondary__text,
                        $color-button-secondary__border--hover,
                        $color-button-secondary__background--hover,
                        $color-button-secondary__text--hover
                    );
                }
            }
        }
    }

    .ms-content-block__details {
        @include media-breakpoint-down(xs) {
            width: 100% !important;
            padding: $padding-container-hero-content__mobile;
            margin: 0 !important;
        }
    }

    .ms-content-block__image {
        @include media-breakpoint-down(xs) {
            height: 65vw;
            padding: 0 !important;
        }
    }
}
