// Banner
$width-container-banner: 100%;
$padding-container-banner: 8px 10px 5px;
$padding-container-banner__mobile: 5px 10px 3px;
$border-width-container-banner: 0;
$border-style-container-banner: solid;
$color-border-banner: $color-border-accent;
$color-background-banner: $color-background-inverse;

// Banner Content
$margin-right-container-banner-content: 3px;

// Banner Text
$color-text-banner: $color-text-inverse;
$font-size-text-banner: 14px;

%text-banner {
    @extend %text;
    @include text-font-size($font-size-text-banner);
    @include link-color($color-text-banner);
    @include link-text-decoration(none);
}

// Banner Link
$color-link-banner: $color-link-inverse;
$color-link-banner--hover: $color-link-inverse--hover;
$text-decoration-link-banner: underline;
$text-decoration-link-banner--hover: underline;

%link-banner {
    @include link(
        $color-link-banner,
        $color-link-banner--hover,
        $text-decoration-link-banner,
        $text-decoration-link-banner--hover
    );
}

// Banner Icon
$padding-icon-banner-close: 0;
$margin-top-icon-banner-close: -3px;
$margin-left-icon-banner-close: 8px;
$icon-banner-close: $asset-icon-close;
$size-icon-banner-close: 12px;

%icon-banner-close {
    @include icon-image($icon-banner-close, before, $size-icon-banner-close);
    @include image-invert;
    border: none;
    padding: $padding-icon-banner-close;
    margin-top: $margin-top-icon-banner-close;
    margin-left: $margin-left-icon-banner-close;
    background-color: transparent;
}
