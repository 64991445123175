.ms-order-details {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__heading {
        display: none;
    }

    &__order-information,
    &__groups,
    &__order-summary,
    &__payment-methods {
        @extend %text-order-details;
    }

    &__order-information,
    &__order-summary,
    &__payment-methods {
        margin-bottom: $margin-bottom-container-order-details-section__mobile;

        @include media-breakpoint-up(xxl) {
            margin-bottom: 0;
        }
    }

    &__order-information {
        order: 1;

        @include media-breakpoint-up(md) {
            @include flex-width($width-container-order-details-information__tablet);
            margin-right: $margin-right-container-order-details-information__tablet;
        }

        @include media-breakpoint-up(xxl) {
            @include flex-width($width-container-order-details-information);
        }

        &-channel-name {
            @extend %heading-order-details;
        }

        &-channel-reference-id,
        &-sales-id {
            display: block;
        }

        &-created-date,
        &-count {
            &::after {
                content: $content-separator-order-details;
                margin: $margin-separator-order-details;
                color: $color-separator-order-details;
            }
        }
    }

    &__groups {
        order: 4;

        @include media-breakpoint-up(md) {
            @include flex-width(100%);
        }

        @include media-breakpoint-up(xxl) {
            @include flex-width($width-container-order-details-groups__desktop-only);
        }
    }

    &__group {
        &-delivery-heading {
            @extend %heading-order-details;
        }

        &-delivery-total-items {
            @extend %text-order-details-delivery-items;
            margin-left: $margin-right-text-order-details-delivery-items;
        }
    }

    &__address {
        &-header {
            display: none;
        }

        &-name,
        &-full-address {
            display: block;
        }
    }

    &__tracking-info {
        display: block;
    }

    &__sales-lines {
        margin-top: $margin-top-container-order-details-sales-lines;
    }

    &__sales-line {
        position: relative;

        .msc-cart-line__content {
            justify-content: flex-start;
        }

        .msc-cart-line__product-title {
            @extend %heading-order-details-product-title;
            @include link-text-decoration(none);
            margin-bottom: $margin-bottom-heading-order-details-product-title;
        }

        .msc-cart-line-item-product-discount {
            display: none;
        }
    }

    &__sales-line-btn-buy-it-again {
        @extend %button-order-details;
        margin-top: $margin-top-button-order-details;
    }

    &__sales-status {
        @extend %text-order-details-sales-status;
        display: inline-block;
        margin: $margin-text-order-details-sales-status;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__sales-line-btn-buy-it-again,
    &__sales-status {
        margin-left: $margin-left-container-order-details-sales-lines;
    }

    &__order-summary {
        order: 2;

        @include media-breakpoint-up(md) {
            @include flex-width($width-container-order-details-summary__tablet);
            margin-right: $margin-right-container-order-details-summary;
        }

        @include media-breakpoint-up(xxl) {
            @include flex-width($width-container-order-details-summary);
        }

        > * {
            display: flex;
            justify-content: space-between;
            max-width: $max-width-container-order-details-summary;
            margin-bottom: 0;
        }

        &-heading {
            @extend %heading-order-details;
            display: inline;
        }

        &-line-total-amount {
            @extend %text-order-details-summary-total;
        }
    }

    &__payment-methods {
        order: 3;

        @include media-breakpoint-up(md) {
            @include flex-width($width-container-order-details-payment__tablet);
        }

        @include media-breakpoint-up(xxl) {
            @include flex-width($width-container-order-details-payment);
            margin-right: $margin-right-container-order-details-payment__desktop-only;
        }

        &-title {
            @extend %heading-order-details;
            display: inline;
        }
    }
}
