// Breadcrumb
$margin-container-breadcrumb: 25px 0;

// Breadcrumb Text
$font-weight-text-breadcrumb: $font-weight-bold;

%text-breadcrumb {
    @extend %text;
    @extend %text-small;
    @extend %text-accent;
}

// Breadcrumb Link
$text-decoration-link-breadcrumb: none;
$text-decoration-link-breadcrumb--hover: none;

%link-breadcrumb {
    @extend %text-breadcrumb;
    @extend %link-accent;
    @include link-text-decoration($text-decoration-link-breadcrumb, $text-decoration-link-breadcrumb--hover);

    &-active {
        @include text-font-weight($font-weight-text-breadcrumb);
        outline: 0;
        color: $color-text;
    }
}

// Breadcrumb Divider
%divider-breadcrumb {
    margin: 0 10px;
    color: $color-text-muted;
    font-size: 20px;
}
