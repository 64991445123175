/* Bootstrap overrides */

.container {
    max-width: 100%;
    padding: 0;
}

.row {
    flex: unset;
    margin: 0;
}

%grid-column {
    padding: 0;
}

.btn,
.btn-primary,
.btn-secondary {
    border-radius: 0;

    .fa-chevron-right {
        display: none;
    }

    &:disabled,
    &.disabled {
        opacity: 1;
    }
}

.hidden {
    display: none;
}

$grid-breakpoints: (
    xxs: $breakpoint-xxs,
    xs: $breakpoint-xs,
    sm: $breakpoint-sm,
    md: $breakpoint-md,
    lg: $breakpoint-lg,
    xl: $breakpoint-xl,
    xxl: $breakpoint-xxl
);
