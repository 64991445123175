.ms-account-profile {
    &__container {
        padding: $padding-container-account-profile__mobile;
        margin: $margin-container-account-profile__mobile;
        background-color: $color-background-account-profile__mobile;

        @include media-breakpoint-up(md) {
            padding: 0;
            margin: $margin-container-account-profile;
            background-color: $color-background-account-profile;
        }

        &.overflow-container-closed .overflow-container-btn {
            margin: 0;
        }
    }

    &__section {
        &-email,
        &-name {
            @include center-flex(y);
            flex-wrap: wrap;

            > * {
                margin-bottom: 0;
            }
        }

        &-heading {
            @include flex-width($width-heading-account-profile);
            margin-top: $margin-top-heading-account-profile;
            margin-right: $margin-right-heading-account-profile;
        }

        &-description {
            flex: 1;
        }

        &-links {
            flex: 0 0 100%;
            margin-top: $margin-top-container-account-profile-links;
        }

        &-link {
            @extend %link-account-profile;
        }
    }

    &__heading,
    &__section-preferences {
        display: none;
    }
}
