.wine-club-signup-benefits .container-with-header-header,
.wine-club-signup-wine-preference,
.wine-club-signup-membership,
.wine-club-signup-signup,
.wine-club-signup-signup-message {
    @include width-container-with-margin(
        $width-content-lg,
        $width-content-lg,
        $width-content-lg
    );
}

.wine-club-signup-benefits {
    .container-with-header-header,
    .wine-club-signup-benefits-button {
        @include width-container(
            $width-content-lg,
            $width-content-lg,
            $width-content-lg
        );
    }

    .container-with-header-header {
        margin-bottom: 10px;
        text-align: center;

        @include media-breakpoint-up(md) {
            margin-bottom: 25px;
        }

        .container-with-header-title {
            margin-bottom: 5px;
        }
    }

    .overflow-container {
        &-opened,
        &-closed {
            .overflow-container-btn {
                @include button-remove;
                @extend %heading-h6;
                display: block;
                margin: auto;
            }
        }

        &-opened {
            .overflow-container-btn {
                @extend %icon-club-signup-benefits-collapse;
            }

            .overflow-container-content {
                @include animation-fadeIn;
            }
        }

        &-closed {
            .overflow-container-btn {
                @extend %icon-club-signup-benefits-expand;
            }

            .overflow-container-content {
                display: none;
            }
        }
    }

    &-content {
        margin: 0 !important;
        width: 100% !important;

        .ms-content-block__details {
            width: calc(12/16 * 16/14 * 100%);

            @include media-breakpoint-up(sm) {
                width: calc(10/16 * 16/14 * 100%);
                padding: 20px 0;
            }
        }

        .ms-content-block__title {
            text-align: center;
        }

        .ms-content-block__text {
            column-count: 2;
            column-width: 300px;
            column-gap: 30px;
        }
    }
}

.wine-club-signup {
    margin-top: 50px;
}

.wine-club-signup-signup {
    text-align: center;

    &-message {
        @extend %text-appellation;
        margin-top: -20px;
        text-align: center;

        @include media-breakpoint-up(sm) {
            margin-top: -25px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: -35px;
        }
    }

    &-button {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }

        @include media-breakpoint-down(xs) {
            @include button-width-full;
        }
    }
}

.wine-club-signup-terms {
    @include width-container-with-margin(
        $width-content-sm,
        $width-content-lg,
        $width-content-lg
    );

    &-title {
        @extend %heading-h3;
        margin-bottom: 30px;
        text-align: center;
    }

    &-subtitle {
        @extend %text;
        @extend %text-small;
        margin-top: -25px;
        margin-bottom: 30px;
        text-align: center;
    }

    .page-tos {
        &-text {
            @extend %text;
            @extend %text-small;
        }

        &-checkbox {
            @include center-flex(y);
            margin-top: 20px;

            &-text {
                @extend %heading-h6;
                margin-left: 15px;
            }

            input {
                margin-bottom: 2px;
            }
        }
    }
}

.wine-club-signup-shipping {
    padding: 45px calc(1/16 * 100%);
    background-color: $color-background-accent;
    text-align: center;

    &-title {
        @extend %heading-h3;
        margin-bottom: 40px;
    }

    &-subtitle {
        @extend %text;
        @extend %text-small;
        margin-top: -35px;
        margin-bottom: 40px;
    }

    select {
        @extend %select;
        max-width: 360px;
        margin: auto;
    }

    &-disclaimer,
    &-description {
        @extend %text;
        @extend %text-small;
        margin-top: 30px;
    }

    &-disclaimer + .wine-club-signup-shipping-description {
        margin-top: 0;
    }

    .shipping-popup {
        margin-top: 20px;

        button {
            @include button-remove;
            @extend %link;
        }
    }

    &-pickup {
        margin-top: 30px;

        @include media-breakpoint-up(sm) {
            @include center-flex(x);
            flex-wrap: wrap;
        }

        &-address {
            @extend %text;
            @extend %text-small;
            display: flex;
            margin-bottom: 20px;
            text-align: left;

            @include media-breakpoint-up(sm) {
                margin-right: 30px;
            }

            &:last-child {
                margin-bottom: 0;

                @include media-breakpoint-up(sm) {
                    margin-right: 0;
                }
            }

            input {
                margin-top: 5px;
            }

            label {
                display: flex;
                flex-direction: column;
                margin-left: 10px;
            }
        }
    }

    &-radio-name {
        font-weight: $font-weight-bold;
    }
}

.wine-club-signup-wine-preference,
.wine-club-signup-membership {
    margin: 45px auto;
    text-align: center;

    @include media-breakpoint-up(md) {
        margin: 75px auto;
    }

    @include media-breakpoint-up(xl) {
        margin: 125px auto;
    }

    &-title {
        @extend %heading-h3;
    }

    &-subtitle {
        @extend %text;
        @extend %text-small;
        margin-top: -10px;
        margin-bottom: 20px;
    }
}

.wine-club-signup-wine-preference-buttons > *,
.wine-club-signup-membership-buttons > * {
    @extend %button-club;
    margin-right: 15px;

    @include media-breakpoint-down(xs) {
        @include button-width-full;
        margin-right: 0;
        margin-bottom: 15px;
    }

    &:last-child {
        margin-right: 0;

        @include media-breakpoint-down(xs) {
            margin-bottom: 0;
        }
    }

    &:disabled,
    &.disabled {
        @extend %button-club-active;
    }
}

.wine-club-signup-variant {
    margin: 50px auto;

    &-title {
        @extend %heading-h3;
        @include width-container(
            $width-content-lg,
            $width-content-lg,
            $width-content-lg
        );
        text-align: center;
        margin-bottom: 30px;
    }

    &-subtitle {
        @extend %text;
        @extend %text-small;
        @include width-container(
            $width-content-lg,
            $width-content-lg,
            $width-content-lg
        );
        margin-top: -25px;
        margin-bottom: 30px;
        text-align: center;
    }

    &-info-container {
        @include media-breakpoint-down(sm) {
            @include animation-fadeInDown;
            border-bottom: 1px solid $color-border-accent;
        }

        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        > * {
            @include media-breakpoint-up(md) {
                @include center-flex(x);
                flex-direction: column;
            }
        }

        &.closed {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    &-expand-button {
        @include center-flex;
        @include button-remove;
        @extend %heading-h6;
        width: 100%;
        padding: 20px;
        @include border($color-border-accent, 1px 0);

        @include media-breakpoint-up(md) {
            display: none;
        }

        &.open {
            @extend %icon-accordion-collapse;
            border-bottom: 0;
        }

        &.closed {
            @extend %icon-accordion-expand;
        }

        &::after {
            margin-left: 20px;
            margin-bottom: 4px;
        }
    }

    &-image {
        display: none;

        @include media-breakpoint-up(md) {
            @include flex-width(calc(7/16 * 100%));
            display: block;
        }
    }

    &-info {
        width: calc(14/16 * 100%);
        margin: 15px auto;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        @include media-breakpoint-up(md) {
            @include flex-width(calc(7.5/16 * 100%));
            margin: 20px calc(0.75/16 * 100%);
        }

        @include media-breakpoint-up(xl) {
            @include flex-width(calc(7/16 * 100%));
            margin: 50px calc(1/16 * 100%);
        }

        &-title {
            @extend %heading-h6;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &-subtitle {
            @extend %heading-h1;
        }

        &-description {
            @extend %text;
            @extend %text-small;

            ul {
                text-align: left;
            }
        }
    }
}

.wine-club-signup-club {
    @include width-container-with-margin(
        $width-content-lg,
        $width-content-lg,
        $width-content-lg,
        separate
    );

    &-title {
        @extend %heading-h3;
        text-align: center;
    }

    &-subtitle {
        @extend %text;
        @extend %text-small;
        margin-top: -10px;
        margin-bottom: 20px;
        text-align: center;
    }

    &-items {
        @include media-breakpoint-up(md) {
            @include center-flex(x);
            flex-wrap: wrap;
        }

        > * {
            margin-bottom: 30px;

            @include media-breakpoint-up(md) {
                flex: 1;
                min-width: calc(3.25/16 * 16/14 * 100%);
                max-width: calc(4/16 * 16/14 * 100%);
                margin-right: calc(0.3/16 * 16/14 * 100%);
                margin-bottom: 0;
                word-break: break-word;

                &:nth-child(4n),
                &:last-child {
                    margin-right: 0;
                }
            }

            @include media-breakpoint-up(xxl) {
                min-width: calc(3/16 * 16/14 * 100%);
                margin-right: calc(0.5/16 * 16/14 * 100%);

                &:nth-child(4n),
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &-item {
        background-color: $color-background-accent;

        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: column;
        }

        &.active {
            outline: 2px solid $color-button-primary__background;
        }

        &-image {
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }

            img {
                @include image-cover;
            }
        }

        &-content {
            @include media-breakpoint-up(md) {
                display: flex;
                flex-direction: column;
                flex: 1;
                padding: 20px calc(0.35/16 * 16/4 * 100%);
            }

            @include media-breakpoint-up(xxl) {
                padding: 30px calc(0.5/16 * 16/4 * 100%);
            }

            &-header {
                @include media-breakpoint-down(sm) {
                    padding: 15px 20px;
                    text-align: center;
                }

                @include media-breakpoint-up(md) {
                    margin-bottom: 30px;
                }
            }

            &-body {
                display: flex;

                @include media-breakpoint-down(sm) {
                    flex-wrap: wrap;
                    justify-content: space-between;
                }

                @include media-breakpoint-down(xxs) {
                    flex-direction: column;
                    justify-content: unset;
                }

                @include media-breakpoint-up(md) {
                    flex-direction: column;
                    flex: 1;
                }
            }
        }

        &-title {
            @extend %heading-h3;
            margin-bottom: 15px;

            @include media-breakpoint-up(md) {
                margin-bottom: 20px;
            }
        }

        &-subtitle {
            @extend %heading-h6;
            letter-spacing: 0.15em;
        }

        &-pricing {
            margin-bottom: 30px;

            @include media-breakpoint-down(sm) {
                @include center-flex(x);
                flex-direction: column;
                flex: 1;
                padding: 10px;
                margin: 0;
                border-top: 1px solid $color-border-accent;
            }

            @include media-breakpoint-down(xxs) {
                display: block;
                text-align: center;
            }

            &-range,
            &-cost-notif {
                display: block;
            }

            &-cost-notif {
                @extend %text-appellation;
                display: none !important;
            }
        }

        &-description {
            @extend %text;
            @extend %text-small;
            margin-bottom: 20px;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &-additional-benefits {
            @include media-breakpoint-down(sm) {
                flex: 0 1 100%;
                order: -1;
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 20px;
            }

            &.empty {
                display: none;
            }
        }

        &-additional-benefits-title {
            @extend %heading-h6;
            margin-bottom: 10px;
            color: $color-text;
            font-size: 13px;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &-additional-benefits-button {
            @include center-flex;
            @include button-remove;
            @extend %heading-h6;
            width: 100%;
            padding: 20px;
            border-top: 1px solid $color-border-accent;

            @include media-breakpoint-up(md) {
                display: none;
            }

            &.open {
                @extend %icon-accordion-collapse;
                border-bottom: 0;
            }

            &.closed {
                @extend %icon-accordion-expand;
            }

            &::after {
                margin-left: 20px;
                margin-bottom: 4px;
            }
        }

        &-additional-benefits-content {
            @extend %text;
            @extend %text-small;

            @include media-breakpoint-down(sm) {
                @include animation-fadeInDown;
                padding: 0 20px 20px;
            }

            @include media-breakpoint-up(md) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            &.closed {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }

        &-button {
            @include media-breakpoint-up(md) {
                display: flex;
                flex: 1;
            }

            button {
                @extend %button-primary;
                margin-top: auto;

                @include media-breakpoint-down(sm) {
                    height: 100%;
                }

                @include media-breakpoint-down(xxs) {
                    @include button-width-full;
                }

                &:disabled,
                &.disabled {
                    @include center-flex;
                    @extend %icon-club-signup-selected;

                    @extend %heading-h6;
                    @include button-color(
                        transparent,
                        transparent,
                        $color-button-primary__background
                    );

                    @include media-breakpoint-down(sm) {
                        @include plain-hover-all {
                            border-top: 1px solid $color-border-accent;
                        }
                    }

                    @include media-breakpoint-up(md) {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    &::before {
                        margin-top: -2px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

.wine-club-signup-shipping-modal {
    &-title {
        @extend %heading-h3;
        margin-bottom: 35px;
        text-align: center;
    }

    &-content {
        @extend %text;
        @extend %text-small;

        @include media-breakpoint-up(xl) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &-map {
        @include media-breakpoint-up(sm) {
            padding: 0 30px;
        }

        @include media-breakpoint-up(xl) {
            @include flex-width(60%);
            padding-left: 20px;
            padding-right: 40px;
        }
    }

    &-description {
        @include media-breakpoint-down(lg) {
            margin-top: 30px;
        }

        @include media-breakpoint-up(xl) {
            @include flex-width(35%);
        }

        p {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-states {
        column-count: 2;
        column-width: 120px;
        column-gap: 20px;
    }

    .shipping-map-legend-item {
        justify-content: center;
    }

    .shipping-map-legend-item-text {
        @include text-font-weight($font-weight-bold);
        @include text-text-transform(uppercase);
    }

    .shipping-map-legend-item-restricted {
        display: none;
    }
}

.club-signup-page .ms-cart-icon,
.club-signup-page .msc-cart-icon {
    display: none !important;
}
