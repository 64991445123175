// Pagination
$width-container-pagination: 100%;
$padding-container-pagination: 50px 0 0;
$padding-container-pagination__tablet: 35px 0 0;
$padding-container-pagination__mobile: 25px 0 0;

// Wine Finder Page
$padding-text-pagination-page: 10px 20px;
$padding-text-pagination-page__mobile: 5px 15px;
$padding-text-pagination-page__mobile-small: 3px 10px;
$border-bottom-text-pagination-page: 1px solid $color-border-muted;
$color-text-pagination-page--active: $color-text;
$color-text-pagination-page--disabled: $color-text-muted;
$color-border-text-pagination-page--active: $color-text;

%text-pagination-page {
    @extend %text;
    @extend %text-small;

    &-active {
        @include link-remove($color-text-pagination-page--active);
        border-bottom-color: $color-border-text-pagination-page--active;
    }

    &-disabled {
        @include link-disable;
        color: $color-text-pagination-page--disabled;
    }
}
