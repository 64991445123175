.sort_and_filter_desktop_view,
.sort_and_filter_mobile_view {
    @extend %width-shop-with-breadcrumb;

    .col-lg-3 {
        display: none;
    }

    .col-lg-9 {
        @include flex-width(100%);
    }

    .breacrumb-placeholder,
    .results-title-placeholder,
    .choice-summary-container,
    .sort-and-filter-container {
        display: none;
    }
}
