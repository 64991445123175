@supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100%;
        background-image: var(--applePayImage);
        height: 35px;
        width: 284px;
        transition: box-shadow 0.3s ease-in-out;
        border-radius: 5px;
        cursor: pointer;
        appearance: var(--applePayWebkit);
        -webkit-appearance: var(--applePayWebkit);

        &:hover,
        &:focus-visible {
            box-shadow: inset 0px 0px 4px 1px transparentize($color: #000000, $amount: 0.8);
        }
    }

    .apple-pay-button-black,
    .apple-pay-button-white,
    .apple-pay-button-white-with-line {
        background-image: var(--applePayImage);
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: #fff;
        -apple-pay-button-style: white-outline;
    }
}

@supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        background-image: var(--applePayImage);
        display: inline-block;
        background-size: 100%;
        background-repeat: no-repeat;
        border-radius: 5px;
        box-sizing: border-box;
        min-width: 284px;
        min-height: 35px;
        max-height: 35px;
        transition: box-shadow 0.3s ease-in-out;
        cursor: pointer;
        appearance: var(--applePayWebkit);
        -webkit-appearance: var(--applePayWebkit);

        &:hover,
        &:focus-visible {
            box-shadow: inset 0px 0px 4px 1px transparentize($color: #000000, $amount: 0.8);
        }
    }

    .apple-pay-button-black,
    .apple-pay-button-white,
    .apple-pay-button-white-with-line {
        background-image: var(--applePayImage);
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: #fff;
        -apple-pay-button-style: white-outline;
    }
}