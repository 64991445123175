.recipe-page{
    .ms-content-block__text{
        display: flex;
        justify-content: space-between;
        margin-right: 0 !important;
        a.msc-cta{
            &__primary,
            &__secondary{
                margin-top: 0;
                @include media-breakpoint-down(lg){
                    margin-top: 10px;
                }
            }
        }
        p{
            margin-bottom: 0;
        }
        @include media-breakpoint-down(lg){
            flex-direction: column;
        }
    }
}
