// Header
$z-index-container-header: 200;
$height-container-header: 105px;
$height-container-header__tablet: 75px;
$height-container-header__mobile: 55px;
$padding-container-header: 0 calc(0.5/16 * 100%);
$color-background-header: $color-background;
$color-background-header-inverse: transparent;
$color-background-header-gradient-inverse: linear-gradient(
    0deg,
    transparentize($color-black, 1) 0%,
    transparentize($color-black, 0.5) 100%
);

// Header Logo
$size-image-header-logo: 100%;
$max-width-image-header-logo: 170px;
$max-width-image-header-logo__tablet: 130px;
$max-width-image-header-logo__mobile: 100px;

// Header Nav Logo
$z-index-image-header-logo-nav__mobile: 60;
$top-image-header-logo-nav__mobile: 14px;

// Header Top Bar
$margin-left-container-header-top: 15px;

// Header Nav Bar
$margin-right-container-header-nav: 15px;

// Header Nav
$z-index-container-header-nav: 65;
$nav-menu-padding-top-sm: 55px;
$nav-menu-padding-top-lg: 85px;

// Header Nav Icon
$z-index-icon-header-nav: 101;
$top-icon-header-nav__tablet: 32px;
$top-icon-header-nav__mobile: 22px;
$margin-top-icon-header-nav: 17px;
$margin-top-icon-header-nav__mobile: -7px;
$icon-header-nav: $asset-icon-menu;
$width-icon-header-nav: 22px;
$height-icon-header-nav: 14px;

%icon-header-nav {
    @include icon-image($icon-header-nav, before, $width-icon-header-nav, $height-icon-header-nav);
}

// Header Nav List
$top-container-header-nav-list__tablet: 60px;
$top-container-header-nav-list__mobile: 65px;
$width-container-header-nav-list: calc(4/16 * 100vw);
$width-container-header-nav-list__tablet: calc(6/16 * 100vw);
$width-container-header-nav-list__mobile: calc(100vw - (1/16 * 100vw));
$min-width-container-header-nav-list: calc(600px - (1/16 * 100vw));
$min-width-container-header-nav-list__tablet: calc(400px - (1/16 * 100vw));
$padding-container-header-nav-list: 10px 0;

// Header Nav Button
$margin-right-button-header-nav: 40px;
$margin-right-button-header-nav__tablet: 20px;
$text-shadow-button-header-nav-inverse: 0 0 5px $color-text;

$width-line-header-nav: 1px;
$color-line-header-nav: $color-button__border;
$color-line-header-nav-inverse: $color-button-inverse__border;
$color-line-header-nav--hover: transparentize($color-line-header-nav, 0.7);
$color-line-header-nav-inverse--hover: transparentize($color-line-header-nav-inverse, 0.3);
$color-line-header-nav--active: $color-line-header-nav;
$color-line-header-nav-inverse--active: $color-line-header-nav-inverse;

%button-header-nav {
    @extend %button;

    &-hover {
        @include button-line($color: $color-line-header-nav--hover, $height: $width-line-header-nav);
    }

    &-active {
        @include button-line($color: $color-line-header-nav--active, $height: $width-line-header-nav);
    }

    &-inverse {
        @include button-color-text($color-button-inverse__text, $color-button-inverse__text--hover);

        &-hover {
            @include button-line($color: $color-line-header-nav-inverse--hover, $height: $width-line-header-nav);
        }

        &-active {
            @include button-line($color: $color-line-header-nav-inverse--active, $height: $width-line-header-nav);
        }
    }
}

// Header Dropdown
$z-index-container-header-dropdown: 60;
$width-container-header-dropdown: calc(5/16 * 100%);
$width-container-header-dropdown__tablet: calc(7/16 * 100%);
$min-width-container-header-dropdown: 600px;
$min-width-container-header-dropdown__tablet: 400px;
$color-background-header-dropdown: $color-background;

// Header Dropdown Overlay
$z-index-container-header-dropdown-overlay: 50;
$color-background-container-header-dropdown-overlay: $color-black;

// Header Dropdown List
$width-container-dropdown-list: calc(5/16 * 100% - 5px);
$width-container-dropdown-list__tablet: calc(7/16 * 100% - 5px);
$min-width-container-dropdown-list: calc(600px - 5px);
$min-width-container-dropdown-list__tablet: calc(400px - 5px);
$margin-top-container-dropdown-list: 150px;
$margin-top-container-dropdown-list__tablet: 140px;
$margin-top-container-dropdown-list__mobile: 130px;
$height-container-dropdown-list: calc(100vh - #{$margin-top-container-dropdown-list});
$height-container-dropdown-list__tablet: calc(100vh - #{$margin-top-container-dropdown-list__tablet});
$height-container-dropdown-list__mobile: calc(100vh - #{$margin-top-container-dropdown-list__mobile});

// Header Dropdown Item
$padding-container-dropdown-item: 0 calc(0.5/16 * 100vw);
$padding-container-dropdown-item__tablet: 0 calc(0.5/16 * 16/7 * 100%);
$padding-container-dropdown-item__mobile: 0 calc(0.5/16 * 100%);
$margin-bottom-container-dropdown-item: 15px;
$margin-bottom-container-dropdown-item__mobile: 10px;

// Header Dropdown Item Alt
$margin-top-container-dropdown-item-alt: 65px;
$margin-top-container-dropdown-item-alt__tablet: 40px;
$margin-top-container-dropdown-item-alt__mobile: 35px;
$margin-top-container-dropdown-item-alt-sibling: 15px;
$margin-top-container-dropdown-item-alt-sibling__mobile: 10px;

// Header Dropdown Button
$color-button-header-dropdown__text: $color-link-muted;
$color-button-header-dropdown__text__mobile: $color-link;
$color-button-header-dropdown__text--hover: $color-link-muted--hover;
$color-button-header-dropdown__text--hover__mobile: $color-link--hover;
$color-button-header-dropdown__text--active: $color-button-primary__background;

%button-header-dropdown {
    @extend %button;
    @extend %heading-h3;
    @include button-color-text(
        $color-button-header-dropdown__text__mobile,
        $color-button-header-dropdown__text--hover__mobile
    );

    @include media-breakpoint-up(lg) {
        @include button-color-text(
            $color-button-header-dropdown__text,
            $color-button-header-dropdown__text--hover
        );
    }

    &-active {
        @include button-color-text(
            $color-button-header-dropdown__text--active,
            $color-button-header-dropdown__text--hover
        );
    }
}

// Header Dropdown Button Alt
$width-line-header-dropdown-alt: 1px;
$color-line-header-dropdown-alt: $color-button__border;
$color-line-header-dropdown-alt--hover: transparentize($color-line-header-dropdown-alt, 0.7);
$color-line-header-dropdown-alt--active: $color-line-header-dropdown-alt;

%button-header-dropdown-alt {
    @extend %button;

    &-hover {
        @include button-line($color: $color-line-header-dropdown-alt--hover, $height: $width-line-header-dropdown-alt);
    }

    &-active {
        @include button-line($color: $color-line-header-dropdown-alt--active, $height: $width-line-header-dropdown-alt);
    }
}

// Header Icon
$margin-right-icon-header: 20px;
$margin-right-icon-header__mobile: 12px;
$icon-header-close: $asset-icon-close;
$size-icon-header-close: 12px;
$icon-header-account: $asset-icon-user;
$width-icon-header-account: 14px;
$height-icon-header-account: 16px;
$icon-header-search: $asset-icon-search;
$size-icon-header-search: 16px;
$icon-header-cart: $asset-icon-cart;
$width-icon-header-cart: 18px;
$height-icon-header-cart: 16px;

%icon-header {
    &-close {
        @include icon-image($icon-header-close, before, $size-icon-header-close);
    }

    &-account {
        @include icon-image($icon-header-account, after, $width-icon-header-account, $height-icon-header-account);
    }

    &-search {
        @include icon-image($icon-header-search, after, $size-icon-header-search);
    }

    &-cart {
        @include icon-image($icon-header-cart, before, $width-icon-header-cart, $height-icon-header-cart);
    }
}

// Header Icon Text
$margin-top-text-header-icon: 5px;
$font-size-text-header-icon: 12px;
$text-transform-text-header-icon: capitalize;

%text-header-icon {
    @extend %text;
    @include text-font-size($font-size-text-header-icon);
    @include text-text-transform($text-transform-text-header-icon);
    display: none;
    margin-top: $margin-top-text-header-icon;

    @include media-breakpoint-up(sm) {
        display: block;
    }

    &-inverse {
        @include text-color($color-text-inverse);
    }
}

// Header Icon Account Text
$margin-right-text-header-icon: 8px;

// Header Icon Cart Text
$margin-left-text-header-icon: 3px;
$letter-spacing-text-header-icon-cart: 0.035em;
$clip-path-x-text-header-icon-cart: 4px;
$clip-path-left-text-header-icon-cart: $clip-path-x-text-header-icon-cart;
$clip-path-right-text-header-icon-cart: $clip-path-x-text-header-icon-cart;
$clip-path-text-header-icon-cart: inset(0 $clip-path-right-text-header-icon-cart 0 $clip-path-left-text-header-icon-cart);

%text-header-icon-cart {
    @include text-letter-spacing($letter-spacing-text-header-icon-cart);
    display: block;
    clip-path: $clip-path-text-header-icon-cart;
}

// Header Dropdown Account
$z-index-container-header-dropdown-account: 500;
$margin-top-container-header-dropdown-account: 10px;
$border-container-header-dropdown-account: 1px solid $color-border-accent;
$box-shadow-container-header-dropdown-account: 0 0 6px transparentize($color-border, 0.5);
$color-background-header-dropdown-account: $color-background;

// Header Dropdown Account Button
$padding-button-header-dropdown-account: 3px 20px;
$padding-top-button-header-dropdown-account: 15px;
$padding-bottom-button-header-dropdown-account: 10px;
$line-height-button-header-dropdown-account: 2;

%button-header-dropdown-account {
    @extend %button-header-nav;
    @include button-line-height($line-height-button-header-dropdown-account);
}
