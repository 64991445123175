.ms-sign-up {
    &__heading {
        @extend %heading-b2c;
        @extend %heading-h2;
    }

    &__email-verification-buttons {
        margin-top: $margin-y-button-b2c * 2;
        margin-bottom: $margin-y-button-b2c * 2;
    }

    &__create-button {
        @extend %button-b2c-primary;
        margin-top: $margin-y-button-b2c;
    }

    &__email-verification-button,
    &__cancel-button {
        @extend %button-b2c-secondary;
    }

    &__password-icon {
        @extend %icon-b2c;

        &-show {
            @extend %icon-b2c-show;
        }

        &-hide {
            @extend %icon-b2c-hide;
        }
    }

    &__account-item-input.ms-sign-up__account-item {
        &-newPassword,
        &-reenterPassword {
            @extend %input-b2c-with-icon;
        }
    }

    &__pw-requirements {
        margin-bottom: 1rem;
    }
}
