#subfooter {
    @extend %width-page-without-padding;
    background-color: $color-background-container-subfooter;
    text-align: $text-align-container-subfooter;

    > div {
        @include width-container(
            $width-container-subfooter,
            $width-container-subfooter__tablet,
            $width-container-subfooter__mobile
        );
        padding: $padding-container-subfooter__mobile;

        @include media-breakpoint-up(sm) {
            padding: $padding-container-subfooter__tablet;
        }

        @include media-breakpoint-up(lg) {
            padding: $padding-container-subfooter;
        }
    }
}
