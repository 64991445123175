.event-list {
    &-image {
        overflow: hidden;

        &-link {
            img {
                @include transition;
                @include image-cover;
            }

            @include hover-all {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    &-date {
        @extend %heading-h6;
        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 15px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 20px;
        }
    }

    &-day,
    &-year-L {
        margin-left: 3px;
    }

    &-day::after {
        content: ',';
    }

    &-separator::before {
        content: '-';
        margin-left: 6px;
    }

    &-name {
        @extend %heading-h2;
        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 15px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 20px;
        }

        @include plain-hover-all {
            @include link-text-decoration(none);
        }
    }

    &-intro {
        display: none;
        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
            display: block;
            margin-bottom: 15px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 20px;
        }
    }

    &-location,
    &-time,
    &-price {
        @extend %text;
        @extend %text-small;
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
        }
    }

    &-sold-out {
        @include text-font-weight($font-weight-bold);
        margin-top: 25px;
    }

    &-button {
        margin-top: 10px;

        @include media-breakpoint-up(sm) {
            margin-top: 15px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 20px;
        }

        &-link {
            @extend %button;
            @extend %button-underlined;
        }
    }

    &-month-S,
    &-month-M,
    &-year-S,
    &-category,
    &-price:empty {
        display: none;
    }
}
