.ms-buybox {
    @include media-breakpoint-up(sm) {
        display: flex;
    }

    @include media-breakpoint-up(xxl) {
        align-items: center;
    }

    &__media-gallery {
        background-color: $color-background-accent;
        @media (max-width: 680px) {
            height: auto;
            min-height: 0;
            max-height: none;
            .ms-media-gallery picture,
            .ms-media-gallery__item {
                max-height: none;
            }
        }

        @include media-breakpoint-up(sm) {
            @include flex-width($width-container-buybox-media-gallery__desktop-only);
            align-self: flex-start;
            height: $height-container-buybox-media-gallery__tablet;
            min-height: $min-height-container-buybox-media-gallery;
            max-height: $max-height-container-buybox-media-gallery;
        }

        @include media-breakpoint-up(xl) {
            height: $height-container-buybox-media-gallery;
        }
    }

    &__content {
        padding: $padding-container-buybox-content__mobile;

        @include media-breakpoint-up(sm) {
            @include flex-width($width-container-buybox-media-gallery__desktop-only);
            padding: $padding-container-buybox-content__tablet;
        }

        @include media-breakpoint-up(xl) {
            padding: $padding-container-buybox-content__desktop;
        }

        @include media-breakpoint-up(xxl) {
            padding: $padding-container-buybox-content;
        }
    }

    .product-attribute-apellation {
        @extend %heading-buybox-appellation;
        margin-bottom: $margin-bottom-heading-buybox-appellation__mobile;

        @include media-breakpoint-up(sm) {
            margin-bottom: $margin-bottom-heading-buybox-appellation__tablet;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: $margin-bottom-heading-buybox-appellation;
        }
    }

    &__product-title {
        margin-bottom: $margin-bottom-heading-buybox-title__mobile;

        @include media-breakpoint-up(sm) {
            margin-bottom: $margin-bottom-heading-buybox-title;
        }
    }

    .product-attribute-skutitle {
        margin-bottom: $margin-bottom-text-buybox-sku-title__mobile;

        @include media-breakpoint-up(xl) {
            margin-bottom: $margin-bottom-text-buybox-sku-title;
        }
    }

    .product-attribute-nv {
        @include media-breakpoint-up(sm) {
            margin-bottom: $margin-bottom-text-buybox-description__mobile;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: $margin-bottom-text-buybox-description;
        }
    }

    .message {
        @extend %text-buyboy-message;

        @include media-breakpoint-down(xs) {
            position: fixed;
            z-index: $z-index-text-buybox-message__mobile;
            bottom: $bottom-text-buybox-message__mobile;
            left: 0;
            right: 0;
        }

        @include media-breakpoint-up(sm) {
            position: absolute;
            margin-top: $margin-top-text-buybox-message;
        }

        &-alert {
            @include media-breakpoint-down(xs) {
                display: block;
                padding: $padding-text-buybox-message__mobile;
                background-color: $color-background-text-buybox-message__mobile;
                text-align: $text-align-text-buybox-message__mobile;
            }
        }
    }

    .acclaim-list {
        display: none;
    }
}
