/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles and padding on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
    padding: 0;
}

/* <a> elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

a:hover {
    text-decoration: underline;
}

/* Make images easier to work with */
img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove up and down buttons for numeric inputs */
input {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
