// Wine Finder Input
%input-wine-finder {
    @extend %input;
}

// Wine Finder Select
%select-wine-finder {
    @extend %select;
}

// Wine Finder Button
%button-wine-finder {
    @extend %button-primary;

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}

// Wine Finder Tabs
$margin-bottom-container-wine-finder-tabs: 35px;
$margin-bottom-container-wine-finder-tabs__mobile: 25px;
$margin-right-button-wine-finder-tab: 35px;
$margin-right-button-wine-finder-tab__mobile: 20px;
$color-button-wine-finder-tab__text: $color-text-muted;
$color-button-wine-finder-tab__text--hover: $color-text-accent;
$color-button-wine-finder-tab__text--active: $color-text;

%button-wine-finder-tab {
    @include button-remove;
    @extend %button;
    @include button-color-text($color-button-wine-finder-tab__text, $color-button-wine-finder-tab__text--hover);

    &-active {
        @include button-color-text($color-button-wine-finder-tab__text--active);
    }
}

// Wine Finder Filters
$margin-right-container-wine-finder-filter: 25px;
$margin-left-label-wine-finder-filter: 10px;

%label-wine-finder-filter {
    @extend %text;
    @extend %text-small;
    margin-left: $margin-left-label-wine-finder-filter;
}

// Wine Finder Results
$padding-top-container-wine-finder-results: 50px;
$padding-top-container-wine-finder-results__tablet: 35px;
$padding-top-container-wine-finder-results__mobile: 25px;
$border-top-container-wine-finder-results: 1px solid $color-border-muted;
$text-align-container-wine-finder-results: left;

// Wine Finder Results Text
%text-wine-finder-results {
    @extend %text;
    @extend %text-small;
}

// Wine Finder Results Item
$width-container-wine-finder-results-item: calc(1/4 * 100%);
$width-container-wine-finder-results-item__tablet: calc(1/3 * 100%);
$padding-container-wine-finder-results-item: 50px;
$padding-container-wine-finder-results-item__tablet: 25px;
$padding-container-wine-finder-results-item__mobile: 25px 0;
$margin-top-container-wine-finder-results-item: -1px;
$border-width-container-wine-finder-results-item: 1px 0;
$border-style-container-wine-finder-results-item: solid;
$color-border-container-wine-finder-results-item: $color-border-muted;

// Wine Finder Results Item Text
%text-wine-finder-results-item {
    @extend %text;
    @extend %text-small;
}

// Wine Finder Results Item Heading
$margin-bottom-heading-wine-finder-results-item: 5px;

%heading-wine-finder-results-item {
    @extend %heading-h4;
    margin-bottom: $margin-bottom-heading-wine-finder-results-item;
}

// Wine Finder Results Item Location
$line-height-text-wine-finder-results-item-location: 21px;

// Wine Finder Results Item Distance
$margin-right-text-wine-finder-results-item-distance: 10px;

// Wine Finder Results Item Products
$margin-top-container-wine-finder-results-item-products: 5px;

// Wine Finder Results Item Products Button
%button-wine-finder-results-item-products {
    @include button-remove;
    @extend %link;
}

// Wine Finder Results Item Products Text
$margin-top-text-wine-finder-results-item-products: 5px;
$color-text-wine-finder-results-item-products: $color-mineshaft;
$font-style-wine-finder-results-item-products: italic;

%text-wine-finder-results-item-products {
    @extend %text-appellation;
    margin-top: $margin-top-text-wine-finder-results-item-products;
    color: $color-text-wine-finder-results-item-products;
    font-style: $font-style-wine-finder-results-item-products;
}

// Wine Finder Pages
$width-container-wine-finder-pages: 100%;
$padding-container-wine-finder-pages: 50px 0 0;
$padding-container-wine-finder-pages__tablet: 35px 0 0;
$padding-container-wine-finder-pages__mobile: 25px 0 0;
$border-top-container-wine-finder-pages: 1px solid $color-border-muted;
$text-align-container-wine-finder-pages: center;

// Wine Finder Page
$padding-text-wine-finder-page: 10px 20px;
$padding-text-wine-finder-page__mobile: 5px 15px;
$padding-text-wine-finder-page__mobile-small: 3px 10px;
$border-bottom-text-wine-finder-page: 1px solid $color-border-muted;
$color-text-wine-finder-page--active: $color-text;
$color-text-wine-finder-page--disabled: $color-text-muted;
$color-border-text-wine-finder-page--active: $color-text;

%text-wine-finder-page {
    @extend %text;
    @extend %text-small;

    &-active {
        @include link-remove($color-text-wine-finder-page--active);
        border-bottom-color: $color-border-text-wine-finder-page--active !important;
    }

    &-disabled {
        @include link-disable;
        color: $color-text-wine-finder-page--disabled;
    }
}
