// Cart Line Image
$width-image-cart-line: 40px;
$max-height-image-cart-line: 80px;
$max-height-image-cart-line: 60px;
$margin-right-image-cart-line: 10px;

// Cart Line Quantity
$content-separator-cart-line-quantity: ':';
$margin-right-separator-cart-line-quantity: 5px;

%separator-cart-line-quantity {
    content: $content-separator-cart-line-quantity;
    margin-right: $margin-right-separator-cart-line-quantity;
}

// Cart Line Strikethrough Price
$margin-right-text-cart-line-price-strike: 5px;
$color-text-cart-line-price-strike: $color-text-muted;
$text-decoration-cart-line-price-strike: line-through;

// Cart Line Remove Icon
$margin-left-icon-cart-line-remove: 10px;
$margin-left-icon-cart-line-remove__mobile: 15px;
$icon-cart-line-remove: $asset-icon-delete;
$size-icon-cart-line-remove: 16px;

%icon-cart-line-remove {
    @include icon-image($icon-cart-line-remove, before, $size-icon-cart-line-remove);
    margin-left: $margin-left-icon-cart-line-remove__mobile;

    @include media-breakpoint-up(lg) {
        margin-left: $margin-left-icon-cart-line-remove;
    }
}
