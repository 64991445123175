// Footer
$width-container-footer: $width-content-sm;
$width-container-footer__tablet: $width-content-md;
$width-container-footer__mobile: $width-content-lg;
$padding-container-footer: 60px 0;
$padding-container-footer__tablet: 30px 0 35px;
$padding-container-footer__mobile: 30px 0 25px;
$padding-container-footer-with-subfooter: 10px 0 60px;
$padding-container-footer-with-subfooter__tablet: 5px 0 35px;
$padding-container-footer-with-subfooter__mobile: 5px 0 25px;
$color-background-container-footer: $color-background;
$text-align-container-footer: center;

// Footer Text
%text-footer {
    @extend %text;
}

// Footer Link
$color-link-footer: $color-link;
$color-link-footer--hover: $color-link--hover;
$text-decoration-link-footer: none;
$text-decoration-link-footer--hover: none;

%link-footer {
    @include link(
        $color-link-footer,
        $color-link-footer--hover,
        $text-decoration-link-footer,
        $text-decoration-link-footer--hover
    );
}

// Footer Social
$margin-container-footer-social: 0 0 20px;
$margin-container-footer-social__mobile: 0 0 25px;
$margin-right-icon-footer-social: 30px;
$height-icon-footer-social: 18px;
$width-icon-footer-social-instagram: 17px;
$width-icon-footer-social-facebook: 16px;
$width-icon-footer-social-twitter: 21px;
$width-icon-footer-social-youtube: 21px;
$icon-footer-social-instagram: $asset-icon-social-instagram;
$icon-footer-social-facebook: $asset-icon-social-facebook;
$icon-footer-social-twitter: $asset-icon-social-twitter;
$icon-footer-social-youtube: $asset-icon-social-youtube;

%icon-footer-social {
    display: block;

    &-instagram {
        @include icon-image($icon-footer-social-instagram, before, $width-icon-footer-social-instagram, $height-icon-footer-social);
    }

    &-facebook {
        @include icon-image($icon-footer-social-facebook, before, $width-icon-footer-social-facebook, $height-icon-footer-social);
    }

    &-twitter {
        @include icon-image($icon-footer-social-twitter, before, $width-icon-footer-social-twitter, $height-icon-footer-social);
    }

    &-youtube {
        @include icon-image($icon-footer-social-youtube, before, $width-icon-footer-social-youtube, $height-icon-footer-social);
    }

    &-instagram,
    &-facebook,
    &-twitter,
    &-youtube {
        @extend %icon-footer-social;
    }
}

// Footer Nav
$margin-container-footer-nav: 20px 0 10px;
$margin-right-link-footer-nav: 25px;

// Footer Legal
$margin-container-footer-legal: 10px 0 5px;
$margin-container-footer-legal__mobile: 15px 0 5px;
$margin-right-link-footer-legal: 15px;
$line-height-text-footer-legal: 25px;

%text-footer-legal {
    @extend %text-appellation;
    @include text-line-height($line-height-text-footer-legal);
}

// Footer Copyright
$margin-container-footer-copyright: 5px 0 15px;
$margin-container-footer-copyright__mobile: 5px 0 15px;
$line-height-text-footer-copyright: 21px;

%text-footer-copyright {
    @extend %text-appellation;
    @include text-line-height($line-height-text-footer-copyright);
}

// Footer Logos
$margin-container-footer-logos: 15px 0 0;
$margin-right-icon-footer-logos: 25px;
$icon-footer-logos-enjoy: $asset-icon-enjoy;
$width-footer-logos-enjoy: 139px;
$height-footer-logos-enjoy: 24px;
$icon-footer-logos-ea: $asset-icon-ea;
$width-footer-logos-ea: 66px;
$height-footer-logos-ea: 24px;

%icon-footer-logos {
    display: block;

    &-enjoy {
        @include icon-image($icon-footer-logos-enjoy, before, $width-footer-logos-enjoy, $height-footer-logos-enjoy);
    }

    &-ea {
        @include icon-image($icon-footer-logos-ea, before, $width-footer-logos-ea, $height-footer-logos-ea);
    }

    &-enjoy,
    &-ea {
        @extend %icon-footer-logos;
    }
}
