.tasting-detail {
    > .container-with-header-slots {
        @include media-breakpoint-up(xl) {
            display: flex;
            margin: $margin-container-product-details__desktop-only;
        }

        > .ms-accordion {
            margin: $margin-container-product-accordion__mobile;

            @include media-breakpoint-up(sm) {
                width: calc(12/16 * 100%);
                margin: 0 auto;
            }

            @include media-breakpoint-up(xl) {
                flex: 1;
                margin: $margin-container-product-accordion__desktop;
            }

            @include media-breakpoint-up(xxl) {
                margin: $margin-container-product-accordion;
            }

            .ms-accordion-header-section {
                display: none;
            }

            .collapse.show {
                @include media-breakpoint-up(sm) {
                    height: auto !important;
                }
            }

            .collapse:not(.show) {
                @include media-breakpoint-up(sm) {
                    display: block;
                }
            }

            .collapsing {
                @include media-breakpoint-up(sm) {
                    height: auto;
                    transition: none;
                }
            }

            .drawer__button {
                @extend %heading-product-accordion;

                @include media-breakpoint-up(sm) {
                    pointer-events: none;
                }

                &::after {
                    @include media-breakpoint-up(sm) {
                        display: none;
                    }
                }
            }

            .ms-accordion-item {
                padding: $padding-container-product-accordion-item__mobile;

                @include media-breakpoint-up(sm) {
                    padding: $padding-container-product-accordion-item;
                }

                &,
                &-section {
                    @include media-breakpoint-up(sm) {
                        border: 0;
                    }
                }

                &-content {
                    padding: $padding-container-product-accordion-content;
                }
            }
        }

        > .tasting-tours {
            width: calc(14/16 * 100%);
            margin: 30px auto;

            @include media-breakpoint-up(sm) {
                width: calc(12/16 * 100%);
                margin: auto;
            }

            @include media-breakpoint-up(xl) {
                @include flex-width(calc(7/16 * 100%));
                margin-right: calc(1/16 * 100%);
            }

            @include media-breakpoint-up(xxl) {
                @include flex-width(calc(5/16 * 100%));
                margin-right: calc(2/16 * 100%);
            }
        }
    }
}
