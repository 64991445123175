.msc-pagination {
    display: inline-flex;
    justify-content: center;
    overflow-x: auto;
    width: $width-container-pagination;
    padding: $padding-container-pagination__mobile !important;

    @include media-breakpoint-up(sm) {
        padding: $padding-container-pagination__tablet !important;
    }

    @include media-breakpoint-up(xl) {
        padding: $padding-container-pagination !important;
    }

    > * {
        padding: $padding-text-pagination-page__mobile-small;

        @include media-breakpoint-up(xs) {
            padding: $padding-text-pagination-page__mobile;
        }

        @include media-breakpoint-up(sm) {
            padding: $padding-text-pagination-page;
        }
    }

    .msc-page-item {
        border-bottom: $border-bottom-text-pagination-page;

        &.active {
            @extend %text-pagination-page-active;
        }

        &.disabled {
            @extend %text-pagination-page-disabled;
        }
    }

    .msc-page-link {
        @include link-text-decoration(none);
    }
}
