@mixin truncation-height {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin truncation-line($lines) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
}
