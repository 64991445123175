@mixin hover-all {
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus {
        @content;
    }
}

@mixin plain-hover-all {
    &,
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus {
        @content;
    }
}

@mixin class-hover-all($class) {
    &.#{$class},
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus {
        @content;
    }
}
