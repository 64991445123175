.b2c {
    width: $width-container-b2c;
    margin: $margin-container-b2c__mobile;

    @include media-breakpoint-up(sm) {
        max-width: $max-width-container-b2c;
    }

    @include media-breakpoint-up(lg) {
        margin: $margin-container-b2c;
    }

    &-message {
        @extend %text-b2c-message;
        padding: $padding-container-b2c-message__mobile;
        background-color: $color-background-b2c-message;

        @include media-breakpoint-up(sm) {
            padding: $padding-container-b2c-message__tablet;
        }

        @include media-breakpoint-up(lg) {
            padding: $padding-container-b2c-message;
        }

        .ms-content-block__title {
            @extend %heading-b2c-message;
        }

        .ms-content-block__cta {
            margin-top: $margin-top-button-b2c-message;

            > button,
            > a[role='button'] {
                @extend %button-b2c-message;
            }
        }
    }

    &-container {
        @extend %text-b2c;
        padding: $padding-container-b2c-container__mobile;
        background-color: $color-background-b2c-container;

        @include media-breakpoint-up(sm) {
            padding: $padding-container-b2c-container__tablet;
        }

        @include media-breakpoint-up(lg) {
            padding: $padding-container-b2c-container;
        }
    }

    .entry-item {
        @extend %input-group-b2c;
    }

    .msc-btn + .msc-btn {
        margin-top: $margin-y-button-b2c;
    }

    .error,
    %success {
        @extend %alert-b2c;
    }

    .error {
        @extend %alert-b2c-danger;
    }

    %success {
        @extend %alert-b2c-success;
    }
}

.ms-sign-in,
.ms-sign-up,
.ms-password-reset-verification,
.ms-password-reset,
.ms-account-profile-edit {
    @extend .b2c-container;

    .ms-sign-in__page-success,
    .ms-sign-up__page-success,
    .ms-password-reset-verification__page-success,
    .ms-password-reset__page-success,
    .ms-account-profile-edit__page-success {
        @extend %success;
    }
}

/* START - B2C page local testing */
// .modal-open {
//     overflow: auto !important;
//     position: static !important;
// }

// .msc-modal,
// .msc-modal__backdrop {
//     display: none !important;
// }
/* END - B2C page local testing */
