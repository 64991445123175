//==============================================================================
// STANDARD
//==============================================================================

.ms-content-block[data-m-layout='standard'],
.container-with-header.layout__standard {
    @include width-container-with-margin(
        $width-container-standard,
        $width-container-standard__tablet,
        $width-container-standard__mobile,
        separate
    );
    @extend %text;
}

.container-with-header.layout__standard .ms-content-block {
    width: 100%;
}

//==============================================================================
// FEATURED
//==============================================================================

.ms-content-block[data-m-layout='featured'],
.container-with-header.layout__featured {
    @include width-container-with-margin(
        $width-container-featured,
        $width-container-featured__tablet,
        $width-container-featured__mobile,
        separate
    );
    @extend %text-featured;
}

.container-with-header.layout__featured .ms-content-block {
    width: 100%;
}

//==============================================================================
// LEGAL
//==============================================================================

.ms-content-block[data-m-layout='legal'],
.container-with-header.layout__legal {
    @include width-container-with-margin(
        $width-container-legal,
        $width-container-legal__tablet,
        $width-container-legal__mobile,
        separate
    );

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        text-align: $text-align-heading-legal;
    }
}

.container-with-header.layout__legal .ms-content-block {
    width: 100%;
}

.legal-table {
    @extend %text-legal-table;
    margin-bottom: $margin-bottom-table-legal;

    td,th {
        @include border($color-border-legal-table);
        vertical-align: top;
        width: $width-table-legal;
        padding: $padding-table-legal;
    }

    p:last-child {
        margin-bottom: 0;
    }

    ol {
        padding-left: 0;
    }
}

//==============================================================================
// PROMO
//==============================================================================

.ms-content-block[data-m-layout='promo'] {
    @include width-container-with-margin(
        $width-container-promo,
        $width-container-promo__tablet,
        $width-container-promo__mobile
    );
    padding: $padding-container-promo__mobile;
    background-color: $color-background-container-promo;

    @include media-breakpoint-up(lg) {
        padding: $padding-container-promo;
    }

    > * {
        @include width-container(
            $width-container-promo-content,
            $width-container-promo-content__tablet,
            $width-container-promo-content__mobile
        );
    }

    .ms-content-block__image {
        margin-bottom: $margin-bottom-image-content-block;
    }
}

.container-with-header.layout__promo {
    @include width-container-with-margin(
        $width-container-promo,
        $width-container-promo__tablet,
        $width-container-promo__mobile
    );

    .ms-content-block[data-m-layout='promo'] {
        width: 100%;
    }
}

//==============================================================================
// DOUBLE
//==============================================================================

.container-with-header.layout__double {
    @include media-breakpoint-up(sm) {
        margin: $margin-container-double__tablet;
    }

    @include media-breakpoint-up(xl) {
        margin: $margin-container-double__desktop;
    }

    @include media-breakpoint-up(xxl) {
        margin: $margin-container-double;
    }

    > .container-with-header-slots {
        @include media-breakpoint-up(sm) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        > * {
            width: $width-container-double-content__mobile;
            padding: $padding-container-double-content__mobile;
            margin: $margin-container-double-content__mobile;

            @include media-breakpoint-up(sm) {
                @include flex-width($width-container-double-content);
                padding: 0;
            }

            &:nth-child(odd) {
                @include media-breakpoint-up(sm) {
                    margin: $margin-container-double-content-left;
                }
            }

            &:nth-child(even) {
                background-color: $color-background-double-content-right__mobile;

                @include media-breakpoint-up(sm) {
                    margin: $margin-container-double-content-right;
                    background-color: $color-background-double-content-right;
                }
            }
        }
    }
}

.container-with-header.layout__doublecontent > .container-with-header-header,
.ms-content-block[data-m-layout='double-tile'] {
    @extend %text-double;
    text-align: $text-align-container-double-content__mobile;

    @include media-breakpoint-up(sm) {
        text-align: $text-align-container-double-content;
    }
}

.container-with-header.layout__double.content > .container-with-header-header > .container-with-header-title {
    margin-bottom: $margin-bottom-container-double-header__mobile;

    @include media-breakpoint-up(sm) {
        margin-bottom: $margin-bottom-container-double-header__tablet;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: $margin-bottom-container-double-header;
    }
}

.ms-content-block[data-m-layout='double-tile'] {
    > .ms-content-block__image {
        margin-bottom: $margin-bottom-image-double__mobile;

        @include media-breakpoint-up(sm) {
            margin-bottom: $margin-bottom-image-double__tablet;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: $margin-bottom-image-double;
        }

        img {
            @include image-cover(width);
        }
    }

    > .ms-content-block__details {
        @include media-breakpoint-up(xl) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        > .ms-content-block__title {
            @include media-breakpoint-up(xl) {
                @include flex-width(100%);
            }
        }

        > .ms-content-block__text {
            @include media-breakpoint-up(xl) {
                flex: 1;
                margin-right: $margin-right-text-double__desktop-only;
            }
        }

        > .ms-content-block__cta {
            margin-top: $margin-top-button-double__mobile;

            @include media-breakpoint-up(sm) {
                margin-top: $margin-top-button-double__tablet;
            }

            @include media-breakpoint-up(xl) {
                margin-top: $margin-top-button-double;
                text-align: $text-align-button-double__desktop-only;
            }
        }
    }
}

//==============================================================================
// TRIPLE
//==============================================================================

.container-with-header.layout__triple {
    @include width-container-with-margin(
        $width-container-triple,
        $width-container-triple__tablet,
        $width-container-triple__mobile
    );
    margin: $margin-container-triple__mobile;
    text-align: $text-align-container-triple-content__mobile;

    @include media-breakpoint-up(sm) {
        margin: $margin-container-triple__tablet;
    }

    @include media-breakpoint-up(md) {
        text-align: $text-align-container-triple-content;
    }

    @include media-breakpoint-up(xl) {
        margin: $margin-container-triple;
    }

    > .container-with-header-header > .container-with-header-title {
        margin-bottom: $margin-bottom-container-triple-header__mobile;
    
        @include media-breakpoint-up(sm) {
            margin-bottom: $margin-bottom-container-triple-header__tablet;
        }
    
        @include media-breakpoint-up(xl) {
            margin-bottom: $margin-bottom-container-triple-header;
        }
    }

    > .container-with-header-slots {
        margin-bottom: $margin-bottom-container-triple-content-offset__desktop-only;

        @include media-breakpoint-up(sm) {
            display: flex;
            flex-wrap: wrap;
        }

        > * {
            margin-bottom: $margin-bottom-container-triple-content__mobile;

            @include media-breakpoint-up(sm) {
                @include flex-width($width-container-triple-content__desktop-only);
                margin-right: $margin-right-container-triple-content__desktop-only;
                margin-bottom: 0;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:nth-child(3n-1) {
                    margin-top: $margin-top-container-triple-content-offset__desktop-only;
                }
            }
        }
    }

    .ms-content-block__image {
        margin-bottom: $margin-bottom-image-triple-content__desktop-only;

        img {
            max-width: 75%;
            margin: 0 auto;
        }

        @include media-breakpoint-up(sm) { 
            img {
                max-width: 100%;
            }
        }
    }
    .ms-content-block__cta {
        a {
            @include media-breakpoint-only(sm) {
                background-image: none;
            }
        }
    }

    .ms-content-block__title {
        margin-bottom: $margin-bottom-heading-triple-content;
    }
}

//==============================================================================
// ALTERNATING
//==============================================================================

%container-alt {
    margin: $margin-container-alt__mobile;

    @include media-breakpoint-up(sm) {
        margin: $margin-container-alt__tablet;
    }

    @include media-breakpoint-up(xl) {
        margin: $margin-container-alt;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    > .container-with-header-header {
        text-align: $text-align-container-alt-header;
    }

    > .container-with-header-slots > * {
        margin: $margin-container-alt-content__mobile;

        @include media-breakpoint-up(sm) {
            margin: $margin-container-alt-content__tablet;
        }

        @include media-breakpoint-up(md) {
            margin: $margin-container-alt-content__desktop;
        }

        @include media-breakpoint-up(xl) {
            margin: $margin-container-alt-content;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.container-with-header.layout__altleft,
.container-with-header.layout__altright {
    @extend %container-alt;
}

.container-with-header.layout__altleft > .container-with-header-slots > .ms-content-block:nth-child(odd),
.container-with-header.layout__altright > .container-with-header-slots > .ms-content-block:nth-child(even) {
    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
}

.container-with-header.layout__altleft > .container-with-header-slots > .ms-content-block:nth-child(even),
.container-with-header.layout__altright > .container-with-header-slots > .ms-content-block:nth-child(odd) {
    @include media-breakpoint-up(sm) {
        flex-direction: row-reverse;
    }
}

@mixin content-alt($breakpoint: sm) {
    @include content-alt-image($breakpoint);

    @include media-breakpoint-up($breakpoint) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    > .ms-content-block__details {
        @include media-breakpoint-up($breakpoint) {
            @include center-flex(x);
            flex-direction: column;
        }
    }
}

@mixin content-alt-image($breakpoint: sm) {
    > .ms-content-block__image {
        margin-bottom: 0;
    }

    > .ms-content-block__image,
    > .ms-content-block__image > picture {
        @include media-breakpoint-up($breakpoint) {
            display: flex;
        }

        > img {
            @include image-cover;
        }
    }
}

.ms-content-block[data-m-layout='detail-tile'] {
    @include content-alt(md);
}

.ms-content-block[data-m-layout='zigzag-tile'],
.ms-content-block[data-m-layout='trending-tile'] {
    @include content-alt;
}

.ms-content-block[data-m-layout='zigzag-tile'],
.ms-content-block[data-m-layout='zigzag-tile-alt'],
.ms-content-block[data-m-layout='detail-tile'] {
    > .ms-content-block__image picture {
        width: 100%;
    }
}

//==============================================================================
// DETAIL
//==============================================================================

.ms-content-block[data-m-layout='detail-tile'] {
    > .ms-content-block__image {
        @include media-breakpoint-up(md) {
            @include flex-width($width-container-detail-image);
        }
    }

    > .ms-content-block__details {
        width: $width-container-detail-content__mobile;
        margin: $margin-container-detail-content__mobile;

        @include media-breakpoint-up(sm) {
            width: $width-container-detail-content__tablet;
            margin: $margin-container-detail-content__tablet;
        }

        @include media-breakpoint-up(md) {
            @include flex-width($width-container-detail-content__desktop);
            margin: $margin-container-detail-content__desktop;
        }

        @include media-breakpoint-up(xl) {
            @include flex-width($width-container-detail-content);
            margin: $margin-container-detail-content;
        }
    }
}

//==============================================================================
// ZIGZAG
//==============================================================================

.ms-content-block[data-m-layout='zigzag-tile'] {
    > .ms-content-block__image {
        @include media-breakpoint-down(xs) {
            padding: $padding-container-zigzag-image__mobile;
        }

        @include media-breakpoint-up(sm) {
            @include flex-width($width-container-zigzag-image);
        }
    }

    > .ms-content-block__details {
        width: $width-container-zigzag-content__mobile;
        margin: $margin-container-zigzag-content__mobile;
        text-align: $text-align-container-zigzag-content__mobile;

        @include media-breakpoint-up(sm) {
            @include flex-width($width-container-zigzag-content__tablet);
            margin: $margin-container-zigzag-content__tablet;
            text-align: $text-align-container-zigzag-content;
        }

        @include media-breakpoint-up(xl) {
            @include flex-width($width-container-zigzag-content);
            margin: $margin-container-zigzag-content;
        }
    }

}

.ms-content-block[data-m-layout='zigzag-tile-alt'] {
    @extend .ms-content-block[data-m-layout='zigzag-tile'];

    @include media-breakpoint-down(xs) {
        display: flex;
        flex-direction: column-reverse;
    }

    > .ms-content-block__details {
        @include media-breakpoint-down(xs) {
            text-align: $text-align-container-zigzag-content;
            margin-top: 0;
        }
    }
}

//==============================================================================
// TRENDING
//==============================================================================

.ms-content-block[data-m-layout='trending-tile'] {
    > .ms-content-block__image {
        @include center-flex;
        width: $width-container-trending-image__mobile;
        padding: $padding-container-trending-image__mobile;
        margin: $margin-container-trending-image__mobile;
        background-color: $color-background-accent;

        @include media-breakpoint-up(sm) {
            @include flex-width($width-container-trending-image);
            padding: $padding-container-trending-image__tablet;
            margin: $margin-container-altleft-trending-image;
        }

        @include media-breakpoint-up(xl) {
            @include flex-width($width-container-trending-image);
            padding: $padding-container-trending-image;
        }
    }

    > .ms-content-block__details {
        width: $width-container-trending-content__mobile;
        margin: $margin-container-trending-content__mobile;
        text-align: $text-align-container-trending-content__mobile;

        @include media-breakpoint-up(sm) {
            @include flex-width($width-container-trending-content__tablet);
            margin: $margin-container-trending-content__tablet;
            text-align: $text-align-container-trending-content;
        }

        @include media-breakpoint-up(xl) {
            @include flex-width($width-container-trending-content);
            margin: $margin-container-trending-content;
        }
    }

    @include media-breakpoint-down(xs) {
        position: relative;
        margin-top: $margin-top-container-trending__mobile !important;

        .ms-content-block__title {
            position: absolute;
            bottom: 100%;
            left: 0;
            right: 0;
            margin: $margin-container-trending-content__mobile;
        }
    }
}

.container-with-header.layout__altleft > .container-with-header-slots > .ms-content-block[data-m-layout='trending-tile']:nth-child(even),
.container-with-header.layout__altright > .container-with-header-slots > .ms-content-block[data-m-layout='trending-tile']:nth-child(odd) {
    > .ms-content-block__image {
        @include media-breakpoint-up(sm) {
            margin: $margin-container-altright-trending-image;
        }
    }
}

//==============================================================================
// HERO
//==============================================================================

@mixin hero-absolute {
    position: relative;

    > .ms-content-block__details {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin hero-text-shadow {
    &.colortheme__default,
    &.colortheme__dark {
        .ms-content-block__title,
        .ms-content-block__text * {
            text-shadow: $text-shadow-text-hero-content;
        }
    }
}

@mixin hero-gradient {
    &.gradient__top,
    &.gradient__bottom,
    &.gradient__left,
    &.gradient__right {
        > .ms-content-block__image::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &.gradient__top > .ms-content-block__image::before {
        background: $background-hero-gradient-top;
    }

    &.gradient__bottom > .ms-content-block__image::before {
        background: $background-hero-gradient-bottom;
    }

    &.gradient__left > .ms-content-block__image::before {
        background: $background-hero-gradient-left;
    }

    &.gradient__right > .ms-content-block__image::before {
        background: $background-hero-gradient-right;
    }
}

%hero-absolute {
    @include hero-absolute;
    @include hero-text-shadow;

    @include media-breakpoint-up(xl) {
        @include hero-gradient;
    }
}

%hero-absolute-tablet {
    @include media-breakpoint-up(md) {
        @include hero-absolute;
        @include hero-text-shadow;
        @include hero-gradient;
    }

    > .ms-content-block__details {
        @include media-breakpoint-down(sm) {
            padding: $padding-container-hero-content__mobile !important;
        }
    }
}

%hero-absolute-desktop {
    @include media-breakpoint-up(xl) {
        @include hero-absolute;
        @include hero-text-shadow;
        @include hero-gradient;
    }

    > .ms-content-block__details {
        @include media-breakpoint-down(lg) {
            padding: $padding-container-hero-content__mobile !important;
        }
    }
}

%hero-image {
    margin-bottom: 0;

    img {
        @include image-cover;
    }
}

%hero-image-landscape {
    height: $height-image-hero-landscape__mobile;

    @include media-breakpoint-up(xxl) {
        height: $height-image-hero-landscape;
    }
}

%hero-image-tall {
    height: $height-image-hero-tall__mobile;
    min-height: $min-height-image-hero-tall__mobile;
    max-height: $max-height-image-hero-tall__mobile;

    @include media-breakpoint-up(lg) {
        height: $height-image-hero-tall__tablet;
    }

    @include media-breakpoint-up(xl) {
        height: $height-image-hero-tall;
        min-height: $min-height-image-hero-tall;
        max-height: $max-height-image-hero-tall;
    }
}

%hero-image-short {
    height: $height-image-hero-short__mobile;
    min-height: $min-height-image-hero-short;
    max-height: $max-height-image-hero-short;

    @include media-breakpoint-up(sm) {
        height: $height-image-hero-short__tablet;
    }

    @include media-breakpoint-up(lg) {
        height: $height-image-hero-short__desktop;
    }

    @include media-breakpoint-up(xl) {
        height: $height-image-hero-short;
    }
}

%hero-text {
    color: $color-text-hero-content;

    &.colortheme__light {
        color: $color-text-hero-content-inverse;
    }
}

%hero-background {
    background-color: $color-background-hero-content;

    &.colortheme__light {
        background-color: $color-background-hero-content-inverse;
    }
}

%hero-cta {
    > .ms-content-block__details > .ms-content-block__cta > * {
        @include button-width-auto;
    }

    &:not(.colortheme__light) {
        .ms-content-block__cta > * {
            @extend %button-hero;
        }

        &.ctastyle__primary > .ms-content-block__details > .ms-content-block__cta > * {
            @extend %button-hero-primary;
        }

        &.ctastyle__secondary > .ms-content-block__details > .ms-content-block__cta > * {
            @extend %button-hero-secondary;
        }
    }

    &.colortheme__light {
        .ms-content-block__cta > * {
            @extend %button-hero-inverse;
        }

        &.ctastyle__primary > .ms-content-block__details > .ms-content-block__cta > * {
            @extend %button-hero-primary-inverse;
        }

        &.ctastyle__secondary > .ms-content-block__details > .ms-content-block__cta > * {
            @extend %button-hero-secondary-inverse;
        }
    }
}

%hero-details {
    @include center-flex(y);
    flex-direction: column;
    padding-top: $padding-top-container-hero-content__mobile;

    @include media-breakpoint-up(sm) {
        padding-top: $padding-top-container-hero-content__tablet;
    }

    @include media-breakpoint-up(xl) {
        align-items: flex-start;
        justify-content: center;
        padding-top: 0;
        padding-left: $padding-x-container-hero-content;
    }

    > * {
        width: $width-container-hero-content__mobile;

        @include media-breakpoint-up(sm) {
            width: $width-container-hero-content__tablet;
        }

        @include media-breakpoint-up(lg) {
            width: $width-container-hero-content__desktop;
        }

        @include media-breakpoint-up(xl) {
            width: $width-container-hero-content;
        }
    }
}

%hero-text-placement-desktop {
    @include media-breakpoint-down(lg) {
        text-align: center;
    }

    @include media-breakpoint-up(xl) {
        &.textplacement__center > .ms-content-block__details {
            align-items: center;
            padding-left: 0;
        }

        &.textplacement__right > .ms-content-block__details {
            align-items: flex-end;
            padding-left: 0;
            padding-right: $padding-x-container-hero-content;
        }

        &.textplacementvertical__top > .ms-content-block__details {
            justify-content: flex-start;
            padding-top: $padding-y-container-hero-content;
        }

        &.textplacementvertical__bottom > .ms-content-block__details {
            justify-content: flex-end;
            padding-bottom: $padding-y-container-hero-content;
        }
    }
}

%hero-text-placement-tablet {
    @include media-breakpoint-up(md) {
        &.textplacement__center > .ms-content-block__details {
            align-items: center;
            padding-left: 0;
        }

        &.textplacement__right > .ms-content-block__details {
            align-items: flex-end;
            padding-left: 0;
            padding-right: $padding-x-container-hero-content;
        }

        &.textplacementvertical__top > .ms-content-block__details {
            justify-content: flex-start;
            padding-top: $padding-y-container-hero-content__tablet;
        }

        &.textplacementvertical__bottom > .ms-content-block__details {
            justify-content: flex-end;
            padding-bottom: $padding-y-container-hero-content__tablet;
        }
    }

    @include media-breakpoint-up(xl) {
        &.textplacementvertical__top > .ms-content-block__details {
            padding-top: $padding-y-container-hero-content;
        }

        &.textplacementvertical__bottom > .ms-content-block__details {
            padding-bottom: $padding-y-container-hero-content;
        }
    }
}

%hero {
    @extend %hero-background;
    @extend %hero-text;
    @extend %hero-cta;

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

    > .ms-content-block__image {
        @extend %hero-image;
    }

    > .ms-content-block__details {
        @extend %hero-details;
    }
}

.msc-carousel {
    &.indicatorlayout__left .msc-carousel__indicators {
        @include media-breakpoint-up(xl) {
            justify-content: flex-start;
            margin-left: $padding-x-container-hero-carousel-indicators;
        }
    }

    &.indicatorlayout__right .msc-carousel__indicators {
        @include media-breakpoint-up(xl) {
            justify-content: flex-end;
            margin-right: $padding-x-container-hero-carousel-indicators;
        }
    }
}

.ms-content-block[data-m-layout='home-hero'],
.ms-content-block[data-m-layout='full-hero'],
.ms-content-block[data-m-layout='half-hero'] {
    @extend %hero;
}

.ms-content-block[data-m-layout='half-hero'] {
    @include media-breakpoint-down(lg) {
        text-align: center;
    }

    > .ms-content-block__image {
        @extend %hero-image-tall;
    }
}

.ms-content-block[data-m-layout='full-hero'] {
    @extend %hero-absolute-tablet;
    @extend %hero-text-placement-tablet;

    > .ms-content-block__image {
        @extend %hero-image-short;
    }
}

.ms-content-block[data-m-layout='home-hero'] {
    @extend %hero-absolute-desktop;
    @extend %hero-text-placement-desktop;

    > .ms-content-block__image {
        @extend %hero-image-landscape;
    }
}

//==============================================================================
// HALF HERO
//==============================================================================

.ms-content-block[data-m-layout='half-hero'] {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: $margin-bottom-container-halfhero__mobile;

    @include media-breakpoint-up(sm) {
        margin-bottom: $margin-bottom-container-halfhero__tablet;
    }

    @include media-breakpoint-up(xl) {
        flex-direction: row-reverse;
        margin-bottom: 0;
    }

    > * {
        @include media-breakpoint-up(xl) {
            @include flex-width($width-container-halfhero-child);
        }
    }

    > .ms-content-block__details {
        @include media-breakpoint-down(lg) {
            padding-top: $padding-top-container-halfhero-content__tablet;
        }

        @include media-breakpoint-down(xs) {
            padding-top: $padding-top-container-halfhero-content__mobile;
        }
    }

    > .ms-content-block__image {
        @include media-breakpoint-down(lg) {
            padding: $padding-image-halfhero__tablet;
            margin-bottom: $margin-bottom-image-halfhero__tablet;
        }

        @include media-breakpoint-down(xs) {
            padding: $padding-image-halfhero__mobile;
            margin-bottom: $margin-bottom-image-halfhero__mobile;
        }
    }
}

//==============================================================================
// HOME HERO
//==============================================================================

.ms-content-block[data-m-layout='home-hero'] {
    @include media-breakpoint-up(xl) {
        &,
        &.ctastyle__default,
        &.ctastyle__primary,
        &.ctastyle__secondary {
            > .ms-content-block__details > .ms-content-block__cta > * {
                @include button(
                    $padding-button-primary,
                    $border-width-button-primary,
                    $border-style-button-primary,
                    $border-radius-button-primary,
                    $color-button-primary__border,
                    $color-button-primary__background,
                    $color-button-primary__text,
                    $color-button-primary__border--hover,
                    $color-button-primary__background--hover,
                    $color-button-primary__text--hover,
                    $font-family-button-primary,
                    $font-size-button-primary,
                    $font-size-button-primary__tablet,
                    $font-size-button-primary__mobile,
                    $font-weight-button-primary,
                    $font-style-button-primary,
                    $letter-spacing-button-primary,
                    $line-height-button-primary,
                    $line-height-button-primary__tablet,
                    $line-height-button-primary__mobile,
                    $text-transform-button-primary,
                    $text-decoration-button-primary,
                    $text-decoration-button-primary--hover
                );
                background-image: none;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &:not(.colortheme__dark):not(.colortheme__custom) {
            background-color: $color-background-hero-content-inverse;
            color: $color-text-hero-content-inverse;

            &:not(.ctastyle__primary):not(.ctastyle__secondary) {
                .ms-content-block__cta > * {
                    @include button;
                    @include button-color(
                        $color-button__border,
                        $color-button__background,
                        $color-button__text,
                        $color-button__border--hover,
                        $color-button__background--hover,
                        $color-button__text--hover
                    );
                }
            }

            &.ctastyle__default > .ms-content-block__details > .ms-content-block__cta > * {
                @include button-line-alt;
            }

            &.ctastyle__primary > .ms-content-block__details > .ms-content-block__cta > * {
                @include button-color(
                    $color-button-primary__border,
                    $color-button-primary__background,
                    $color-button-primary__text,
                    $color-button-primary__border--hover,
                    $color-button-primary__background--hover,
                    $color-button-primary__text--hover,
                );
            }

            &.ctastyle__secondary > .ms-content-block__details > .ms-content-block__cta > * {
                @include button-color(
                    $color-button-secondary__border,
                    $color-button-secondary__background,
                    $color-button-secondary__text,
                    $color-button-secondary__border--hover,
                    $color-button-secondary__background--hover,
                    $color-button-secondary__text--hover
                );
            }
        }
    }
}

.msc-carousel.home-carousel .msc-carousel__indicators {
    @include media-breakpoint-down(lg) {
        top: $top-container-hero-landscape-carousel-indicators__mobile;
        bottom: unset;
        margin: 0;
    }
}

//==============================================================================
// FULL HERO
//==============================================================================

.ms-content-block[data-m-layout='full-hero'] {
    @include media-breakpoint-down(sm) {
        &:not(.colortheme__dark):not(.colortheme__custom) {
            background-color: $color-background-hero-content-inverse;
            color: $color-text-hero-content-inverse;

            &:not(.ctastyle__primary):not(.ctastyle__secondary) {
                .ms-content-block__cta > * {
                    @include button;
                    @include button-color(
                        $color-button__border,
                        $color-button__background,
                        $color-button__text,
                        $color-button__border--hover,
                        $color-button__background--hover,
                        $color-button__text--hover
                    );
                }
            }

            &.ctastyle__default > .ms-content-block__details > .ms-content-block__cta > * {
                @include button-line-alt;
            }

            &.ctastyle__primary > .ms-content-block__details > .ms-content-block__cta > * {
                @include button-color(
                    $color-button-primary__border,
                    $color-button-primary__background,
                    $color-button-primary__text,
                    $color-button-primary__border--hover,
                    $color-button-primary__background--hover,
                    $color-button-primary__text--hover,
                );
            }

            &.ctastyle__secondary > .ms-content-block__details > .ms-content-block__cta > * {
                @include button-color(
                    $color-button-secondary__border,
                    $color-button-secondary__background,
                    $color-button-secondary__text,
                    $color-button-secondary__border--hover,
                    $color-button-secondary__background--hover,
                    $color-button-secondary__text--hover
                );
            }
        }
    }

    > .ms-content-block__details {
        @include media-breakpoint-up(md) {
            align-items: flex-start;
            justify-content: center;
            padding-top: 0;
            padding-left: $padding-x-container-hero-content;
        }
    }
}
