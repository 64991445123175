header:not([class]) {
    position: relative;
    z-index: $z-index-container-header;
}

.ms-header {

    // Header Container
    &__container {
        @extend %width-page;
        @include center-flex(y);
        flex-wrap: wrap;
        justify-content: space-between;
        height: $height-container-header__mobile;
        padding: $padding-container-header;
        background-color: $color-background-header;

        @include media-breakpoint-up(sm) {
            height: $height-container-header__tablet;
        }

        @include media-breakpoint-up(xl) {
            height: $height-container-header;
        }
    }

    // Header Logo
    &__logo {
        @include center-absolute(x);
        max-width: $max-width-image-header-logo__mobile;

        @include media-breakpoint-up(sm) {
            max-width: $max-width-image-header-logo__tablet;
        }

        @include media-breakpoint-up(xl) {
            max-width: $max-width-image-header-logo;
        }
    }

    // Header Nav Icon
    &__nav-icon {
        @include button-remove;
        @extend %icon-header-nav;
        position: absolute;
        z-index: $z-index-icon-header-nav;
        margin-top: $margin-top-icon-header-nav__mobile;

        &[aria-expanded='true'] {
            @extend %icon-header-close;
            top: $top-icon-header-nav__mobile;
            margin-top: 0;
        }

        @include media-breakpoint-up(sm) {
            &[aria-expanded='true'] {
                top: $top-icon-header-nav__tablet;
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            &[aria-expanded='false']::before {
                .header-inverse & {
                    filter: brightness(100);
                }
            }

            &[aria-expanded='true'] {
                top: ($top-icon-header-nav__tablet + 14);
            }
        }
    }

    // Header Top Bar
    &__topbar {
        @include center-flex(y);
        flex-wrap: wrap;
        margin-left: $margin-left-container-header-top;

        >*:not(:last-child) {
            margin-right: $margin-right-icon-header__mobile;

            @include media-breakpoint-up(sm) {
                margin-right: $margin-right-icon-header;
            }
        }
    }

    // Header Account and Cart Icons
    &__signin-button,
    &__profile-button,
    .ms-search__icon,
    .msc-cart-icon {
        @include button-remove;
        @include link-text-decoration(none);
        @include center-flex;

        >* {
            @extend %text-header-icon;
        }
    }

    // Header Account Icon
    &__signin-button,
    &__profile-button {
        @extend %icon-header-account;

        .ms-header__signin-button-text,
        .ms-profile-button-text {
            margin-right: $margin-right-text-header-icon;
        }
    }

    // Header Search Icon
    .ms-search__icon {
        @extend %icon-header-search;

        &-text {
            display: none;
        }
    }

    // Header Cart Icon
    .msc-cart-icon {
        @extend %icon-header-cart;

        &__count {
            @extend %text-header-icon-cart;
            margin-left: $margin-left-text-header-icon;
        }
    }

    // Header Account Dropdown
    .msc-popover {
        z-index: $z-index-container-header-dropdown-account;

        &-inner {
            margin-top: $margin-top-container-header-dropdown-account;
            border: $border-container-header-dropdown-account;
            box-shadow: $box-shadow-container-header-dropdown-account;
            background-color: $color-background-header-dropdown-account;
        }

        button,
        a {
            @extend %button-header-dropdown-account;
            display: block;
            padding: $padding-button-header-dropdown-account;

            &:first-child {
                padding-top: $padding-top-button-header-dropdown-account;
            }

            &:last-child {
                padding-bottom: $padding-bottom-button-header-dropdown-account;
            }
        }

        .msc-arrow {
            display: none;
        }
    }

    // Header Nav
    &__nav {
        >.ms-nav.nav-closed {
            display: none;
        }

        >.ms-nav.nav-closed>.ms-nav__list>.ms-nav__list__item {

            >.ms-nav__list__item__button,
            >.ms-nav__list__item__link,
            >.ms-nav__list__item__span {
                &.selected {
                    @extend %button-header-nav-active;
                }
            }
        }

        >.ms-nav.nav-open>.ms-nav__list>.ms-nav__list__item {

            >.ms-nav__list__item__button,
            >.ms-nav__list__item__link,
            >.ms-nav__list__item__span {
                &[aria-expanded='true'] {
                    @extend %button-header-nav-active;

                    @include hover-all {
                        @extend %button-header-nav-active;
                    }
                }
            }
        }

        >.ms-nav {
            @include animation-fadeInRight;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            min-width: 100%;
            padding-top: $nav-menu-padding-top-sm;
            z-index: $z-index-container-header-nav;
            background-color: $color-background-header-dropdown;

            @media only screen and (min-width: 450px) {
                min-width: 450px;
            }

            @include media-breakpoint-up(sm) {
                padding-top: ($nav-menu-padding-top-sm + 15);
            }

            @include media-breakpoint-up(lg) {
                max-width: 55%;
            }

            @include media-breakpoint-up(xl) {
                padding-top: $nav-menu-padding-top-lg;
            }
        }

        // Header Nav Links
        >.ms-nav>.ms-nav__list {
            @include center-flex(y);
            position: relative;
            flex-wrap: wrap;
            padding: $padding-container-dropdown-item__mobile;

            @include media-breakpoint-up(sm) {
                padding: $padding-container-dropdown-item__tablet;
            }

            @include media-breakpoint-up(xl) {
                padding: $padding-container-dropdown-item;
            }

            // Header Nav Item
            >.ms-nav__list__item {
                flex-shrink: 0;
                margin-bottom: 15px;

                &:not(:last-child) {
                    margin-right: 15px;
                    @media (min-width: 450px) {
                        margin-right: $margin-right-button-header-nav__tablet;
                    }

                    @include media-breakpoint-up(xl) {
                        margin-right: $margin-right-button-header-nav;
                    }
                }

                // Header Nav Button
                >.ms-nav__list__item__button,
                >.ms-nav__list__item__link,
                >.ms-nav__list__item__span {
                    @extend %button-header-nav;
                    position: relative;
                    letter-spacing: 0.15em;
                    @media (min-width: 450px) {
                        letter-spacing: 0.25em;
                    }

                    &::before {
                        @include transition(width);
                        content: '';
                        width: 0;
                    }

                    @include hover-all {
                        @include media-breakpoint-up(lg) {
                            @include button-line($color: $color-line-header-nav--hover, $height: $width-line-header-nav);

                            &.selected {
                                @include button-line($color: $color-line-header-nav--active, $height: $width-line-header-nav);
                            }
                        }
                    }
                }
            }
        }
    }

    // Submenu Items
    .ms-nav__list__wrapper {
        position: absolute;
        top: var(--navMenuHeight);
        left: 0;
        width: 100%;

        .ms-nav__list {
            // Header Nav Dropdown
            display: none;
            height: calc(100vh - #{$nav-menu-padding-top-sm} - var(--navMenuHeight));
            padding-bottom: 30px;
            overflow-y: auto;

            @include media-breakpoint-up(sm) {
                height: calc(100vh - #{$nav-menu-padding-top-sm + 15} - var(--navMenuHeight));
            }

            @include media-breakpoint-up(xl) {
                height: calc(100vh - #{$nav-menu-padding-top-lg} - var(--navMenuHeight));
            }

            // Header Nav Dropdown Item
            >.ms-nav__list__item {
                padding: $padding-container-dropdown-item__mobile;
                margin-bottom: $margin-bottom-container-dropdown-item__mobile;

                @include media-breakpoint-up(sm) {
                    padding: $padding-container-dropdown-item__tablet;
                }

                @include media-breakpoint-up(xl) {
                    padding: $padding-container-dropdown-item;
                    margin-bottom: $margin-bottom-container-dropdown-item;
                }

                // Header Nav Dropdown Button
                &:not(.ms-nav__list__item-alt) {

                    >.ms-nav__list__item__span,
                    >.ms-nav__list__item__link,
                    >.ms-nav__list__item__button {
                        @extend %button-header-dropdown;

                        &.selected {
                            @extend %button-header-dropdown-active;
                        }
                    }
                }

                &-alt {
                    margin-top: $margin-top-container-dropdown-item-alt__mobile;

                    @include media-breakpoint-up(sm) {
                        margin-top: $margin-top-container-dropdown-item-alt__tablet;
                    }

                    @include media-breakpoint-up(xl) {
                        margin-top: $margin-top-container-dropdown-item-alt;
                    }

                    &+.ms-nav__list__item-alt {
                        margin-top: $margin-top-container-dropdown-item-alt-sibling__mobile;

                        @include media-breakpoint-up(xl) {
                            margin-top: $margin-top-container-dropdown-item-alt-sibling;
                        }
                    }

                    >.ms-nav__list__item__span,
                    >.ms-nav__list__item__link,
                    >.ms-nav__list__item__button {
                        @extend %button-header-dropdown-alt;
                        position: relative;

                        &.selected {
                            @extend %button-header-dropdown-alt-active;
                        }

                        &::before {
                            @include transition(width);
                            content: '';
                            width: 0;
                        }

                        @include hover-all {
                            @include media-breakpoint-up(lg) {
                                @include button-line($color: $color-line-header-dropdown-alt--hover, $height: $width-line-header-dropdown-alt);

                                &.selected {
                                    @include button-line($color: $color-line-header-dropdown-alt--active, $height: $width-line-header-dropdown-alt);
                                }
                            }
                        }
                    }
                }
            }

            &.expanded {
                display: block !important;
            }
        }
    }
}

// Body translucent overlay when nav is open
#ms-header__subnav-fade {
    display: none;
    position: fixed;
    z-index: $z-index-container-header-dropdown-overlay;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: $color-background-container-header-dropdown-overlay;
    opacity: 0;

    &[data-expanded='true'] {
        @include animation-fadeInTranslucent;
        display: block;
    }
}

// When header is fixed, 100% is relative to header.
// Instead, use 100 of viewport height.
.sticky-header {
    #ms-header__subnav-fade {
        height: 100vh;
    }
}

// Body is fixed when nav is open
body.fixed {
    overflow: hidden;
}

// Pull Home main container up so transparent header has a background
.home {
    main {
        @include media-breakpoint-up(xl) {
            margin-top: -$height-container-header;
        }
    }
}

// Header with inverted colors
.header-inverse-override .ms-header,
.ms-header.header-inverse,
.ms-header.header-inverse-promo {
    @include media-breakpoint-up(xl) {
        background: $color-background-header-gradient-inverse;

        .ms-header__container {
            background-color: $color-background-header-inverse;
        }

        .ms-header__logo img,
        .ms-header__logo a::before {
            @include image-invert;
        }

        .ms-header__signin-button,
        .ms-header__profile-button,
        .ms-search__icon,
        .msc-cart-icon {

            &::before,
            &::after {
                @include image-invert;
            }

            >* {
                @include text-color($color-text-inverse);
            }
        }

        .ms-header__nav {
            >.ms-nav.nav-closed>.ms-nav__list>.ms-nav__list__item {

                >.ms-nav__list__item__button,
                >.ms-nav__list__item__link,
                >.ms-nav__list__item__span {
                    @include button-color-text($color-button-inverse__text, $color-button-inverse__text--hover);
                    text-shadow: $text-shadow-button-header-nav-inverse;

                    &.selected {
                        @include button-line($color: $color-line-header-nav-inverse--active, $height: $width-line-header-nav);
                    }

                    @include class-hover-all(selected) {
                        @include button-line($color: $color-line-header-nav-inverse--hover, $height: $width-line-header-nav);
                    }
                }
            }
        }
    }
}

.ms-header__nav {
    .ms-header__logo {
        display: none;
    }

    .ms-nav.nav-open~.ms-header__logo {
        @include media-breakpoint-down(xs) {
            @include animation-fadeIn;
            @include animation-delay(0.1s);
            display: block;
            z-index: $z-index-image-header-logo-nav__mobile;
            top: $top-image-header-logo-nav__mobile;
        }
    }
}

.hide-cart {

    .msc-cart-icon,
    .ms-cart-icon {
        display: none !important;
    }
}

.hide-search .ms-search {
    display: none !important;
}

.hide-account .ms-header__account-info {
    display: none !important;
}