// Mailing List Alert
$margin-top-alert-mailing-list: 10px;

%alert-mailing-list {
    @extend %alert;
    margin-top: $margin-top-alert-mailing-list;

    &-danger {
        @extend %alert-danger;
    }
}

// Mailing List Modal Dialog
$max-width-container-mailing-list-modal-dialog: 500px;

// Mailing List Modal Body
$padding-container-mailing-list-modal-body: 50px 50px 30px;
$padding-container-mailing-list-modal-body__mobile: 50px 25px 25px;

// Mailing List Modal Logo
$max-width-image-mailing-list-modal-logo: 250px;
$margin-image-mailing-list-modal-logo: 0 auto 35px;
$margin-image-mailing-list-modal-logo__mobile: $margin-image-mailing-list-modal-logo;

// Mailing List Modal Marketing
$margin-bottom-container-mailing-list-modal-marketing: 30px;
$margin-bottom-container-mailing-list-modal-marketing__mobile: 15px;
$text-align-container-mailing-list-modal-marketing: center;

%text-mailing-list-modal-marketing {
    @extend %text;
    @extend %text-small;
}

// Mailing List Modal Input
%input-mailing-list-modal {
    @extend %input;
}

// Mailing List Modal Button
$margin-button-mailing-list-modal: 30px auto 0;

%button-mailing-list-modal {
    @extend %button-primary;
    display: block;
    margin: $margin-button-mailing-list-modal;

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}

// Mailing List Modal Links
$margin-top-link-mailing-list-modal: 15px;
$text-align-link-mailing-list-modal: center;

%link-mailing-list-modal {
    @include link-text-decoration(none);
    @extend %text;
    @extend %text-small;
    display: block;
    text-align: $text-align-link-mailing-list-modal;
    margin-top: $margin-top-link-mailing-list-modal;
}

// Mailing List Modal Success Message
$padding-container-mailing-list-modal-success: 8px 15px;
$text-align-container-mailing-list-modal-success: center;

%text-mailing-list-modal-success {
    @extend %heading-h4;
}
