.msc-cart-line {
    display: flex;

    &__brand,
    &__product-variants {
        display: none;
    }

    &__product-image {
        @include flex-width($width-image-cart-line);
        margin-right: $margin-right-image-cart-line;

        img {
            max-height: $max-height-image-cart-line;
            margin: auto;

            @include media-breakpoint-up(sm) {
                max-height: $max-height-image-cart-line;
            }
        }
    }

    .msc-empty_image {
        background-size: $width-image-cart-line $max-height-image-cart-line;
        width: $width-image-cart-line;
        max-height: $max-height-image-cart-line;

        @include media-breakpoint-up(sm) {
            background-size: $width-image-cart-line $max-height-image-cart-line;
            max-height: $max-height-image-cart-line;
        }
    }

    &__content {
        @include center-flex(x);
        flex-direction: column;
    }

    &__quantity .quantity-label {
        margin-bottom: 0;

        &::after {
            @extend %separator-cart-line-quantity;
        }
    }

    .msc-price__strikethrough {
        margin-right: $margin-right-text-cart-line-price-strike;
        color: $color-text-cart-line-price-strike;
        text-decoration: $text-decoration-cart-line-price-strike;
    }
}
