.club-menu {
    margin: 30px auto 50px;

    @include media-breakpoint-up(xl) {
        margin: 30px auto 75px;
    }
}

.club-link-list {
    @include width-container(
        $width-content-lg,
        $width-content-lg,
        $width-content-lg
    );

    .link-list__links {
        text-align: center;
    }

    .link-list__link {
        @extend %button;
        position: relative;
        margin-right: 20px;

        &::before {
            @include transition(width);
            content: '';
            width: 0;
        }

        @include media-breakpoint-up(sm) {
            margin-right: 30px;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 40px;
        }

        @include media-breakpoint-up(xl) {
            margin-right: 50px;
        }

        &:last-child {
            margin-right: 0;
        }

        @include hover-all {
            @include button-line($color: $color-line-header-nav--hover, $height: $width-line-header-nav);

            &.active {
                @include button-line($color: $color-line-header-nav--active, $height: $width-line-header-nav);
            }
        }

        &.active {
            @include button-line($color: $color-line-header-nav--active, $height: $width-line-header-nav);
        }
    }
}
