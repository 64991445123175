// Order Summary Heading
%heading-order-summary {
    @extend %heading-h3;
}

// Order Summary Items
%text-order-summary-items {
    @extend %text;
    @extend %text-small;
}

// Order Summary Left Column
$margin-right-container-order-summary-left: 15px;

// Order Summary Right Column
$width-container-order-summary-right: 130px;
$text-align-container-order-summary-right: right;

// Order Summary Total
$margin-top-text-order-summary-total: 15px;
$font-weight-text-order-summary-total: $font-weight-bold;

%text-order-summary-total {
    @extend %text;
    @include text-font-weight($font-weight-text-order-summary-total);
}

// Order Summary Warning
%text-order-summary-warning {
    @extend %text-appellation;
    @extend %text-appellation-accent;
}

// Order Summary Conditions
$margin-top-text-order-summary-conditions: -5px;

%text-order-summary-conditions {
    @extend %text-appellation;
}
