.ms-breadcrumb {
    @extend %width-page;
    @extend %text-breadcrumb;
    display: none;
    margin: $margin-container-breadcrumb;

    @include media-breakpoint-up(xl) {
        display: flex;
    }

    > * {
        display: inline-flex;
        align-items: center;
    }

    &-cta {
        @extend %link-breadcrumb;
        padding: 0;
        border: 0;
        color: #423737 !important;
        &:hover{
            text-decoration: underline !important;
        }
    }

    &-data-separator {
        @extend %divider-breadcrumb;
    }

    &-data-text {
        @extend %link-breadcrumb-active;
        color: #423737 !important;
    }
}
