.ms-checkout {
  &__body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__main {
    @include media-breakpoint-up(md) {
      @include flex-width($width-container-checkout-main__tablet);
      margin-right: $margin-right-container-checkout-main__tablet-up;
    }

    @include media-breakpoint-up(lg) {
      @include flex-width($width-container-checkout-main__desktop);
    }

    @include media-breakpoint-up(xl) {
      @include flex-width($width-container-checkout-main);
      padding: $padding-container-checkout-main__xl-only;
    }
  }

  &__side {
    background-color: $color-background-container-checkout-side;
    padding: $padding-container-checkout-side__mobile;
    margin: $margin-container-checkout-side__mobile;
    order: 1;
    @include media-breakpoint-up(md) {
      flex: 1;
      padding: $padding-container-checkout-side__tablet;
      margin: 0;
    }

    @include media-breakpoint-up(xl) {
      padding: $padding-container-checkout-side;
    }

    > * {
      padding: $padding-container-checkout-side-section;
    }
  }

  &__guided-form > *:not(:last-child) {
    border-bottom: 1px solid $color-border-accent;
  }

  &__guided-card {
    @extend %text-checkout-card;
    position: relative;
    padding: $padding-container-checkout-card;

    @include media-breakpoint-up(xl) {
      display: flex;
      flex-wrap: wrap;
    }

    &-header {
      @include media-breakpoint-up(xl) {
        @include flex-width(
          $width-container-checkout-card-header__desktop-only
        );
        margin-right: $margin-right-container-checkout-card-header__desktop-only;
      }
    }

    &:not(.closed) .ms-checkout__guided-card-header {
      margin-bottom: $margin-bottom-container-checkout-card-header__mobile;

      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }

    &-title {
      @extend %heading-checkout-card;
      margin-bottom: 0;

      &-step {
        display: none;
      }
    }

    &-btn-edit {
      @extend %button-checkout-card;
      position: absolute;
      bottom: $bottom-button-checkout-card__mobile;
      z-index: 1;

      &:disabled,
      &.disabled {
        @extend %button-disabled;
      }

      @include media-breakpoint-up(xl) {
        bottom: unset;
        top: $top-button-checkout-card__desktop-only;
        right: 0;
      }
    }

    &.disabled,
    &.closed .ms-checkout__guided-card-body {
      display: none;
    }

    &-body {
      backface-visibility: hidden;
      background-color: transparent; //web 255 fix; checkout > email address
      @include media-breakpoint-up(xl) {
        flex: 1;
        margin-top: $margin-top-container-checkout-card-body__desktop-only;
      }
    }

    &-footer {
      margin-top: $margin-top-container-checkout-card-footer;

      > *:not(:last-child) {
        margin-right: $margin-right-button-checkout-card-footer;
      }
    }

    &-btn-save {
      @extend %button-checkout-card-save;
    }

    &-btn-cancel {
      @extend %button-checkout-card-cancel;
    }
  }

  &-section-container {
    &__item:not(:first-child) {
      margin-bottom: 5px;
    }

    &__item.hidden {
      display: none;
    }
  }

  &-shipping-address {
    .msc-address-form__item-threeletterisoregionname {
      display: none;
    }

    .msc-address-select {
      position: relative;
      margin-bottom: $margin-bottom-container-checkout-card-address__mobile;

      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }

      &__button-add {
        @extend %button-checkout-card-address-add;
        position: absolute;
        top: $top-button-checkout-card-address-add__mobile;
        z-index: 1;

        @include media-breakpoint-up(xl) {
          top: $top-button-checkout-card-address-add;
          right: 0;
        }
      }

      &__item {
        @include media-breakpoint-up(lg) {
          margin-left: $margin-left-container-checkout-card-address-item__desktop-only;
        }

        @include media-breakpoint-up(xl) {
          width: $width-container-checkout-card-address-item__xl-only;
        }
      }

      .msc-address-detail {
        margin: 0;
      }
    }
  }

  &-billing-address__heading {
    display: none;
  }

  &-delivery-options {
    &__option,
    &__option-selected {
      display: flex;
      justify-content: space-between;
      max-width: $max-width-container-checkout-card-delivery;
    }

    &__label {
      margin-bottom: 0;
    }

    &__price {
      line-height: $line-height-radio-checkout-card;
    }
  }

  &-payment-instrument {
    margin-left: $margin-container-checkout-card-payment;

    .checkout-payment-instrument__iframe {
      border: 0;
    }

    &__card-type-id {
      display: none;
    }

    &__loading-background {
      margin: $margin-container-checkout-card-payment-content;
    }

    &__show {
      margin: $margin-container-checkout-card-payment-content;

      > * {
        margin-bottom: 0;
      }
    }

    &__card-title {
      @extend %heading-checkout-card-payment;
      margin-bottom: $margin-bottom-heading-checkout-card-payment;
    }

    &__error {
      margin: $margin-alert-checkout-card-payment-error;

      &-title {
        display: none;
      }

      &-message {
        @extend %alert-checkout-card-error;
      }
    }
  }

  &-gift-card {
    margin-top: $margin-top-container-checkout-card-gift__mobile;
    margin-bottom: $margin-bottom-container-checkout-card-gift__mobile;

    @include media-breakpoint-up(lg) {
      margin-top: $margin-top-container-checkout-card-gift;
      margin-bottom: 0;
    }

    &__label {
      margin-bottom: 0;
    }

    &__input-text {
      @extend %input-checkout-card;
    }

    &__input-error {
      @extend %alert-checkout-card-error;
      display: block;
      margin-bottom: $margin-bottom-alert-checkout-card-gift-error;
    }

    &__btn-apply {
      @extend %button-checkout-card-gift-apply;
      margin-top: $margin-top-button-checkout-card-gift-apply;
    }

    &__list {
      margin-top: 15px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: $margin-bottom-container-checkout-card-gift-item;
      }
    }

    &__btn-remove {
      @extend %button-checkout-card-gift-remove;
    }
  }

  &-guest-profile,
  .checkout-date-of-birth {
    label {
      @extend %label-checkout-card;
    }

    input {
      @extend %input-checkout-card;
    }
  }

  .checkout-date-of-birth {
    label {
      margin-bottom: 0;
      font-size: 0;
    }

    &__input-error {
      @extend %alert-checkout-card-dob-error;
    }
  }

  &-delivery-options__error,
  &-guest-profile__input-error,
  .checkout-date-of-birth__input-error {
    @extend %alert-checkout-card-error;
    display: block;
    margin-bottom: $margin-bottom-checkout-card-error;
  }

  .ms-checkout-billing-address__shipping-address-label,
  .ms-checkout-delivery-options__label,
  .checkout-terms-and-conditions label {
    cursor: pointer;

    > * {
      vertical-align: middle;
    }

    input {
      position: static;
      margin: $margin-radio-checkout-card;
    }

    p,
    span {
      display: inline;
      line-height: $line-height-radio-checkout-card;
    }
  }

  .checkout-terms-and-conditions label {
    display: flex;
    margin-bottom: $margin-bottom-container-checkout-card-toc;

    input {
      margin-top: $margin-top-radio-checkout-card-toc;
    }

    p,
    span {
      display: inline;
      line-height: $line-height-radio-checkout-card-toc;
    }
  }

  &-shipping-address .msc-address-detail,
  &-billing-address .msc-address-detail,
  &-delivery-options .ms-checkout-delivery-options__option-selected,
  &-payment-instrument .ms-checkout-payment-instrument__show,
  &-guest-profile .ms-checkout-guest-profile__selected-item,
  .checkout-date-of-birth input:disabled {
    margin-bottom: $margin-bottom-container-checkout-card__mobile;

    @include media-breakpoint-up(xl) {
      width: $width-container-checkout-card__desktop;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(xxl) {
      width: $width-container-checkout-card;
    }
  }

  &__main-control,
  &__side-control {
    margin-top: $margin-top-container-checkout-control;

    > * {
      width: 100%;
      margin-bottom: $margin-bottom-button-checkout-control;

      @include media-breakpoint-up(sm) {
        width: auto;
        margin-bottom: 0;
        margin-right: $margin-right-button-checkout-control;
      }

      @include media-breakpoint-up(md) {
        width: 100%;
        margin-bottom: $margin-bottom-button-checkout-control;
        margin-right: 0;
      }

      @include media-breakpoint-up(xl) {
        width: auto;
        margin-bottom: 0;
        margin-right: $margin-right-button-checkout-control;
      }

      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }

  &__side-control {
    &-first,
    &-second {
      display: none;
    }
  }

  &__btn-place-order {
    @extend %button-checkout-control-order;
  }

  &__btn-keep-shopping {
    @extend %button-checkout-control-shop;
  }

  .msc-order-summary__heading {
    margin-bottom: $margin-bottom-heading-checkout-side;
  }

  &__line-items {
    @extend %text-checkout-line-items;
    position: relative;
    border-top: $border-top-container-checkout-line-items;

    &-heading {
      display: none;
    }

    &-header {
      position: absolute;
      right: 0;
      z-index: 1;
    }

    &-edit-cart-link {
      @extend %button-checkout-line-items;
    }

    &-group-title {
      @extend %heading-checkout-line-items;
      width: $width-heading-checkout-line-items__mobile;

      @include media-breakpoint-up(xl) {
        width: $width-heading-checkout-line-items;
      }
    }

    &-list {
      margin-top: $margin-top-container-checkout-line-items-list;

      > *:not(:last-child) {
        margin-bottom: $margin-bottom-container-checkout-line-item;
      }
    }

    &-list-title {
      @extend %heading-checkout-line-items-list;
    }
  }

  &__line-item .msc-cart-line {
    &__brand,
    &__product-image,
    &-item-product-discount {
      display: none;
    }

    &__content {
      display: flex;
      flex-direction: row;
      flex: 1;
      position: relative;

      > *:not(:last-child) {
        margin-right: $margin-right-container-checkout-line-item-content;
      }
    }

    &__product {
      flex: 1;

      &-title {
        @extend %text-checkout-line-item-product;
        @include link-text-decoration(none);
        @include truncation-line($lines-text-checkout-line-item-product);
      }
    }

    &__quantity {
      @include flex-width($width-container-checkout-line-item-quantity);

      .quantity-label {
        display: none;
      }

      .quantity-value::before {
        content: $content-prefix-checkout-line-item-quantity;
        margin-right: $margin-right-prefix-checkout-line-item-quantity;
      }
    }

    &__product-price {
      @include flex-width($width-container-checkout-line-item-price__mobile);
      margin-bottom: $margin-bottom-container-checkout-line-item-price;
      text-align: right;

      @include media-breakpoint-up(sm) {
        @include flex-width($width-container-checkout-line-item-price__tablet);
      }

      @include media-breakpoint-up(xl) {
        @include flex-width($width-container-checkout-line-item-price__desktop);
      }

      @include media-breakpoint-up(xxl) {
        @include flex-width($width-container-checkout-line-item-price);
      }

      .msc-price__strikethrough {
        display: none;
      }
    }

    &-item-product-discount > span:not([class]),
    &__product-savings-label {
      display: none;
    }

    &-item-product-discount .msc-price__actual {
      @extend %text-checkout-line-item-savings;
      position: absolute;
      top: $top-text-checkout-line-item-savings;
      right: 0;

      &::after {
        content: $content-text-checkout-line-item-savings;
        margin-left: $margin-left-text-checkout-line-item-savings;
      }
    }
  }

  &__error-message {
    @extend %alert-checkout-error;
    flex: 0 0 100%;
    margin: $margin-checkout-error;
  }

  .msc-p65-warning-icon {
    flex: 0;
    max-width: none;
    margin-right: $margin-right-icon-checkout-p65;

    img {
      max-width: $max-width-icon-checkout-p65;
    }
  }

  .msc-p65-warning-text {
    @extend %text-checkout-p65;
    flex: 1;
    max-width: none;
  }
}

// Paypal ready styling
.ms-checkout-payment-instrument-paypal .ms-checkout-payment-instrument {
  &__card-type-id {
    display: block;
  }
}

.ms-checkout-billing-note {
  font-size: 11px;
  line-height: normal;
}