// Essential Icons
$asset-icon-menu: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2BmQ');
$asset-icon-user: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2BmB');
$asset-icon-search: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2Bn8');
$asset-icon-cart: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2Bmm');
$asset-icon-delete: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2BlY');
$asset-icon-close: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2BmK');
$asset-icon-submit: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2Bmy');
$asset-icon-download: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2Bmp');
$asset-icon-check: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2BmW');
$asset-icon-selected: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2Bmd');
$asset-icon-dropdown-expand: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2BmN');
$asset-icon-dropdown-collapse: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2BmZ');
$asset-icon-accordion-expand: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2BmH');
$asset-icon-accordion-collapse: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2BmE');

$asset-icon-carousel-prev: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MAktvS');
$asset-icon-carousel-next: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MAkw7q');

// Social Icons
$asset-icon-social-instagram: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2Bn5');
$asset-icon-social-facebook: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2Bmv');
$asset-icon-social-twitter: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2Bnb');
$asset-icon-social-youtube: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2BmT');

// Legal Icons
$asset-icon-enjoy: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2Bn2');
$asset-icon-ea: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/binary/MC2Bms');

// Backgrounds
$asset-background-shadow: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/mhtmhnbtbn/imageFileData/MC2GuF')
