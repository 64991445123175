.mailing-list {
    .form-group label {
        // display: none;
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }

    .input-group-mailing-list-button{
        &:focus{
            outline: 0 !important;
            border-color: #80bdff;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }

    .text-input-validation-error {
        @extend %alert-mailing-list;
        @extend %alert-mailing-list-danger;
    }

    &-modal {
        .msc-modal__dialog {
            max-width: $max-width-container-mailing-list-modal-dialog;
        }

        .msc-modal__body {
            padding: $padding-container-mailing-list-modal-body__mobile;

            @include media-breakpoint-up(lg) {
                padding: $padding-container-mailing-list-modal-body;
            }
        }
        
        &-logo {
            max-width: $max-width-image-mailing-list-modal-logo;
            margin: $margin-image-mailing-list-modal-logo__mobile;

            @include media-breakpoint-up(lg) {
                margin: $margin-image-mailing-list-modal-logo;
            }
        }

        .mailing-list-form-marketing {
            text-align: $text-align-container-mailing-list-modal-marketing;
            margin-bottom: $margin-bottom-container-mailing-list-modal-marketing__mobile;

            @include media-breakpoint-up(lg) {
                margin-bottom: $margin-bottom-container-mailing-list-modal-marketing;
            }

            &-description {
                @extend %text-mailing-list-modal-marketing;
            }
        }

        input.form-control {
            @extend %input-mailing-list-modal;
        }

        .input-group-mailing-list-button {
            @extend %button-mailing-list-modal;
        }

        .popup-modal-close-text {
            @extend %link-mailing-list-modal;
        }

        .mailing-list-success-message {
            padding: $padding-container-mailing-list-modal-success;
            text-align: $text-align-container-mailing-list-modal-success;

            > * {
                @extend %text-mailing-list-modal-success;
            }
        }
    }
}
