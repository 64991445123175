// Product Breadcrumb
$padding-container-product-breadcrumb: 0 calc(0.5/16 * 100%);

// Product Details
$margin-container-product-details__desktop-only: 60px 0;

// Product Accordion
$margin-container-product-accordion: 0 calc(2/16 * 100%);
$margin-container-product-accordion__desktop: 0 calc(1/16 * 100%);
$margin-container-product-accordion__tablet: 70px 0 0;
$margin-container-product-accordion__mobile: 0;
$margin-left-container-product-accordion__tablet: calc(9/16 * 100%);

// Product Accordion Heading
%heading-product-accordion {
    @extend %heading-h6;
}

// Product Accordion Item
$padding-container-product-accordion-item: 15px 0;
$padding-container-product-accordion-item__mobile: 20px calc(1/16 * 100%);

// Product Accordion Content
$padding-container-product-accordion-content: 10px 0 0;

// Product Attribute Separator
$content-separator-product-attribute: ':';
$margin-right-separator-product-attribute: 5px;

// Product Attribute Serving Size
$margin-top-text-product-attribute-serving: 12px;

%text-product-attribute-serving {
    @extend %text-appellation;
}

// Product Attribute Download Icon
$margin-right-icon-product-attribute-download: 15px;
$margin-bottom-icon-product-attribute-download: 4px;
$width-icon-product-attribute-download: 10px;
$height-icon-product-attribute-download: 11px;
$icon-product-attribute-download: $asset-icon-download;

%icon-product-attribute-download {
    @include icon-image($icon-product-attribute-download, before, $width-icon-product-attribute-download, $height-icon-product-attribute-download);
}

// Product Details Image
$width-image-product-details: calc(9/16 * 100%);
$width-image-product-details__tablet: calc(14/16 * 100%);
$margin-image-product-details: 40px auto;
