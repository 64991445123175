// Minicart
$z-index-container-minicart: 1060;
$width-container-minicart: 400px;
$padding-container-minicart: 20px;
$border-container-minicart: 1px solid $color-border;
$box-shadow-container-minicart: 0 6px 6px transparentize($color-border, 0.5);
$color-background-container-minicart: $color-background;

// Minicart Heading
$margin-bottom-heading-minicart: 10px;
$text-transform-heading-minicart: capitalize;

%heading-minicart {
    @extend %heading-h4;
    @include heading-text-transform($text-transform-heading-minicart);
}

// Minicart Lines
$max-height-container-minicart-lines: 400px;
$margin-bottom-container-minicart-lines: 10px;

// Minicart Line Item
$margin-container-minicart-line-item: 10px 0;

// Minicart Product Image
$width-image-minicart-product: 40px;
$max-height-image-minicart-product: 60px;

// Minicart Product Title
$margin-right-heading-minicart-product-title: 5px;
$width-heading-minicart-product-title: calc(50% - #{$margin-right-heading-minicart-product-title});
$text-decoration-heading-minicart-product-title: none;
$text-decoration-heading-minicart-product-title--hover: none;

%heading-minicart-product-title {
    @extend %heading-h5;
    @include link-text-decoration($text-decoration-heading-minicart-product-title, $text-decoration-heading-minicart-product-title--hover);
}

// Minicart Product Quantity
$margin-right-select-minicart-product-quantity: 5px;
$width-select-minicart-product-quantity: calc(22% - #{$margin-right-select-minicart-product-quantity});
$padding-select-minicart-product-quantity: 7px 0 3px 3px;
$text-align-select-minicart-product-quantity: center;

%select-minicart-product-quantity {
    @extend %select;
    padding: $padding-select-minicart-product-quantity;
    text-align: $text-align-select-minicart-product-quantity;
    text-align-last: $text-align-select-minicart-product-quantity;
}

// Minicart Product Price
$margin-right-text-minicart-product-price: 10px;
$width-text-minicart-product-price: calc(28% - #{$margin-right-text-minicart-product-price});
$text-align-text-minicart-product-price: right;

%text-minicart-product-price {
    @extend %text;
    @extend %text-small;
}

// Minicart Product Variant
$content-minicart-product-variant: ',';

%text-minicart-product-variant {
    @extend %text;
    @extend %text-small;
}

// Minicart Product Remove Icon
$margin-bottom-icon-minicart-remove: 3px;
$icon-minicart-product-remove: $asset-icon-delete;
$size-icon-minicart-product-remove: 14px;
$opacity-icon-minicart-product-remove: 0.5;
$opacity-icon-minicart-product-remove--hover: 1;

%icon-minicart-product-remove {
    @include icon-image($icon-minicart-product-remove, before, $size-icon-minicart-product-remove);
}

// Minicart Product Remove Button
%button-minicart-product-remove {
    @include button-remove;
    @extend %icon-minicart-product-remove;
    @include transition;
    opacity: $opacity-icon-minicart-product-remove;
    font-size: 0;

    @include hover-all {
        opacity: $opacity-icon-minicart-product-remove--hover;
    }
}

// Minicart Subtotal
$margin-text-minicart-subtotal: 0 (-$padding-container-minicart);
$font-weight-text-minicart-subtotal: $font-weight-bold;
$text-align-text-minicart-subtotal: right;

%text-minicart-subtotal {
    @extend %text;
    @include text-font-weight($font-weight-text-minicart-subtotal);
}

// Minicart Buttons
$margin-bottom-button-minicart: 10px;

// Minicart Checkout Button
%button-minicart-checkout {
    @extend %button-primary;
}

%button-minicart-cart {
    @extend %button-secondary;
}
