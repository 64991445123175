@mixin button(
    $padding: $padding-button,
    $border-width: $border-width-button,
    $border-style: $border-style-button,
    $border-radius: $border-radius-button,

    $color__border: $color-button__border,
    $color__background: $color-button__background,
    $color__text: $color-button__text,
    $color__border--hover: $color-button__border--hover,
    $color__background--hover: $color-button__background--hover,
    $color__text--hover: $color-button__text--hover,

    $font-family: $font-family-button,
    $font-size: $font-size-button,
    $font-size__tablet: $font-size-button__tablet,
    $font-size__mobile: $font-size-button__mobile,
    $font-weight: $font-weight-button,
    $font-style: $font-style-button,
    $letter-spacing: $letter-spacing-button,
    $line-height: $line-height-button,
    $line-height__tablet: $line-height-button__tablet,
    $line-height__mobile: $line-height-button__mobile,
    $text-transform: $text-transform-button,
    $text-decoration: $text-decoration-button,
    $text-decoration--hover: $text-decoration-button--hover
) {
    @include transition;
    display: inline-block;
    padding: $padding;
    outline: 0;
    border-radius: $border-radius;
    border-width: $border-width;
    border-style: $border-style;
    border-color: $color__border;
    background-color: $color__background;
    color: $color__text;
    font-family: $font-family;
    font-size: $font-size__mobile;
    font-weight: $font-weight;
    font-style: $font-style;
    letter-spacing: $letter-spacing;
    line-height: $line-height__mobile;
    text-transform: $text-transform;
    text-decoration: $text-decoration;
    text-align: center;
    cursor: pointer;

    @include media-breakpoint-up(sm) {
        font-size: $font-size__tablet;
        line-height: $line-height__tablet;
    }

    @include media-breakpoint-up(xl) {
        font-size: $font-size;
        line-height: $line-height;
    }

    @include hover-all {
        &:focus {
            outline: 2px solid black; 
        }
        outline: 0;
        border-color: $color__border--hover;
        background-color: $color__background--hover;
        color: $color__text--hover;
        text-decoration: $text-decoration--hover;
    }
}

@mixin button-color(
    $color__border,
    $color__background,
    $color__text,
    $color__border--hover: $color__border,
    $color__background--hover: $color__background,
    $color__text--hover: $color__text
) {
    border-color: $color__border;
    background-color: $color__background;
    color: $color__text;

    @include hover-all {
        border-color: $color__border--hover;
        background-color: $color__background--hover;
        color: $color__text--hover;
    }
}

@mixin button-color-text(
    $color__text,
    $color__text--hover: $color__text
) {
    color: $color__text;

    @include hover-all {
        color: $color__text--hover;
    }
}

@mixin button-padding($padding) {
    padding: $padding;
}

@mixin button-border(
    $color__border,
    $color__border--hover: $color__border,
    $border-width: 1px,
    $border-style: solid,
) {
    border-width: $border-width;
    border-style: $border-style;
    border-color: $color__border;

    @include hover-all {
        border-color: $color__border--hover;
    }
}

@mixin button-size(
    $padding,
    $border-width,
    $font-size,
    $font-size__tablet: $font-size,
    $font-size__mobile: $font-size
) {
    padding: $padding;
    border-width: $border-width;
    font-size: $font-size__mobile;

    @include media-breakpoint-up(sm) {
        font-size: $font-size__tablet;
    }

    @include media-breakpoint-up(xl) {
        font-size: $font-size;
    }
}

@mixin button-width($width) {
    width: $width;
}

@mixin button-width-auto {
    width: auto;
}

@mixin button-width-full {
    width: 100%;
}

@mixin button-width-full__mobile-only {
    width: 100%;

    @include media-breakpoint-up(md) {
        width: auto;
    }
}

@mixin button-min-width($min-width) {
    min-width: $min-width;
}

@mixin button-font-size($font-size, $font-size__tablet: $font-size, $font-size__mobile: $font-size) {
    font-size: $font-size__mobile;

    @include media-breakpoint-up(sm) {
        font-size: $font-size__tablet;
    }

    @include media-breakpoint-up(xl) {
        font-size: $font-size;
    }
}

@mixin button-letter-spacing($letter-spacing) {
    letter-spacing: $letter-spacing;
}

@mixin button-line-height($line-height, $line-height__tablet: $line-height, $line-height__mobile: $line-height) {
    line-height: $line-height__mobile;

    @include media-breakpoint-up(sm) {
        line-height: $line-height__tablet;
    }

    @include media-breakpoint-up(xl) {
        line-height: $line-height;
    }
}

@mixin button-text-transform($text-transform) {
    text-transform: $text-transform;
}

@mixin button-remove {
    padding: 0;
    border: 0;
    background-color: transparent;
}

@mixin button-disable {
    pointer-events: none;
}

@mixin button-line(
    $selector: before,
    $height: 1px,
    $color: inherit
) {
    &::#{$selector} {
        content: '';
        position: absolute;
        bottom: calc(-1px - #{$height});
        height: $height;
        width: calc(100% - 2px);
        background-color: $color;
    }
}

@mixin button-line-alt(
    $height: 1px,
    $color: currentColor
) {
    display: inline-block;
    text-decoration: none;
    background-image: linear-gradient($color, $color);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 100% $height;

    @include hover-all {
        background-size: 0% $height;
    }
}
