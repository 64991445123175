$transition-property: all !default;
$transition-duration: 0.3s !default;
$transition-function: ease !default;

@mixin transition(
    $property: $transition-property,
    $duration: $transition-duration,
    $function: $transition-function
) {
    transition: $property $duration $function;
}
