// Order History Button
$margin-top-button-order-history: 20px;

%button-order-history {
    @extend %button;
    @extend %button-underlined;

    &:disabled,
    &.disabled {
        @extend %button-disabled;
    }
}

// Order History Order
$padding-bottom-container-order-history-order: 30px;
$margin-bottom-container-order-history-order: 30px;
$border-bottom-container-order-history-order: 1px solid $color-border-accent;

%text-order-history-order {
    @extend %text;
    @extend %text-small;
}

// Order History Channel
$margin-top-text-order-history-channel: 5px;

%text-order-history-channel {
    @extend %heading-h6;
}

// Order History Sales ID
$margin-bottom-text-order-history-sales-id: 10px;

%text-order-history-sales-id {
    @extend %heading-h4;
}

// Order History Separator
$content-separator-order-history: '/';
$margin-separator-order-history: 0 10px;
$color-separator-order-history: $color-text-muted;

// Order History Groups
$margin-top-container-order-history-groups: 10px;

// Order History Delivery Type
$font-weight-text-order-history-delivery-type: $font-weight-bold;

%text-order-history-delivery-type {
    @include text-font-weight($font-weight-text-order-history-delivery-type);
}

// Order History Delivery Heading
$margin-right-heading-order-history-delivery: 3px;

// Order History Delivery Processing
$color-text-order-history-delivery-processing: $color-text-accent;
