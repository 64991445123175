.order-confirmation {
    @extend %width-shop;

    > .container-with-header-slots {
        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }
    }

    &__message {
        @include media-breakpoint-up(md) {
            @include flex-width($width-container-order-confirmation-message__desktop-only);
            margin-right: $margin-right-container-order-confirmation-message__desktop-only;
        }
    }

    &__confirmation {
        padding: $padding-container-order-confirmation-confirmation__mobile;
        margin: $margin-container-order-confirmation-confirmation__mobile;
        background-color: $color-background-container-order-confirmation-confirmation;

        @include media-breakpoint-up(md) {
            @include flex-width($width-container-order-confirmation-confirmation__desktop-only);
            padding: $padding-container-order-confirmation-confirmation;
            margin: 0;
        }
    }

    &__details {
        @include media-breakpoint-up(md) {
            @include flex-width(100%);
            padding-top: $padding-top-container-order-confirmation-details__desktop-only;
            margin-top: $margin-top-container-order-confirmation-details__desktop-only;
            border-top: $border-top-container-order-confirmation-details__desktop-only;
        }
    }
}
