.mailing-list-redirect-form {
    &-content {
        margin-bottom: $margin-bottom-container-mailing-list-redirect-content__mobile;

        @include media-breakpoint-up(sm) {
            margin-bottom: $margin-bottom-container-mailing-list-redirect-content;
        }
    }

    &-form {
        position: relative;
        margin: auto;

        @include media-breakpoint-up(sm) {
            width: $width-container-mailing-list-redirect-form__tablet;
        }

        @include media-breakpoint-up(lg) {
            width: $width-container-mailing-list-redirect-form;
        }
    }

    &-label {
        display: none;
    }

    &-input {
        @extend %input-mailing-list-redirect;
    }

    &-button {
        @include button-remove;
        @include center-flex;
        @extend %icon-mailing-list-redirect-button;
        position: absolute;
        top: 0;
        right: 0;
        width: $size-mailing-list-redirect-button;
        height: $size-mailing-list-redirect-button;

        &:disabled,
        &.disabled {
            @extend %icon-mailing-list-redirect-button-disabled;
        }
    }

    &-error {
        @extend %alert-mailing-list-redirect;
        @extend %alert-mailing-list-redirect-danger;
    }
}
