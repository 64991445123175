// Mailing List Redirect Content
$margin-bottom-container-mailing-list-redirect-content: 15px;
$margin-bottom-container-mailing-list-redirect-content__mobile: 10px;

// Mailing List Redirect Form
$width-container-mailing-list-redirect-form: 360px;
$width-container-mailing-list-redirect-form__tablet: 290px;

// Mailing List Redirect Input
$padding-right-input-mailing-list-redirect: 50px;

%input-mailing-list-redirect {
    @extend %input;
    padding-right: $padding-right-input-mailing-list-redirect;
}

// Mailing List Redirect Button
$size-mailing-list-redirect-button: 50px;
$icon-mailing-list-redirect-button: $asset-icon-submit;
$width-icon-mailing-list-redirect-button: 14px;
$height-icon-mailing-list-redirect-button: 12px;

%icon-mailing-list-redirect-button {
    @include icon-image($icon-mailing-list-redirect-button, before, $width-icon-mailing-list-redirect-button, $height-icon-mailing-list-redirect-button);

    &-disabled {
        opacity: 0.5;
    }
}

// Mailing List Redirect Alert
$margin-top-alert-mailing-list-redirect: 10px;

%alert-mailing-list-redirect {
    @extend %alert;
    margin-top: $margin-top-alert-mailing-list-redirect;

    &-danger {
        @extend %alert-danger;
    }
}
