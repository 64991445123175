.msc-waiting {
    &-circular {
        display: inline-block;
        position: relative;
        z-index: 1;
        width: map-get($size-shape-waiting-circle, 'md');
        height: map-get($size-shape-waiting-circle, 'md');
        border-radius: 50%;
        border-color: rgba($color-shape-waiting-background, $opacity-shape-waiting-background);
        border-style: solid;
        border-width: $stroke-width-shape-waiting-circle;
        border-top-color: $color-shape-waiting-foreground;
        transform: translate3d(-50%, -50%, 0);
        animation: waiting-spin $transition-time-shape-waiting-circle ease-in-out infinite;
    }

    @each $color, $value in $theme-colors {
        &-circular#{&}-#{$color} {
            border-color: rgba(mix($value, $color-shape-waiting-background, 25%), $opacity-shape-waiting-background);
            border-top-color: $value;
        }
    }

    @each $key, $value in $size-shape-waiting-circle {
        &-circular#{&}-#{$key} {
            width: $value;
            height: $value;
        }
    }

    &-linear {
        position: relative;
        width: 100%;
        border-color: rgba($color-shape-waiting-background, $opacity-shape-waiting-background);
        border-style: solid;
        border-width: 0;
        border-top-width: map-get($size-shape-waiting-linear, 'md') / 2;
        border-bottom-width: map-get($size-shape-waiting-linear, 'md') / 2;

        &:before {
            content: '';
            position: absolute;
            top: calc(-#{map-get($size-shape-waiting-linear, 'md')} / 2);
            border-color: $color-shape-waiting-foreground;
            border-style: solid;
            border-width: map-get($size-shape-waiting-linear, 'md');
            border-top-width: map-get($size-shape-waiting-linear, 'md') / 2;
            border-bottom-width: map-get($size-shape-waiting-linear, 'md') / 2;
            animation: waiting-track $transition-time-shape-waiting-linear linear infinite;
        }
    }

    @each $color, $value in $theme-colors {
        &-linear#{&}-#{$color} {
            border-color: rgba(mix($value, $color-shape-waiting-background, 25%), $opacity-shape-waiting-background);

            &:before {
                border-color: $value;
            }
        }
    }

    @each $key, $value in $size-shape-waiting-linear {
        &-linear#{&}-#{$key} {
            border-top-width: $value / 2;
            border-bottom-width: $value / 2;

            &:before {
                top: -$value / 2;
                border-top-width: $value / 2;
                border-bottom-width: $value / 2;
            }
        }
    }
}
