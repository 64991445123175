// Waiting
$color-shape-waiting-foreground: $color-button-primary__background;
$color-shape-waiting-background: $color-border;
$opacity-shape-waiting-background: 0.5;

// Waiting Circle
$transition-time-shape-waiting-circle: 0.75s;
$stroke-width-shape-waiting-circle: 3px;
$size-shape-waiting-circle: (
    'sm': 25px,
    'md': 50px,
    'lg': 75px
);

// Waiting Linear
$transition-time-shape-waiting-linear: 1.5s;
$size-shape-waiting-linear: (
    'sm': 4px,
    'md': 8px,
    'lg': 12px
);

// Waiting Spin Animation
@keyframes waiting-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// Waiting Track Animation
@keyframes waiting-track {
    0% {
        right: 100%;
        left: 0%;
    }

    20% {
        right: 80%;
        left: 0%;
    }

    80% {
        right: 0%;
        left: 80%;
    }

    100% {
        right: 0%;
        left: 100%;
    }
}
