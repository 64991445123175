// Media Gallery
$padding-container-media-gallery: 80px calc(1/16 * 100%) 100px;
$padding-container-media-gallery__tablet: 60px calc(1/16 * 100%) 75px;
$padding-container-media-gallery__mobile: 45px calc(1/16 * 100%) 60px;

// Media Gallery Carousel Indicators
$bottom-container-media-gallery-carousel-indicators: 50px;
$bottom-container-media-gallery-carousel-indicators__tablet: 30px;
$bottom-container-media-gallery-carousel-indicators__mobile: 20px;

// Media Gallery Carousel Indicator
$opacity-icon-media-gallery-carousel-indicator: 0.15;
$opacity-icon-media-gallery-carousel-indicator--active: 1;
$color-background-icon-media-gallery-carousel-indicator: $color-text;

// Media Gallery Item: Wine
$max-width-image-media-gallery-item-wine: 150px;

// Media Gallery Item: Bottled Wine
$max-height-image-media-gallery-item-wine-bottled: 540px;

// Media Gallery Item: Canned Wine
$max-height-image-media-gallery-item-wine-canned: 295px;
