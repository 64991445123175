// Order Confirmation Message
$width-container-order-confirmation-message__desktop-only: calc(8/16 * 16/14 * 100%);
$margin-right-container-order-confirmation-message__desktop-only: calc(1/16 * 16/14 * 100%);

// Order Confirmation Confirmation
$width-container-order-confirmation-confirmation__desktop-only: calc(5/16 * 16/14 * 100%);
$padding-container-order-confirmation-confirmation: 55px calc(0.75/16 * 100%);
$padding-container-order-confirmation-confirmation__mobile: 25px calc(1/16 * 16/14 * 100%);
$margin-container-order-confirmation-confirmation__mobile: 30px calc(-1/16 * 16/14 * 100%);
$color-background-container-order-confirmation-confirmation: $color-background-accent;

// Order Confirmation Details
$padding-top-container-order-confirmation-details__desktop-only: 50px;
$margin-top-container-order-confirmation-details__desktop-only: 50px;
$border-top-container-order-confirmation-details__desktop-only: 1px solid $color-border-accent;
