.ms-address-list,
.msc-address-list {
    display: flex;
    flex-wrap: wrap;

    &__heading {
        display: none;
    }

    &__add-empty {
        flex: 0 0 100%;
    }

    &__button-add {
        @include center-flex(both);
        @extend %button-address-add;
        order: 1;

        @include media-breakpoint-up(lg) {
            max-width: $max-width-button-address-add__desktop-only;
            margin-left: $margin-left-button-address-add__desktop-only;
            text-align: $text-align-button-address-add__desktop-only;
        }
    }

    &__add-empty + .msc-address-list__button-add {
        @include button-width-full;
        max-width: none;
        margin: 0;
    }

    &__primary:nth-child(3) {
        flex: 0 0 100%;

        @include media-breakpoint-up(lg) {
            @include flex-width($width-container-address-list-primary__desktop-only);
            order: 0;
        }
    }

    &__primary:nth-child(4) {
        flex: 0 0 100%;
        margin-top: $margin-top-container-address-list-other;

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            flex: 0 0 100%;
            order: 2;
        }

        .msc-address-list__primary-heading {
            @include media-breakpoint-up(lg) {
                flex: 0 0 100%;
            }
        }

        .msc-address-list__primary-list {
            margin-bottom: $margin-bottom-container-address-list-other;

            &:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                @include flex-width($width-container-address-list-other__desktop-only);
                margin-right: $margin-right-container-address-list-other__desktop-only;
            }

            &:nth-child(2n-1) {
                @include media-breakpoint-up(lg) {
                    margin-right: 0;
                }
            }

            &:nth-last-child(2):nth-child(2n) {
                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__primary-list {
        display: flex;
        flex-wrap: wrap;

        .msc-address-detail {
            flex: 0 0 100%;
            order: 0;
        }

        .msc-address-list__button-edit {
            order: 1;
        }

        .msc-address-list__button-primary {
            order: 2;
            margin-left: $margin-left-button-address-list;
        }
    }

    &__button-edit,
    &__button-primary {
        @extend %button-address-list;
        margin-top: $margin-top-button-address-list;
    }
}
