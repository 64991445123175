.shipping-map {
    &-svg {
        max-width: 100%;
        height: 100%;
    }

    &-path {
        fill: $color-shape-shipping-map__fill;
        stroke: $color-shape-shipping-map__stroke;
        stroke-width: $stroke-width-shape-shipping-map;
        stroke-linejoin: $stroke-linejoin-shape-shipping-map;
        stroke-linecap: $stroke-linecap-shape-shipping-map;
        stroke-opacity: $stroke-opacity-shape-shipping-map;

        &-active {
            fill: $color-shape-shipping-map__fill--active;
        }
    }

    &-legend {
        margin-top: $margin-top-container-shipping-map-legend;
    }

    &-legend-item {
        display: flex;

        &-text {
            margin-bottom: $margin-bottom-text-shipping-map-legend-item-text;
            line-height: $line-height-text-shipping-map-legend-item-text;
        }

        &-box {
            @include flex-width($size-container-shipping-map-legend-item-box);
            height: $size-container-shipping-map-legend-item-box;
            margin-right: $margin-right-container-shipping-map-legend-item-box;
            border-radius: $border-radius-container-shipping-map-legend-item-box;
            background-color: $color-container-shipping-map-legend-item-box;

            &-active {
                background-color: $color-container-shipping-map-legend-item-box--active;
            }
        }
    }

    &-list {
        margin-top: $margin-top-container-shipping-map-list;

        &-item-qty {
            margin-left: $margin-left-container-shipping-map-item-quantity;
        }
    }
}
