/**
 * @license
 * MyFonts Webfont Build ID 4021880, 2021-03-02T13:49:59-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AvenirLTPro-Book by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-45-book/
 * Copyright: Copyright &amp;#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: AvenirLTPro-Heavy by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-85-heavy/
 * Copyright: Copyright &amp;#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: Joane-SemiBold by W Type Foundry
 * URL: https://www.myfonts.com/fonts/without-foundry/joane/semi-bold/
 * Copyright: Copyright &amp;#xFFC2;&amp;#xFFA9; 2017 by Ale Navarro. All rights reserved.
 * 
 * 
 * 
 * © 2021 MyFonts Inc
*/

@import url("//hello.myfonts.net/count/3d5e78");

$font-family-path: 'fonts';

@font-face {
    font-family: 'Avenir';
    font-weight: 400;
    src: url('#{$font-family-path}/AvenirLTProBook/font.woff2') format('woff2'),
        url('#{$font-family-path}/AvenirLTProBook/font.woff') format('woff');
}

@font-face {
    font-family: 'Avenir';
    font-weight: 700;
    src: url('#{$font-family-path}/AvenirLTProHeavy/font.woff2') format('woff2'),
        url('#{$font-family-path}/AvenirLTProHeavy/font.woff') format('woff');
}

@font-face {
    font-family: 'Joane';
    font-weight: 600;
    src: url('#{$font-family-path}/JoaneSemiBold/font.woff2') format('woff2'),
        url('#{$font-family-path}/JoaneSemiBold/font.woff') format('woff');
}
