//==============================================================================
// CONTENT
//==============================================================================

$width-content-xl: 100%;
$width-content-lg: calc(14/16 * 100%);
$width-content-md: calc(12/16 * 100%);
$width-content-sm: calc(10/16 * 100%);
$width-content-xs: calc(8/16 * 100%);

$margin-container-content: 45px auto;
$margin-container-content__tablet: 35px auto;
$margin-container-content__mobile: 30px auto;

// Content Title
$margin-bottom-heading-content-title: 25px;

// Content Subtitle
$margin-bottom-heading-content-subtitle: 15px;

// Content Image
$margin-bottom-image-content: 25px;

//==============================================================================
// STANDARD
//==============================================================================

$width-container-standard: $width-content-lg;
$width-container-standard__tablet: $width-container-standard;
$width-container-standard__mobile: $width-container-standard;

//==============================================================================
// FEATURED
//==============================================================================

$width-container-featured: $width-content-xs;
$width-container-featured__tablet: $width-content-sm;
$width-container-featured__mobile: $width-content-lg;

//==============================================================================
// LEGAL
//==============================================================================

$width-container-legal: $width-content-xs;
$width-container-legal__tablet: $width-content-sm;
$width-container-legal__mobile: $width-content-lg;

// Legal Heading
$text-align-heading-legal: center;

// Legal Table
$width-table-legal: $width-content-xs;
$padding-table-legal: 4px 8px;
$margin-bottom-table-legal: 15px;
$color-border-legal-table: $color-border-accent;

%text-legal-table {
    @extend %text-appellation;
}

//==============================================================================
// PROMO
//==============================================================================

$width-container-promo: $width-content-lg;
$width-container-promo__tablet: $width-container-promo;
$width-container-promo__mobile: $width-container-promo;
$padding-container-promo: 50px 0;
$padding-container-promo__mobile: 20px 0;
$color-background-container-promo: $color-background-accent;

// Promo Content
$width-container-promo-content: calc(8/16 * 16/14 * 100%);
$width-container-promo-content__tablet: calc(10/16 * 16/14 * 100%);
$width-container-promo-content__mobile: calc(12/16 * 16/14 * 100%);

//==============================================================================
// DOUBLE
//==============================================================================

$margin-container-double: 70px 0 50px;
$margin-container-double__desktop: 45px 0 30px;
$margin-container-double__tablet: 25px 0;
$text-align-container-double-content: left;
$text-align-container-double-content__mobile: center;

// Double Header
$margin-bottom-container-double-header: 20px;
$margin-bottom-container-double-header__tablet: 10px;
$margin-bottom-container-double-header__mobile: 5px;

// Double Content
$width-container-double-content: calc(6.5/16 * 100%);
$width-container-double-content__mobile: 100%;
$padding-container-double-content__mobile: 20px calc(1/16 * 100%);
$margin-container-double-content-left: 0 calc(0.5/16 * 100%) 0 calc(1/16 * 100%);
$margin-container-double-content-right: 0 calc(1/16 * 100%) 0 calc(0.5/16 * 100%);
$margin-container-double-content__mobile: 0 auto;
$color-background-double-content-right: transparent;
$color-background-double-content-right__mobile: $color-background-page;
$text-align-container-double-content: left;
$text-align-container-double-content__mobile: center;

// Double Image
$margin-bottom-image-double: 15px;
$margin-bottom-image-double__tablet: 10px;
$margin-bottom-image-double__mobile: 5px;

// Double Text
$margin-right-text-double__desktop-only: 5%;

%text-double {
    @extend %text;
    @extend %text-small;
}

// Double Button
$margin-top-button-double: 0;
$margin-top-button-double__tablet: 5px;
$margin-top-button-double__mobile: 10px;
$text-align-button-double__desktop-only: right;

//==============================================================================
// TRIPLE
//==============================================================================

$width-container-triple: $width-content-lg;
$width-container-triple__tablet: $width-container-triple;
$width-container-triple__mobile: $width-container-triple;
$margin-container-triple: 45px auto;
$margin-container-triple__tablet: 35px auto;
$margin-container-triple__mobile: 25px auto;
$text-align-container-triple-content: left;
$text-align-container-triple-content__mobile: center;

// Triple Header
$margin-bottom-container-triple-header: 20px;
$margin-bottom-container-triple-header__tablet: 15px;
$margin-bottom-container-triple-header__mobile: 10px;

// Triple Content
$width-container-triple-content__desktop-only: calc(4/16 * 16/14 * 100%);
$margin-right-container-triple-content__desktop-only: calc(1/16 * 16/14 * 100%);
$margin-bottom-container-triple-content__mobile: 30px;
$margin-top-container-triple-content-offset__desktop-only: calc(0.75/16 * 16/14 * 100%);
$margin-bottom-container-triple-content-offset__desktop-only: $margin-top-container-triple-content-offset__desktop-only;

// Triple Content Image
$margin-bottom-image-triple-content__desktop-only: 10px;

// Triple Content Title
$margin-bottom-heading-triple-content: 5px;

//==============================================================================
// ALTERNATING
//==============================================================================

$margin-container-alt: 60px 0;
$margin-container-alt__tablet: 25px 0;
$margin-container-alt__mobile: 20px 0;

// Alternating Header
$text-align-container-alt-header: center;

// Alternating Content
$margin-container-alt-content: 60px 0;
$margin-container-alt-content__desktop: 50px 0;
$margin-container-alt-content__tablet: 30px 0;
$margin-container-alt-content__mobile: 20px 0;

//==============================================================================
// DETAIL
//==============================================================================

// Detail Image
$width-container-detail-image: calc(9/16 * 100%);

// Detail Content
$width-container-detail-content: calc(5/16 * 100%);
$width-container-detail-content__desktop: calc(6/16 * 100%);
$width-container-detail-content__tablet: calc(12/16 * 100%);
$width-container-detail-content__mobile: calc(14/16 * 100%);
$margin-container-detail-content: 50px calc(1/16 * 100%);
$margin-container-detail-content__desktop: 35px calc(0.5/16 * 100%);
$margin-container-detail-content__tablet: 40px auto;
$margin-container-detail-content__mobile: 20px auto;

//==============================================================================
// ZIGZAG
//==============================================================================

// Zigzag Image
$width-container-zigzag-image: calc(9/16 * 100%);
$padding-container-zigzag-image__mobile: 0 calc(1/16 * 100%);

// Zigzag Content
$width-container-zigzag-content: calc(5/16 * 100%);
$width-container-zigzag-content__tablet: calc(6/16 * 100%);
$width-container-zigzag-content__mobile: calc(14/16 * 100%);
$margin-container-zigzag-content: 50px calc(1/16 * 100%);
$margin-container-zigzag-content__tablet: 35px calc(0.5/16 * 100%);
$margin-container-zigzag-content__mobile: 15px auto 35px;
$text-align-container-zigzag-content: left;
$text-align-container-zigzag-content__mobile: center;

//==============================================================================
// TRENDING
//==============================================================================

$margin-top-container-trending__mobile: 100px;

// Trending Image
$width-container-trending-image: calc(6.5/16 * 100%);
$width-container-trending-image__mobile: calc(10/16 * 100%);
$padding-container-trending-image: 100px calc(2/16 * 100%);
$padding-container-trending-image__tablet: 80px calc(2/16 * 100%);
$padding-container-trending-image__mobile: 70px calc(3/16 * 100%);
$margin-x-container-trending-image: calc(1/16 * 100%);
$margin-container-altleft-trending-image: 0 0 0 $margin-x-container-trending-image;
$margin-container-altright-trending-image: 0 $margin-x-container-trending-image 0 0;
$margin-container-trending-image__mobile: 0 auto;

// Trending Content
$width-container-trending-content: calc(5/16 * 100%);
$width-container-trending-content__tablet: calc(6.5/16 * 100%);
$width-container-trending-content__mobile: calc(14/16 * 100%);
$margin-container-trending-content: 100px calc(1.75/16 * 100%);
$margin-container-trending-content__tablet: 50px calc(1/16 * 100%);
$margin-container-trending-content__mobile: 15px auto;
$text-align-container-trending-content: left;
$text-align-container-trending-content__mobile: center;

//==============================================================================
// HERO
//==============================================================================

// Hero Image Short
$height-image-hero-short: 27vw;
$height-image-hero-short__desktop: 35vw;
$height-image-hero-short__tablet: 35vw;
$height-image-hero-short__mobile: 40vw;
$min-height-image-hero-short: 190px;
$max-height-image-hero-short: 500px;

// Hero Image Tall
$height-image-hero-tall: 80vh;
$height-image-hero-tall__desktop: 40vw;
$height-image-hero-tall__tablet: 45vw;
$height-image-hero-tall__mobile: 60vw;
$min-height-image-hero-tall: 550px;
$min-height-image-hero-tall__mobile: 350px;
$max-height-image-hero-tall: 950px;
$max-height-image-hero-tall__mobile: 600px;

// Hero Image Landscape
$height-image-hero-landscape: 40vw;
$height-image-hero-landscape__mobile: 45vw;
$top-container-hero-landscape-carousel-indicators__mobile: 39vw;

// Hero Gradient
$background-hero-gradient-top: linear-gradient(
    0deg,
    transparentize($color-black, 1) 60%,
    transparentize($color-black, 0.6) 70%,
    transparentize($color-black, 0.1) 100%
);

$background-hero-gradient-bottom: linear-gradient(
    180deg,
    transparentize($color-black, 1) 50%,
    transparentize($color-black, 0.5) 70%,
    transparentize($color-black, 0.1) 100%
);

$background-hero-gradient-left: linear-gradient(
    270deg,
    transparentize($color-black, 1) 50%,
    transparentize($color-black, 0.5) 70%,
    transparentize($color-black, 0.1) 100%
);

$background-hero-gradient-right: linear-gradient(
    90deg,
    transparentize($color-black, 1) 50%,
    transparentize($color-black, 0.5) 70%,
    transparentize($color-black, 0.1) 100%
);

// Hero Content
$width-container-hero-content: calc(6/16 * 100vw);
$width-container-hero-content__desktop: calc(10/16 * 100%);
$width-container-hero-content__tablet: calc(12/16 * 100%);
$width-container-hero-content__mobile: calc(14/16 * 100%);
$padding-x-container-hero-content: calc(1/16 * 100%);
$padding-y-container-hero-content: 75px;
$padding-y-container-hero-content__tablet: 50px;
$padding-top-container-hero-content__tablet: 50px;
$padding-top-container-hero-content__mobile: 25px;
$padding-container-hero-content__mobile: 20px calc(1/16 * 100%);
$color-text-hero-content: $color-text-inverse;
$color-text-hero-content-inverse: $color-text;
$color-background-hero-content: $color-background-inverse;
$color-background-hero-content-inverse: $color-background-accent;
$text-shadow-text-hero-content: 0 0 5px $color-text;

// Hero Button
%button-hero {
    @extend %button;
    @extend %button-inverse;

    &-inverse {
        @extend %button;
    }
}

%button-hero-primary {
    @extend %button-primary;

    &-inverse {
        @extend %button-primary;
    }
}

%button-hero-secondary {
    @extend %button-secondary;
    @extend %button-secondary-inverse;

    &-inverse {
        @extend %button-secondary;
    }
}

// Hero Carousel Indicators
$padding-x-container-hero-carousel-indicators: calc(#{$padding-x-container-hero-content} - 10px);

//==============================================================================
// HALF HERO
//==============================================================================

$padding-top-container-halfhero-content__tablet: 30px;
$padding-top-container-halfhero-content__mobile: 15px;
$margin-bottom-container-halfhero__mobile: 55px;
$margin-bottom-container-halfhero__tablet: 95px;

// Half Hero Child
$width-container-halfhero-child: 50%;

// Half Hero Image
$padding-image-halfhero__tablet: 25px calc(1/16 * 100%) 0;
$padding-image-halfhero__mobile: 20px calc(1/16 * 100%) 0;
$margin-bottom-image-halfhero__tablet: -70px;
$margin-bottom-image-halfhero__mobile: -35px;
