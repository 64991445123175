.msc-address-form {
    &__item {
        margin-bottom: $margin-bottom-container-address-form-item;
    }

    &__label {
        @extend %label-address-form;
    }

    &__input {
        @extend %input-address-form;
    }

    &__dropdown {
        @extend %select-address-form;
    }

    &__alert {
        @extend %alert-address-form;
        @extend %alert-address-form-danger;
    }

    &__warning {
        @extend %alert-address-form;
        @extend %alert-address-form-warning;
    }

    &__item-county {
        display: none;
    }

    &__button-save,
    &__button-cancel {
        margin-top: $margin-top-button-address-form;
    }

    &__button-save {
        @extend %button-address-form-save;

        &:not(:last-child) {
            margin-right: $margin-right-button-address-form;
        }
    }

    &__button-cancel {
        @extend %button-address-form-cancel;
    }
}
