.ms-order-confirmation {
    &__order-information-channel-reference-id,
    &__order-information-receipt-email {
        display: block;
    }

    &__order-information-channel-reference-id {
        @extend %text-order-confirmation-id;
        margin-bottom: $margin-bottom-text-order-confirmation-id;
    }

    &__btn-keep-shopping {
        @extend %button-order-confirmation;
        margin-top: $margin-top-button-order-confirmation;
    }

    &__groups,
    &__order-summary,
    &__payment-methods {
        display: none;
    }
}
