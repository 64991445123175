.clickable-container,
.content-block-clickable,
.ms-content-block__clickable {
    @include link-text-decoration(none);
    display: block;

    &[href] {
        cursor: pointer;
    }

    .clickable-image-zoom,
    .ms-content-block[data-m-layout='detail-tile'],
    .ms-content-block[data-m-layout='zigzag-tile'],
    .ms-content-block[data-m-layout='zigzag-tile-alt'],
    .ms-content-block[data-m-layout='trending-tile'],
    .ms-content-block[data-m-layout='double-tile'],
    .ms-content-block[data-m-layout='triple-tile'] {
        .ms-content-block__image {
            overflow: hidden;
            &:focus {
                outline: 3px solid #0055B3 !important;
            }
        }

        img {
            @include transition;
            @include image-cover;
        }

        @include hover-all {
            img {
                transform: scale(1.1);
            }
        }
    }

    .clickable-no-image-zoom {
        @include hover-all {
            img {
                transform: none !important;
            }
        }
    }
}
