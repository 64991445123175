footer {
    @extend %width-page-without-padding;
    @extend %text-footer;
    background-color: $color-background-container-footer;
    text-align: $text-align-container-footer;

    // Footer Container
    > div {
        @include width-container(
            $width-container-footer,
            $width-container-footer__tablet,
            $width-container-footer__mobile
        );
        padding: $padding-container-footer__mobile;

        @include media-breakpoint-up(sm) {
            padding: $padding-container-footer__tablet;
        }

        @include media-breakpoint-up(lg) {
            padding: $padding-container-footer;
        }
    }

    // Footer Link
    a {
        @extend %link-footer;
    }

    li {
        margin-bottom: 0;
    }

    // Footer Link Sections
    ul.footer-social,
    ul.footer-nav,
    ul.footer-legal,
    ul.footer-logos {
        @include center-flex(x);
        flex-wrap: wrap;
    }

    // Footer Social Section
    ul.footer-social {
        margin: $margin-container-footer-social__mobile;

        @include media-breakpoint-up(lg) {
            margin: $margin-container-footer-social;
        }

        > li:not(:last-child) {
            margin-right: $margin-right-icon-footer-social;
        }
    }

    .footer-social {
        &__instagram > a {
            @extend %icon-footer-social-instagram;
        }

        &__facebook > a {
            @extend %icon-footer-social-facebook;
        }

        &__twitter > a {
            @extend %icon-footer-social-twitter;
        }

        &__youtube > a {
            @extend %icon-footer-social-youtube;
        }
    }

    // Footer Nav Section
    ul.footer-nav {
        margin: $margin-container-footer-nav;

        > li:not(:last-child) {
            margin-right: $margin-right-link-footer-nav;
        }
    }

    // Footer Legal Section
    ul.footer-legal {
        @extend %text-footer-legal;
        margin: $margin-container-footer-legal__mobile;

        @include media-breakpoint-up(xl) {
            margin: $margin-container-footer-legal;
        }

        > li:not(:last-child) {
            margin-right: $margin-right-link-footer-legal;
        }
    }

    // Footer Copyright Section
    .footer-copyright {
        @extend %text-footer-copyright;
        margin: $margin-container-footer-copyright__mobile;

        @include media-breakpoint-up(xl) {
            margin: $margin-container-footer-copyright;
        }
    }

    // Footer Logos Section
    ul.footer-logos {
        margin: $margin-container-footer-logos;

        > li:not(:last-child) {
            margin-right: $margin-right-icon-footer-logos;
        }
    }

    .footer-logos {
        &__enjoy {
            @extend %icon-footer-logos-enjoy;
        }

        &__ea > a {
            @extend %icon-footer-logos-ea;
        }
    }
}

#subfooter + footer > div {
    padding: $padding-container-footer-with-subfooter__mobile;

    @include media-breakpoint-up(sm) {
        padding: $padding-container-footer-with-subfooter__tablet;
    }

    @include media-breakpoint-up(lg) {
        padding: $padding-container-footer-with-subfooter;
    }
}
