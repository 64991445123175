.ms-product-collection {
    .msc-ss-carousel {
        overflow: hidden;
        touch-action: pan-y pinch-zoom;
        position: relative;
        &:hover {
            .msc-ss-carousel__flipper {
                opacity: 1;
            }
        }
        &__flipper {
            display: none;
            @media (pointer: fine) {
                display: block;
                opacity: 0;
                position: absolute;
                top: 35%;
                color: white;
                background-color: rgba(0, 0, 0, 0.2);
                border: none;
                transform: scale(0.8);
                width: 3rem;
                height: 3rem;
                border-radius: 100%;
                transition: transform 0.5s ease-in, opacity 0.7s cubic-bezier(0.15, 0, 0.2, 1) 0.1s;
                z-index: 1;
                @include icon-image($asset-icon-carousel-prev, after, 2rem);

                &--next {
                    right: 0px;
                    @include icon-image($asset-icon-carousel-next, after, 2rem);
                    &::after {
                        margin-left: 5px;
                    }
                }
                &:hover {
                    background-color: rgba(0, 0, 0, 0.3);
                }
                &.disabled {
                    display: none;
                }
            }
        }

        &-strip {
            @include media-breakpoint-down(lg) {
                position: relative;
                left: calc(3.5 / 16 * 16 / 14 * 100%);
                margin: 0 calc(-0.5 / 16 * 16 / 14 * 100%);
            }

            @include media-breakpoint-down(xxs) {
                left: calc(2.75 / 16 * 16 / 14 * 100%);
            }
        }

        &-slide {
            margin-bottom: 20px;

            @include media-breakpoint-up(xs) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            > * {
                @include flex-width(calc(9 / 16 * 16 / 14 * 100%));

                @include media-breakpoint-up(xs) {
                    @include flex-width(calc(7 / 16 * 16 / 14 * 100%));
                }

                @include media-breakpoint-up(xl) {
                    @include flex-width(calc(4 / 16 * 16 / 14 * 100%));

                    &:nth-child(3n-1) {
                        margin-top: calc(0.75 / 16 * 16 / 14 * 100%);
                    }
                }

                @include media-breakpoint-down(lg) {
                    background-color: transparent;
                    padding: 0 calc(0.5 / 16 * 16 / 14 * 100%);
                }

                > .msc-product__details {
                    @include media-breakpoint-down(lg) {
                        @include plain-hover-all {
                            background-color: $color-background-accent;
                        }
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                position: relative;
                left: 0;
                padding-bottom: 50px;
                padding-inline-start: 0;
                transition: left cubic-bezier(0.16, 1, 0.29, 0.99) 0.667s;

                &::after {
                    content: "";
                    background-color: transparent;
                    padding: 0 calc(0.5 / 16 * 16 / 14 * 100%);
                    @include flex-width(calc(7 / 16 * 16 / 14 * 100%));
                }

                .product-component-wrapper {
                    display: flex;
                    position: absolute;
                    top: 100%;
                    left: calc(0.5 / 16 * 16 / 7 * 100%);
                    right: calc(0.5 / 16 * 16 / 7 * 100%);
                }

                > * {
                    @include transition;
                    margin-top: 20px;

                    @include media-breakpoint-up(sm) {
                        margin-top: 35px;
                    }

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        bottom: 100%;
                        left: calc(0.5 / 16 * 16 / 7 * 100%);
                        right: calc(0.5 / 16 * 16 / 7 * 100%);
                        height: 0;
                        opacity: 0;
                        background-color: $color-background-accent;
                        transition: 0.1s ease height;
                    }
                }

                > *.active {
                    &::before {
                        opacity: 1;
                        height: 20px;

                        @include media-breakpoint-up(sm) {
                            height: 35px;
                        }
                    }

                    .product-component-wrapper {
                        @include animation-fadeInDownDelay;
                        display: flex;
                    }

                    .msc-product__image {
                        transform: translateY(-10px);
                    }

                    .product-details {
                        @include animation-fadeIn;
                    }
                }

                > *:not(.active) {
                    .product-details {
                        opacity: 0;
                    }
                }
            }

            @include media-breakpoint-down(xxs) {
                > * {
                    &::before,
                    .product-component-wrapper {
                        left: calc(0.5 / 16 * 16 / 9 * 100%);
                        right: calc(0.5 / 16 * 16 / 9 * 100%);
                    }
                }

                &::after {
                    @include flex-width(calc(5 / 16 * 16 / 14 * 100%));
                }
            }
        }
    }
}
