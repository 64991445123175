@mixin visually-hidden {
    position: absolute !important;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
}

.sr-only {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0px, 0px, 0px, 0px);
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
}
