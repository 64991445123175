// Accordion
$margin-bottom-container-accordion: 35px;

// Accordion Text
%text-accordion {
    @extend %text;
    @extend %text-small;
}

// Accordion Heading
$margin-bottom-heading-accordion: 25px;

// Accordion Toggle Container
%text-accordion-toggle {
    @extend %text;
    @extend %text-small;
    @extend %text-muted;
}

// Accordion Toggle Link
$padding-link-accordion-toggle: 0 5px;

%link-accordion-toggle {
    @include button-remove;
    @extend %link;
    padding: $padding-link-accordion-toggle;
}

// Accordion Item
$padding-container-accordion-item: 15px 0;

// Accordion Button
%button-accordion {
    @include button-remove;
    @extend %heading-h4;
}

// Accordion Content
$padding-container-accordion-content: 25px 0 15px;

// Accordion Divider
$border-width-divider-accordion-edge: 1px;
$border-width-divider-accordion: $border-width-divider-accordion-edge 0 0;
$color-border-accordion-divider: $color-border-accent;

%divider-accordion {
    @include border($color-border-accordion-divider, $border-width-divider-accordion);
}

// Accordion Icon
$margin-left-icon-accordion: 5px;
$size-icon-accordion-expand: 11px;
$width-icon-accordion-collapse: 11px;
$height-icon-accordion-collapse: 1px;
$icon-accordion-expand: $asset-icon-accordion-expand;
$icon-accordion-collapse: $asset-icon-accordion-collapse;

%icon-accordion {
    &-expand {
        @include icon-image($icon-accordion-expand, after, $size-icon-accordion-expand);
    }

    &-collapse {
        @include icon-image($icon-accordion-collapse, after, $width-icon-accordion-collapse, $height-icon-accordion-collapse);
    }
}
