.sticky-header {
    .ms-header:not(.no-sticky) {
        @include animation-fadeInDown;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    .ms-header:not(.no-shadow):not(.no-shadow-promo) {
        box-shadow: 0 0 10px transparentize($color-black, 0.9);
    }

    main {
        padding-top: $height-container-header__mobile;

        @include media-breakpoint-up(sm) {
            padding-top: $height-container-header__tablet;
        }

        @include media-breakpoint-up(xl) {
            padding-top: $height-container-header;
        }
    }

    .ms-header.no-sticky {
        margin-bottom: -$height-container-header__mobile;

        @include media-breakpoint-up(sm) {
            margin-bottom: -$height-container-header__tablet;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: -$height-container-header;
        }
    }
}

.sticky-header.home .ms-header.no-sticky {
    @include media-breakpoint-up(xl) {
        margin-bottom: -$height-container-header * 2;
    }
}

.sticky-anchor,
.home-anchor {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
}
