.custom-h1 {
    .ms-content-block__title,
    .container-with-header-title {
        @extend %heading-h1;
    }
}

.custom-h2 {
    .ms-content-block__title,
    .container-with-header-title {
        @extend %heading-h2;
    }
}

.custom-h3 {
    .ms-content-block__title,
    .container-with-header-title {
        @extend %heading-h3;
    }
}

.custom-h4 {
    .ms-content-block__title,
    .container-with-header-title {
        @extend %heading-h4;
    }
}

.custom-h5 {
    .ms-content-block__title,
    .container-with-header-title {
        @extend %heading-h5;
    }
}

.custom-h6 {
    .ms-content-block__title,
    .container-with-header-title {
        @extend %heading-h6;
    }
}