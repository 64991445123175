.ms-product-search-result__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 280px;
    background-color: $color-background-accent;

    @include media-breakpoint-up(sm) {
        height: 410px;
    }

    @include media-breakpoint-up(lg) {
        height: 480px;
    }

    @include media-breakpoint-up(xl) {
        height: 510px;
    }

    @include media-breakpoint-up(xxl) {
        height: 550px;
    }

    .msc-product__details {
        @include center-flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        padding: 10px calc(0.5/16 * 16/6.5 * 100%);

        @include media-breakpoint-up(xl) {
            padding: 10px calc(0.25/16 * 16/4 * 100%);
        }

        /* START - Show Add to Cart bar on hover, PART 1/3 */
        // padding: 10px calc(0.5/16 * 16/6.5 * 100%) 50px;

        // @include media-breakpoint-up(xl) {
        //     padding: 10px calc(0.5/16 * 16/4 * 100%) 50px;
        // }
        /* END - Show Add to Cart bar on hover, PART 1/3 */

        @include plain-hover-all {
            @include link-text-decoration(none);
        }
    }

    .msc-product__image {
        @include transition;
    }

    .msc-product__image img,
    .msc_image {
        max-height: 160px;
        margin: auto;

        @include media-breakpoint-up(sm) {
            max-height: 270px;
        }

        @include media-breakpoint-up(lg) {
            max-height: 310px;
        }

        @include media-breakpoint-up(xl) {
            max-height: 340px;
        }

        @include media-breakpoint-up(xxl) {
            max-height: 380px;
        }
    }

    .product-type-bottled-wine,
    .product-type-canned-wine {
        .msc-product__image {
            text-align: center;
        }

        .msc-product__image img,
        .msc_image {
            position: relative;
            max-width: 50px;

            @include media-breakpoint-up(sm) {
                max-width: 75px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 90px;
            }

            @include media-breakpoint-up(xl) {
                max-width: 110px;
            }
        }

        picture {
            display: inline-block;
            position: relative;
            margin-bottom: -10px;

            &::before {
                @include center-absolute(x);
                content: '';
                display: block;
                height: 80px;
                background-image: $asset-background-shadow;
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center bottom;
            }
        }
    }

    .product-type-bottled-wine picture::before {
        width: 250%;
        bottom: -6px;

        @include media-breakpoint-up(sm) {
            bottom: -10px;
        }

        @include media-breakpoint-up(lg) {
            bottom: -11px;
        }

        @include media-breakpoint-up(xl) {
            bottom: -12px;
        }
    }

    .product-type-canned-wine picture::before {
        width: 210%;
        bottom: -7px;

        @include media-breakpoint-up(sm) {
            bottom: -11px;
        }

        @include media-breakpoint-up(lg) {
            bottom: -12px;
        }

        @include media-breakpoint-up(xl) {
            bottom: -13px;
        }
    }

    .msc-empty_image {
        @include image-placeholder(50px);

        @include media-breakpoint-up(sm) {
            @include image-placeholder(75px);
        }

        @include media-breakpoint-up(lg) {
            @include image-placeholder(90px);
        }

        @include media-breakpoint-up(xl) {
            @include image-placeholder(110px);
        }
    }

    .product-details {
        margin-top: 10px;
        text-align: center;

        @include media-breakpoint-up(sm) {
            margin-top: 15px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 20px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 25px;
        }
    }

    .product-placement__item-title {
        @extend %heading-h5;
        @include truncation-line(2);
    }

    .product-component-wrapper {
        display: flex;
        justify-content: space-between;
        height: 50px;
        background-color: $color-background;

        /* START - Show Add to Cart bar on hover, PART 2/3 */
        // @include media-breakpoint-up(xl) {
        //     display: none;
        //     position: absolute;
        //     z-index: 1;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        // }
        /* END - Show Add to Cart bar on hover, PART 2/3 */

        &-price {
            @extend %text;
            @extend %text-small;
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 8px 10px;
            border: 1px solid $color-background-accent;
            border-top: 0;
            line-height: 18px;

            @include media-breakpoint-up(sm) {
                flex-direction: row;
                padding: 19px 15px;
            }

            @include media-breakpoint-up(lg) {
                padding: 19px 25px;
            }

            @include media-breakpoint-up(xl) {
                padding: 19px 15px;
            }

            @include media-breakpoint-up(xxl) {
                padding: 19px 22px;
            }

            &-default {
                @include text-font-weight($font-weight-bold);

                &:only-child {
                    @include media-breakpoint-down(xs) {
                        flex-direction: row;
                        padding: 9px 0;
                    }
                }
            }

            &-club {
                @include media-breakpoint-up(sm) {
                    margin-left: 12px;
                }

                @include media-breakpoint-up(lg) {
                    margin-left: 20px;
                }

                @include media-breakpoint-up(xl) {
                    margin-left: 12px;
                }

                @include media-breakpoint-up(xxl) {
                    margin-left: 20px;
                }
            }
        }

        &-quantity-group {
            z-index: 1;

            @include media-breakpoint-down(xs) {
                display: flex;
            }
        }

        &-quantity,
        .product-component__quantity__select-menu,
        &-add-to-cart-button,
        &-add-to-cart-out-of-stock {
            height: 100%;
        }

        &-quantity,
        .product-component__quantity__select-menu {
            @extend %input;
            width: 50px;
            padding-left: 3px;
            padding-right: 3px;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-color: $color-background-accent;
            text-align: center;
            text-align-last: center;
        }

        .product-component__quantity__select-menu {
            padding-right: 0;
        }

        .product-component__quantity__select-menu {
            &:focus {
                box-shadow: none !important;
                outline: 3px solid #0055B3 !important;
            }
        }

        &-add-to-cart {
            $width-button-product-search-result-cart: 50px;
            $padding-x-button-product-search-result-cart: 10px;
            $icon-product-search-result-cart: $asset-icon-cart;
            $width-icon-product-search-result-cart: 18px;
            $height-icon-product-search-result-cart: 16px;
            $margin-bottom-icon-product-search-result-cart: 2px;

            @mixin icon-product-search-result-cart {
                @include icon-image($icon-product-search-result-cart, before, $width-icon-product-search-result-cart, $height-icon-product-search-result-cart);

                &::before {
                    @include image-invert;
                    margin-bottom: $margin-bottom-icon-product-search-result-cart;
                }
            }

            &-button {
                @extend %button-primary;
                @include center-flex;
                @include icon-product-search-result-cart;
                width: $width-button-product-search-result-cart;
                padding-left: $padding-x-button-product-search-result-cart;
                padding-right: $padding-x-button-product-search-result-cart;
                font-size: 0;
            }

            &-out-of-stock {
                @extend %button-primary;
                @extend %button-primary-disabled;
                max-width: 50px;
                padding: 5px 3px 2px;
                font-size: 9px;
                line-height: 12px;

                @include media-breakpoint-up(xs) {
                    max-width: 80px;
                    padding: 11px 10px 8px;
                    font-size: 10px;
                    line-height: 15px;
                }

                @include media-breakpoint-up(sm) {
                    max-width: 90px;
                    font-size: 11px;
                }
            }

            &-message {
                position: absolute !important;
                z-index: 1;
                right: 0 !important;
                margin-top: 2px !important;

                @include media-breakpoint-up(sm) {
                    margin-top: 5px !important;
                }

                @include media-breakpoint-up(md) {
                    margin-top: 10px !important;
                }

                &.success {
                    @extend %text;
                    @extend %text-small;
                }

                &.error {
                    @extend %alert;
                    @extend %alert-danger;

                    @include media-breakpoint-down(xs) {
                        padding: 3px 10px 0 !important;
                    }
                }

                // Remove when Mixed Cart Bandaid is gone
                &.mixedcart {
                    @extend %alert;
                    @extend %alert-danger;
                    @extend %text-appellation;

                    @include media-breakpoint-down(xs) {
                        padding: 3px 5px 0 !important;
                    }
                }
            }
        }

        &-giftcard-link {
            @extend %heading-h6;
            @extend %heading-h6-inverse;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: 5px 10px 2px;
            background-color: $color-button-primary__background;
            color: $color-button-primary__text;
            text-align: center;

            @include hover-all {
                background-color: $color-button-primary__background--hover;
                color: $color-button-primary__text--hover;
            }

            @include plain-hover-all {
                text-decoration: none;
            }
        }
    }

    /* START - Show Add to Cart bar on hover, PART 3/3 */
    // @include media-breakpoint-up(xl) {
    //     @include hover-all {
    //         .product-component-wrapper {
    //             @include animation-fadeInUp;
    //             display: flex;
    //         }

    //         .msc-product__image {
    //             transform: translateY(-10px);
    //         }
    //     }
    // }
    /* END - Show Add to Cart bar on hover, PART 3/3 */

    .fa-chevron-right,
    .product-attribute,
    .acclaim-list,
    .product-lightbox,
    .product-component-wrapper-decrease-button,
    .product-component-wrapper-increase-button {
        display: none;
    }
}
