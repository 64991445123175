// Promo Code
$padding-container-promo-code: 35px 50px;
$padding-container-promo-code__desktop: 20px 25px;
$padding-container-promo-code__tablet: 35px 50px;
$padding-container-promo-code__mobile: 20px 25px;
$margin-top-container-promo-code: 50px;
$margin-top-container-promo-code__mobile: 25px;
$color-background-container-promo-code: $color-background-accent;

// Promo Code Heading
$margin-bottom-heading-promo-code: 10px;
$text-transform-heading-promo-code: capitalize;

%heading-promo-code {
    @extend %heading-h3;
    @include heading-text-transform($text-transform-heading-promo-code);
}

// Promo Code Input
%input-promo-code {
    @extend %input;
}

// Promo Code Button
$margin-top-button-promo-code: 10px;

%button-promo-code {
    @extend %button-primary;

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}

// Promo Code Error
$margin-top-alert-promo-code-error: 10px;

%alert-promo-code-error {
    @extend %alert;
    @extend %alert-danger;
}

// Promo Code Applied
$margin-top-container-promo-code-applied: 55px;
$margin-top-container-promo-code-applied__tablet: 35px;
$margin-top-container-promo-code-applied__mobile: 25px;

// Promo Code Applied Heading
$margin-bottom-heading-promo-code-applied: 5px;
$text-transform-heading-promo-code-applied: capitalize;

%heading-promo-code-applied {
    @extend %heading-h4;
    @include heading-text-transform($text-transform-heading-promo-code-applied);
}

// Promo Code Applied Subtext
$margin-bottom-text-promo-code-applied-sub: 20px;

%text-promo-code-applied-sub {
    @extend %text-appellation;
    @extend %text-appellation-accent;
}

// Promo Code Applied Line
%text-promo-code-applied-line {
    @extend %text;
    @extend %text-small;
}

// Promo Code Applied Line Price
$margin-left-text-promo-code-applied-line-price: 5px;

%text-promo-code-applied-line-price {
    @extend %text-appellation;
    @extend %text-appellation-accent;
}

// Promo Code Applied Remove Button
%button-promo-code-applied-remove {
    @extend %link;
}
