.landing {
    @extend %text-landing;
    background-color: $color-background-page-landing;

    header,
    main,
    footer {
        @extend %width-page;
        max-width: $max-width-page-landing;
    }

    header,
    .ms-header__container,
    footer,
    &-footer {
        width: 100%;
        padding: $padding-page-landing;
    }

    main {
        padding: $padding-container-landing;
    }

    &-logo.ms-header {
        @include center-flex(x);
        margin-top: $margin-top-image-landing-logo;

        .ms-header__logo {
            max-width: $max-width-image-landing-logo__mobile;

            @include media-breakpoint-up(md) {
                max-width: $max-width-image-landing-logo;
            }
        }
    }

    &-overview {
        text-align: $text-align-container-landing-overview;
        padding: $padding-container-landing-overview__mobile;

        @include media-breakpoint-up(md) {
            padding: $padding-container-landing-overview;
        }
    }

    &-overview,
    &-featured,
    &-wine-finder,
    &-content-grid {
        margin: $margin-container-landing-item;
    }

    &-featured,
    &-wine-finder,
    &-promo {
        background-color: $color-background-container-landing-item;

        img {
            @extend %image-landing;
        }

        .ms-content-block__image {
            margin-bottom: 0;
        }

        .ms-content-block__details,
        .content-with-media-text {
            padding: $padding-container-landing-item-content__mobile;

            @include media-breakpoint-up(md) {
                padding: $padding-container-landing-item-content;
            }
        }

        .ms-content-block__cta {
            margin-top: $margin-top-button-landing;

            > button,
            > a[role='button'] {
                @extend %button-landing;
                background-image: none;
            }
        }
    }

    &-featured,
    &-wine-finder {
        @include media-breakpoint-up(md) {
            display: flex;

            > * {
                @include flex-width($width-container-landing-item-content-featured);
            }
        }
    }

    &-content-grid > .row {
        > *:not(:last-child) {
            margin-bottom: $margin-container-landing-item-promo;
        }

        > *:empty {
            display: none;
        }

        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;

            > * {
                @include flex-width($width-container-landing-item-promo);

                &:nth-child(2n-1):not(:last-child) {
                    margin-right: $margin-container-landing-item-promo;
                }

                &:nth-child(2n-1):nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-footer {
        @extend %text-landing-footer;
        margin-top: $margin-top-container-landing-footer;

        .ms-footer__list {
            @include center-flex(x);
            flex-wrap: wrap;

            > *:not(:last-child) {
                margin-bottom: $margin-bottom-link-landing-footer__mobile;
                margin-right: $margin-right-link-landing-footer__mobile;

                @include media-breakpoint-up(lg) {
                    margin-bottom: $margin-bottom-link-landing-footer;
                    margin-right: $margin-right-link-landing-footer;
                }
            }
        }

        .ms-footer__link {
            @extend %link-landing-footer;
        }
    }

    &-copyright {
        padding: $padding-container-landing-copyright__mobile;

        @include media-breakpoint-up(lg) {
            padding: $padding-container-landing-copyright;
            margin-bottom: $margin-bottom-container-landing-copyright;
        }
    }
}
