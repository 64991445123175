%button {
    @include button;

    &-underlined {
        @include button-line-alt;
    }

    &-inverse {
        @include button-color(
            $color-button-inverse__border,
            $color-button-inverse__background,
            $color-button-inverse__text,
            $color-button-inverse__border--hover,
            $color-button-inverse__background--hover,
            $color-button-inverse__text--hover
        );
    }

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-disabled__border,
            $color-button-disabled__background,
            $color-button-disabled__text,
            $color-button-disabled__border,
            $color-button-disabled__background,
            $color-button-disabled__text
        );
    }
}

%button-primary {
    @include button(
        $padding-button-primary,
        $border-width-button-primary,
        $border-style-button-primary,
        $border-radius-button-primary,
        $color-button-primary__border,
        $color-button-primary__background,
        $color-button-primary__text,
        $color-button-primary__border--hover,
        $color-button-primary__background--hover,
        $color-button-primary__text--hover,
        $font-family-button-primary,
        $font-size-button-primary,
        $font-size-button-primary__tablet,
        $font-size-button-primary__mobile,
        $font-weight-button-primary,
        $font-style-button-primary,
        $letter-spacing-button-primary,
        $line-height-button-primary,
        $line-height-button-primary__tablet,
        $line-height-button-primary__mobile,
        $text-transform-button-primary,
        $text-decoration-button-primary,
        $text-decoration-button-primary--hover
    );

    &-inverse {
        @include button-color(
            $color-button-primary-inverse__border,
            $color-button-primary-inverse__background,
            $color-button-primary-inverse__text,
            $color-button-primary-inverse__border--hover,
            $color-button-primary-inverse__background--hover,
            $color-button-primary-inverse__text--hover,
        );
    }

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-primary-disabled__border,
            $color-button-primary-disabled__background,
            $color-button-primary-disabled__text,
            $color-button-primary-disabled__border,
            $color-button-primary-disabled__background,
            $color-button-primary-disabled__text
        );
    }
}

%button-secondary {
    @include button(
        $padding-button-secondary,
        $border-width-button-secondary,
        $border-style-button-secondary,
        $border-radius-button-secondary,
        $color-button-secondary__border,
        $color-button-secondary__background,
        $color-button-secondary__text,
        $color-button-secondary__border--hover,
        $color-button-secondary__background--hover,
        $color-button-secondary__text--hover,
        $font-family-button-secondary,
        $font-size-button-secondary,
        $font-size-button-secondary__tablet,
        $font-size-button-secondary__mobile,
        $font-weight-button-secondary,
        $font-style-button-secondary,
        $letter-spacing-button-secondary,
        $line-height-button-secondary,
        $line-height-button-secondary__tablet,
        $line-height-button-secondary__mobile,
        $text-transform-button-secondary,
        $text-decoration-button-secondary,
        $text-decoration-button-secondary--hover
    );

    &-inverse {
        @include button-color(
            $color-button-secondary-inverse__border,
            $color-button-secondary-inverse__background,
            $color-button-secondary-inverse__text,
            $color-button-secondary-inverse__border--hover,
            $color-button-secondary-inverse__background--hover,
            $color-button-secondary-inverse__text--hover
        );
    }

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-secondary-disabled__border,
            $color-button-secondary-disabled__background,
            $color-button-secondary-disabled__text,
            $color-button-secondary-disabled__border,
            $color-button-secondary-disabled__background,
            $color-button-secondary-disabled__text
        );
    }
}

.button {
    @extend %button;
}

.button-primary {
    @extend %button-primary;
}

.button-secondary {
    @extend %button-secondary;
}

.button-underlined {
    @extend %button-underlined;

    @include plain-hover-all {
        @include link-text-decoration(none);
    }
}
