.ms-product-collection {
    width: $width-content-xl;
    margin: $margin-container-content__mobile;

    @include media-breakpoint-up(sm) {
        width: $width-content-xl;
        margin: $margin-container-content__tablet;
        margin-top: 75px;
    }

    @include media-breakpoint-up(xl) {
        width: $width-shop;
        margin: $margin-container-content;
        margin-top: 100px;
    }

    &__heading {
        text-align: center;
    }
}

.product-collection-container > .container-with-header-header {
    @include width-container-with-margin(
        $width-content-lg,
        $width-content-lg,
        $width-content-lg
    );
    text-align: center;
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }

    > .container-with-header-title {
        margin-bottom: 15px;
    }
}

.product-collection-container .ms-product-collection {
    margin-top: 0;
}
