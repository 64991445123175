$margin-left-icon-refiner-pulldown-heading: 10px;
$icon-refiner-pulldown-heading-expand: $asset-icon-dropdown-expand;
$icon-refiner-pulldown-heading-collapse: $asset-icon-dropdown-collapse;
$width-icon-refiner-pulldown-heading: 14px;
$height-icon-refiner-pulldown-heading: 9px;

%icon-refiner-pulldown-heading {
    @include transition;

    &-expand {
        @include icon-image($icon-refiner-pulldown-heading-expand, after, $width-icon-refiner-pulldown-heading, $height-icon-refiner-pulldown-heading);
    }

    &-collapse {
        @include icon-image($icon-refiner-pulldown-heading-collapse, after, $width-icon-refiner-pulldown-heading, $height-icon-refiner-pulldown-heading);
    }

    &-expand,
    &-collapse {
        @extend %icon-refiner-pulldown-heading;
    }

    &::after {
        margin-left: $margin-left-icon-refiner-pulldown-heading;
    }
}
