.club-checkout {
    .msc-cart-line__product-title {
        pointer-events: none;
    }

    .checkout-step-club-info {
        margin-bottom: $margin-bottom-container-checkout-card__mobile;

        @include media-breakpoint-up(xl) {
            width: $width-container-checkout-card__desktop;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xxl) {
            width: $width-container-checkout-card;
        }

        &-heading {
            @extend %heading-h3;
        }

        label {
            @extend %label-checkout-card;
        }

        input,
        select {
            @extend %input-checkout-card;
        }
    }

    .checkout-step-input-end-date {
        margin-bottom: 15px;
    }

    .checkout-club-info-collector-error-lines {
        display: block;
    }

    .checkout-club-info-collector-display-field {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xxl) {
            margin-bottom: 0;
        }

        &-name {
            @extend %text-appellation;
            @extend %text-appellation-accent;
            @include text-font-weight($font-weight-bold);
            display: block;

            @include media-breakpoint-up(xxl) {
                display: inline-block;
                min-width: 185px;
                margin-right: 15px;
            }
        }

        &-value {
            @include media-breakpoint-up(xxl) {
                display: inline-block;
                min-width: 180px;
            }
        }
    }

    .checkout-step-club-info-control {
        margin-bottom: $margin-bottom-input-checkout-card;
    }

    .container-with-header-text{
        *{
            color: #797676 !important;
        }
    }
}

.club-checkout-page {
    .msc-cart-icon,
    .ms-cart-icon,
    .ms-search,
    .ms-header__account-info,
    .ms-checkout__line-items-edit-cart-link {
        display: none;
    }
}
