.msc-address-select {
    &__button-add {
        @extend %button-address-select;
    }

    &__item {
        display: flex;
        margin-bottom: $margin-bottom-container-address-select-item;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__input-radio {
        @extend %radio-address-select;
    }
}
