%link {
    @include link;

    &-inverse {
        @include link-color($color-link-inverse, $color-link-inverse--hover);
    }

    &-accent {
        @include link-color($color-link-accent, $color-link-accent--hover);
    }

    &-muted {
        @include link-color($color-link-muted, $color-link-muted--hover);
    }
}
