.container-with-header {
    .container-with-header-title:empty {
        display: none;
    }

    > .container-with-header-header {
        > .container-with-header-text {
            margin-bottom: $margin-bottom-text-container-with-header;
        }

        > .container-with-header-title {
            margin-bottom: $margin-bottom-heading-container-with-header;
        }
    }

    > .container-with-header-header:last-child {
        > .container-with-header-text,
        > .container-with-header-title:last-child {
            margin-bottom: 0;
        }
    }
}
