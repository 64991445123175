.slider {
    display: block;
    position: relative;
    padding: $padding-container-slider;
    cursor: pointer;
    touch-action: none;

    &__track {
        position: absolute;
        top: $margin-top-line-slider-track;
        height: 100%;
        background-color: $color-background-line-slider-track;
    }

    &-horizontal {
        height: $height-line-slider-background;
        background-color: $color-background-line-slider-background;
    }

    &__thumb {
        @include vfi();
        @include center-absolute(both);
        display: inline-block;
        width: $size-circle-slider-thumb;
        height: $size-circle-slider-thumb;
        border-radius: $border-radius-circle-slider-thumb;
        outline-offset: $outline-offset-circle-slider-thumb;
        background: $background-circle-slider-thumb;
        background-size: $size-background-circle-slider-thumb;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            outline-offset: $outline-offset-circle-slider-thumb-before;
        }

        &__range:focus + .slider__thumb {
            border: $border-width-circle-slider-thumb-focus $border-style-circle-slider-thumb-focus $color-border-circle-slider-thumb-focus;
            outline: $border-width-circle-slider-thumb-focus $border-style-circle-slider-thumb-focus $color-outline-circle-slider-thumb-focus;
        }
    }

    .slider__labels-item {
        display: inline-block;
        padding-bottom: $padding-bottom-label-slider-item;
        margin-top: $margin-top-label-slider-item;
        font-size: $font-size-label-slider-item;
        cursor: pointer;

        &.start {
            float: left;
        }

        &.end {
            float: right;
        }
    }
}
