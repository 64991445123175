// Shop
$width-shop: calc(14/16 * 100%);
$width-shop__tablet: $width-shop;
$width-shop__mobile: $width-shop;

// Shop Container
%width-shop {
    @include width-container-with-margin(
        $width-shop,
        $width-shop__tablet,
        $width-shop__mobile,
        separate
    );
}

// Shop Container with Breadcrumb
%width-shop-with-breadcrumb {
    @include width-container-with-margin(
        $width-shop,
        $width-shop__tablet,
        $width-shop__mobile
    );
}

// Shop Container with Header Text
$margin-bottom-container-shop-header-text: 35px;
$margin-bottom-heading-shop-header: 10px;

%text-shop-header {
    @extend %text;
    @extend %text-small;
    @extend %text-accent;
}

%link-shop-header {
    @extend %link;
    @extend %link-accent;
}

%width-shop-with-header > .container-with-header-header {
    margin-bottom: $margin-bottom-container-shop-header-text;
    
    > .container-with-header-title {
        margin-bottom: $margin-bottom-heading-shop-header;
    }

    > .container-with-header-text {
        @extend %text-shop-header;

        a {
            @extend %link-shop-header;
        }
    }
}
