// Instagram Widget Icon
$margin-top-icon-instagram-widget: 35px;
$margin-top-icon-instagram-widget__tablet: 25px;
$margin-top-icon-instagram-widget__mobile: 20px;
$width-icon-instagram-widget: 21px;
$height-icon-instagram-widget: 22px;
$icon-instagram-widget: $asset-icon-social-instagram;
$text-align-icon-instagram-widget: center;

%icon-instagram-widget {
    @include icon-image($icon-instagram-widget, before, $width-icon-instagram-widget, $height-icon-instagram-widget);
}
