.ms-search {
    &__form {
        @include center-flex(y);
        position: absolute;
        z-index: $z-index-container-search;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-color: $color-background;

        &.bx-hide {
            display: none;
        }

        &.bx-show {
            display: block;
            @include animation-fadeInDown;
        }

        &-container {
            @include center-flex(y);
            @extend %icon-search;
            position: relative;

            @include media-breakpoint-down(lg) {
                flex: 1;
                height: 100%;
            }

            &::before {
                position: absolute;

                @include media-breakpoint-down(lg) {
                    left: $margin-x-icon-search;
                }

                @include media-breakpoint-up(xl) {
                    right: $margin-x-icon-search;
                }
            }
        }

        &-control {
            @extend %input-search;

            @include media-breakpoint-down(lg) {
                height: 100%;
                padding-left: $padding-left-input-search__mobile;
                border: 0;
            }

            @include media-breakpoint-up(xl) {
                width: $width-input-search;
                min-width: $min-width-input-search;
                padding-right: $padding-right-input-search;
            }
        }

        &-placeholder {
            display: none;

            @include media-breakpoint-up(xl) {
                display: block;
                width: $width-container-search-placeholder;
                margin-left: $margin-left-container-search-placeholder;
            }
        }

        &-cancelSearch,
        &-cancelSearchNoSumbit {
            @include button-remove;
            @extend %search-close;
            margin-right: $margin-right-icon-search-close;

            @include media-breakpoint-down(lg) {
                position: absolute;
                right: 0;
            }
        }
    }

    &__searchForm {
        @include center-flex(y);
        justify-content: space-between;
        height: 100%;
    }

    &__autoSuggest {
        position: absolute;
        left: 0;
        right: 0;
        border-top: $border-container-search-results;
        box-shadow: $box-shadow-container-search-results;
        background-color: $color-background;

        @include media-breakpoint-up(xl) {
            @include center-absolute(x);
            top: $top-container-search-results;
            width: $width-container-search-results;
            min-width: $min-width-container-search-results;
            border: $border-container-search-results;
        }

        &:empty {
            display: none;
        }

        .msc-autoSuggest__screen-reader {
            @include screen-reader;
        }

        &__keyword,
        &__category {
            display: none;
        }

        &__product {
            padding: $padding-container-search-results;

            .msc-autoSuggest__productResults-title {
                display: none;
            }

            .msc-autoSuggest__productResults-item {
                @extend %link-search-results-item;

                &:not(:last-child) {
                    padding-bottom: $padding-bottom-link-search-results-item;
                    margin-bottom: $margin-bottom-link-search-results-item;
                    border-bottom: $border-bottom-link-search-results-item;
                }

                &-price,
                &-img {
                    display: none;
                }
            }
        }
    }
}
