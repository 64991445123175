//==============================================================================
// BASE FONT FAMILIES
//==============================================================================
$font-family-system: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
$font-family-avenir: 'Avenir', 'Helvetica', 'Arial', sans-serif;
$font-family-joane: 'Joane', 'Georgia', 'Times New Roman', serif;

//==============================================================================
// COMPONENT FONT FAMILIES
//==============================================================================

// Text
$font-family-text: $font-family-avenir;
$font-family-text-featured: $font-family-joane;
$font-family-text-appellation: $font-family-text;

// Heading
$font-family-heading: $font-family-joane;
$font-family-heading-h1: $font-family-heading;
$font-family-heading-h2: $font-family-heading;
$font-family-heading-h3: $font-family-heading;
$font-family-heading-h4: $font-family-heading;
$font-family-heading-h5: $font-family-heading;
$font-family-heading-h6: $font-family-avenir;

// Button
$font-family-button: $font-family-avenir;
$font-family-button-primary: $font-family-button;
$font-family-button-secondary: $font-family-button;

// Input
$font-family-input: $font-family-avenir;

//==============================================================================
// FONT SIZES
//==============================================================================

// Text
$font-size-text: 17px;
$font-size-text__tablet: 16px;
$font-size-text__mobile: 15px;

$font-size-text-small: 15px;
$font-size-text-small__tablet: $font-size-text-small;
$font-size-text-small__mobile: 14px;

$font-size-text-featured: 27px;
$font-size-text-featured__tablet: 21px;
$font-size-text-featured__mobile: 18px;

$font-size-text-appellation: 13px;
$font-size-text-appellation__tablet: $font-size-text-appellation;
$font-size-text-appellation__mobile: $font-size-text-appellation;

// Heading
$font-size-heading: 36px;
$font-size-heading__tablet: 27px;
$font-size-heading__mobile: 21px;

$font-size-heading-h1: 42px;
$font-size-heading-h1__tablet: 33px;
$font-size-heading-h1__mobile: 24px;

$font-size-heading-h2: 36px;
$font-size-heading-h2__tablet: 27px;
$font-size-heading-h2__mobile: 21px;

$font-size-heading-h3: 27px;
$font-size-heading-h3__tablet: 21px;
$font-size-heading-h3__mobile: 18px;

$font-size-heading-h4: 21px;
$font-size-heading-h4__tablet: 18px;
$font-size-heading-h4__mobile: 15px;

$font-size-heading-h5: 15px;
$font-size-heading-h5__tablet: 14px;
$font-size-heading-h5__mobile: 12px;

$font-size-heading-h6: 13px;
$font-size-heading-h6__tablet: 12px;
$font-size-heading-h6__mobile: $font-size-heading-h6__tablet;

// Button
$font-size-button: 13px;
$font-size-button__tablet: 12px;
$font-size-button__mobile: $font-size-button__tablet;

$font-size-button-primary: $font-size-button;
$font-size-button-primary__tablet: $font-size-button__tablet;
$font-size-button-primary__mobile: $font-size-button__mobile;

$font-size-button-secondary: $font-size-button-primary;
$font-size-button-secondary__tablet: $font-size-button-primary__tablet;
$font-size-button-secondary__mobile: $font-size-button-primary__mobile;

// Input
$font-size-input: 15px;
$font-size-input__tablet: $font-size-input;
$font-size-input__mobile: 14px;

// Icon
$font-size-icon: 20px;

//==============================================================================
// FONT WEIGHTS
//==============================================================================

$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-normal: 400;

// Text
$font-weight-text: $font-weight-normal;
$font-weight-text-featured: $font-weight-semibold;
$font-weight-text-appellation: $font-weight-normal;

// Heading
$font-weight-heading: $font-weight-semibold;
$font-weight-heading-h1: $font-weight-heading;
$font-weight-heading-h2: $font-weight-heading;
$font-weight-heading-h3: $font-weight-heading;
$font-weight-heading-h4: $font-weight-heading;
$font-weight-heading-h5: $font-weight-heading;
$font-weight-heading-h6: $font-weight-bold;

// Button
$font-weight-button: $font-weight-bold;
$font-weight-button-primary: $font-weight-button;
$font-weight-button-secondary: $font-weight-button;

// Input
$font-weight-input: $font-weight-normal;

//==============================================================================
// FONT STYLES
//==============================================================================

// Text
$font-style-text: normal;
$font-style-text-featured: normal;
$font-style-text-appellation: normal;

// Heading
$font-style-heading: normal;
$font-style-heading-h1: normal;
$font-style-heading-h2: normal;
$font-style-heading-h3: normal;
$font-style-heading-h4: normal;
$font-style-heading-h5: normal;
$font-style-heading-h6: normal;

// Button
$font-style-button: normal;
$font-style-button-primary: normal;
$font-style-button-secondary: normal;

// Input
$font-style-input: normal;

//==============================================================================
// LETTER SPACING
//==============================================================================

// Text
$letter-spacing-text: 0;
$letter-spacing-text-featured: 0;
$letter-spacing-text-appellation: 0;

// Heading
$letter-spacing-heading: 0;
$letter-spacing-heading-h1: 0;
$letter-spacing-heading-h2: 0;
$letter-spacing-heading-h3: 0;
$letter-spacing-heading-h4: 0;
$letter-spacing-heading-h5: 0;
$letter-spacing-heading-h6: 0.25em;

// Button
$letter-spacing-button: 0.25em;
$letter-spacing-button-primary: $letter-spacing-button;
$letter-spacing-button-secondary: $letter-spacing-button;

// Input
$letter-spacing-input: 0;

//==============================================================================
// LINE HEIGHTS
//==============================================================================

// Text
$line-height-text: 25px;
$line-height-text__tablet: 24px;
$line-height-text__mobile: 21px;

$line-height-text-small: 21px;
$line-height-text-small__tablet: $line-height-text-small;
$line-height-text-small__mobile: 19px;

$line-height-text-featured: 45px;
$line-height-text-featured__tablet: 33px;
$line-height-text-featured__mobile: 27px;

$line-height-text-appellation: 16px;
$line-height-text-appellation__tablet: $line-height-text-appellation;
$line-height-text-appellation__mobile: $line-height-text-appellation;

// Heading
$line-height-heading: 45px;
$line-height-heading__tablet: 33px;
$line-height-heading__mobile: 24px;

$line-height-heading-h1: 48px;
$line-height-heading-h1__tablet: 39px;
$line-height-heading-h1__mobile: 27px;

$line-height-heading-h2: 45px;
$line-height-heading-h2__tablet: 33px;
$line-height-heading-h2__mobile: 24px;

$line-height-heading-h3: 36px;
$line-height-heading-h3__tablet: 27px;
$line-height-heading-h3__mobile: 21px;

$line-height-heading-h4: 27px;
$line-height-heading-h4__tablet: 21px;
$line-height-heading-h4__mobile: 19px;

$line-height-heading-h5: 19px;
$line-height-heading-h5__tablet: 19px;
$line-height-heading-h5__mobile: 16px;

$line-height-heading-h6: 18px;
$line-height-heading-h6__tablet: 16px;
$line-height-heading-h6__mobile: $line-height-heading-h6__tablet;

// Button
$line-height-button: 21px;
$line-height-button__tablet: 18px;
$line-height-button__mobile: $line-height-button__tablet;

$line-height-button-primary: 18px;
$line-height-button-primary__tablet: $line-height-button__tablet;
$line-height-button-primary__mobile: $line-height-button__mobile;

$line-height-button-secondary: $line-height-button-primary;
$line-height-button-secondary__tablet: $line-height-button-primary__tablet;
$line-height-button-secondary__mobile: $line-height-button-primary__mobile;

// Input
$line-height-input: 21px;
$line-height-input__tablet: $line-height-input;
$line-height-input__mobile: 19px;

//==============================================================================
// TEXT TRANSFORM
//==============================================================================

// Text
$text-transform-text: none;
$text-transform-text-featured: none;
$text-transform-text-appellation: none;

// Heading
$text-transform-heading: none;
$text-transform-heading-h1: none;
$text-transform-heading-h2: none;
$text-transform-heading-h3: none;
$text-transform-heading-h4: none;
$text-transform-heading-h5: none;
$text-transform-heading-h6: uppercase;

// Button
$text-transform-button: uppercase;
$text-transform-button-primary: uppercase;
$text-transform-button-secondary: uppercase;

// Input
$text-transform-input: none;

//==============================================================================
// TEXT DECORATION
//==============================================================================

// Link
$text-decoration-link: underline;
$text-decoration-link--hover: underline;

// Button
$text-decoration-button: none;
$text-decoration-button--hover: none;

$text-decoration-button-primary: none;
$text-decoration-button-primary--hover: none;

$text-decoration-button-secondary: none;
$text-decoration-button-secondary--hover: none;
