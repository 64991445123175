.msc-videoplayer {
    &__video {
        display: block;
        max-width: 100%;
    }

    &__errormessage {
        &[aria-hidden='true'] {
            display: none;
        }

        &__wrapper {
            @extend %alert-videoplayer-error;
        }
    }

    &__screenreader {
        @include screen-reader;
    }

    &__spinner {
        display: none;
    }
}
