// Landing Page
$max-width-page-landing: 1200px;
$padding-page-landing: 0 1rem;
$color-background-page-landing: $color-background;

// Landing Container
$padding-container-landing: 1rem 0;

// Landing Logo
$margin-top-image-landing-logo: 1rem;
$max-width-image-landing-logo: 200px;
$max-width-image-landing-logo__mobile: 150px;

// Landing Item Text
%text-landing {
    @extend %text;
}

// Landing Overview
$padding-container-landing-overview: 2rem 0;
$padding-container-landing-overview__mobile: 1rem 1.5rem;
$text-align-container-landing-overview: center;

// Landing Item
$margin-container-landing-item: 1.5rem 0;
$color-background-container-landing-item: $color-background-accent;

// Landing Item Image
%image-landing {
    @include image-cover;
}

// Landing Item Content
$padding-container-landing-item-content: 2rem 3rem;
$padding-container-landing-item-content__mobile: 2rem 1.5rem;

// Landing Item Button
$margin-top-button-landing: 1rem;

%button-landing {
    @extend %button-primary;

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}

// Landing Featured Item Content
$width-container-landing-item-content-featured: 50%;

// Landing Promo Item
$margin-container-landing-item-promo: 1.5rem;
$width-container-landing-item-promo: calc((100% - #{$margin-container-landing-item-promo}) / 2);

// Landing Footer
$margin-top-container-landing-footer: 2rem;

// Landing Footer Text
%text-landing-footer {
    @extend %text-appellation;
}

// Landing Footer Link
$margin-bottom-link-landing-footer: 0;
$margin-bottom-link-landing-footer__mobile: 1rem;
$margin-right-link-landing-footer: 1rem;
$margin-right-link-landing-footer__mobile: 2rem;
$line-height-link-landing-footer: 25px;
$letter-spacing-link-landing-footer: 0.075em;
$text-transform-link-landing-footer: uppercase;
$text-decoration-link-landing-footer: none;
$text-decoration-link-landing-footer--hover: none;

%link-landing-footer {
    @extend %link;
    @include text-line-height($line-height-link-landing-footer);
    @include text-letter-spacing($letter-spacing-link-landing-footer);
    @include text-text-transform($text-transform-link-landing-footer);
    @include link-text-decoration($text-decoration-link-landing-footer, $text-decoration-link-landing-footer--hover);
}

// Landing Copyright
$padding-container-landing-copyright: 0;
$padding-container-landing-copyright__mobile: 1rem 0;
$margin-bottom-container-landing-copyright: 1rem;
