.ms-refine-submenu {
    // Hide price refiner
    &:nth-child(2) {
        display: none;
    }

    // Hide rating refiner
    &:nth-child(3) {
        display: none;
    }

    &__toggle_expanded,
    &__toggle_collapsed {
        @extend %heading-h6;
        margin-bottom: 15px;

        @include media-breakpoint-up(xl) {
            margin-bottom: 20px;
        }
    }

    &-item {
        @include link-text-decoration(none);
        @extend %heading-h4;

        @at-root li#{&} {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            &::before {
                @include transition;
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                margin-right: 15px;
                border: 1px solid $color-border;

                @include media-breakpoint-up(xl) {
                    margin-bottom: 2px;
                }
            }

            &.single-select::before,
            &.single-select-checked::before {
                border-radius: 50%;
            }

            &.multi-select-checked::before,
            &.single-select-checked::before {
                border-color: $color-button-primary__background;
                background-color: $color-button-primary__background;
            }

            &:hover {
                &::before {
                    border-color: $color-border-muted;
                    background-color: $color-border-muted;
                }

                &.multi-select-checked::before,
                &.single-select-checked::before {
                    border-color: $color-button-primary__background--hover;
                    background-color: $color-button-primary__background--hover;
                }
            }
        }
    }
}

.refiner-links {
    @extend %width-shop-with-breadcrumb;
    @include center-flex(y);
    flex-direction: column;
    position: relative;
    z-index: 2;

    .overflow-container-btn {
        @include button-remove;
        @extend %heading-h6;
        @extend %icon-refiner-pulldown-heading-expand;
        @include center-flex(y);
        @include flex-width(100%);
        justify-content: space-between;
        z-index: 2;
        padding: 15px 15px 12px calc(1/16 * 16/14 * 100%);
        border: 1px solid $color-border;
        background-color: $color-background;

        @include media-breakpoint-up(sm) {
            @include flex-width(calc(6/16 * 16/14 * 100%));
            padding: 15px 20px 12px;
        }

        @include media-breakpoint-up(xl) {
            @include flex-width(calc(4/16 * 16/14 * 100%));
        }
    }

    &.overflow-container-opened .overflow-container-btn {
        @extend %icon-refiner-pulldown-heading-collapse;
        border-bottom-color: $color-background;
    }

    .overflow-container-content {
        @include animation-fadeIn;
        display: block;
        position: absolute;
        z-index: 1;
        top: calc(100% - 2px);
        width: 100%;
        padding: 15px calc(1/16 * 16/14 * 100%) 12px;
        border: 1px solid $color-border;
        background-color: transparentize($color-background, 0.15);

        @include media-breakpoint-up(sm) {
            width: calc(6/16 * 16/14 * 100%);
            padding: 15px 20px;
        }

        @include media-breakpoint-up(xl) {
            width: calc(4/16 * 16/14 * 100%);
        }
    }

    &.overflow-container-closed .overflow-container-content {
        display: none;
    }
}

.category-link-list {
    .link-list__link {
        @include link-text-decoration(none);
        @extend %heading-h4;
        display: block;
        margin-bottom: 15px;

        @include media-breakpoint-up(xl) {
            margin-bottom: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.active {
            display: none;
        }
    }
}
