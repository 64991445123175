.msc-carousel {
    @include center-flex;
    position: relative;

    .pointer-event {
        touch-action: pan-y;
    }

    &__dismiss {
        @include icon-content($icon-carousel-dismiss);
        position: absolute;
        right: 0;
        top: 0;
        border-color: $color-border-carousel-dismiss;
        background-color: $color-background-carousel-dismiss;
        color: $color-icon-carousel-dismiss;

        &::before {
            font-size: $font-size-icon-carousel-dismiss;
            position: unset;
            visibility: visible;
            vertical-align: $vertical-align-icon-carousel-dismiss;
            text-align: $text-align-icon-carousel-dismiss;
        }
    }

    &__inner {
        @include clearfix;
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    &__item {
        @include transition-carousel($transition-carousel);
        display: none;
        position: relative;
        float: left;
        backface-visibility: hidden;
        width: 100%;
        height: 100%;
        margin-right: -100%;
        text-decoration: none;
    }

    &__item {
        &.active {
            text-decoration: none;
        }

        &.active,
        &__next,
        &__prev {
            display: block;
        }
        a.msc-cta__primary{
            &:focus{
                border: 3px solid !important
            }
        }

    }

    &.carousel-fade {
        .msc-carousel__item {
            opacity: 0;
            transition-property: opacity;
            transform: none;
        }

        .msc-carousel__item.active,
        .msc-carousel__item__next.msc-carousel__item__left,
        .msc-carousel__item__prev.msc-carousel__item__right {
            z-index: 1;
            opacity: 1;
        }

        .active.msc-carousel__item__left,
        .active.msc-carousel__item__right {
            @include transition-carousel(0 $transition-duration-carousel opacity);
            z-index: 0;
            opacity: 0;
        }
    }

    &__control__prev,
    &__control__next {
        @include center-flex;
        @include transition-carousel($transition-container-carousel-control);
        position: absolute;
        z-index: $z-index-container-carousel-control;
        top: $top-container-carousel-control;
        bottom: 0;
        width: $width-container-carousel-control;
        height: $height-container-carousel-control;
        opacity: $opacity-container-carousel-control;
        color: $color-container-carousel-control;
        text-align: $text-align-container-carousel-control;
        transition: none;

        // Hover/focus state
        @include hover-focus {
            opacity: $opacity-container-carousel-control--hover;
            outline: $outline-container-carousel-control--hover !important;
            color: $color-container-carousel-control--hover;
            text-decoration: none;

            &::before {
                outline: $outline-container-carousel-control-before--hover;
            }

            &__icon {
                outline: 0;

                &::before {
                    outline: 0;
                }
            }
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            outline-offset: 1px;
        }
    }

    &__control__prev {
        justify-content: flex-start;
        left: 0;
        margin-left: $margin-x-container-carousel-control;

        &__icon {
            margin-left: $margin-x-icon-carousel-control;
        }
    }

    &__control__next {
        justify-content: flex-end;
        right: 0;
        margin-right: $margin-x-container-carousel-control;

        &__icon {
            margin-right: $margin-x-icon-carousel-control;
        }
    }

    &__control__prev__icon,
    &__control__next__icon {
        display: inline-block;
        width: $width-icon-carousel-control;
        height: $height-icon-carousel-control;
        background: no-repeat 50% / 100% 100%;
    }

    &__control__prev__icon {
        background-image: $icon-carousel-control-prev;
    }

    &__control__next__icon {
        background-image: $icon-carousel-control-next;
    }

    &__caption {
        position: absolute;
        z-index: $z-index-container-carousel-caption;
        bottom: $bottom-container-carousel-caption;
        left: $left-container-carousel-caption;
        right: $right-container-carousel-caption;
        padding-top: $padding-top-container-carousel-caption;
        padding-bottom: $padding-bottom-container-carousel-caption;
        color: $color-container-carousel-caption;
        text-align: $text-align-container-carousel-caption;
    }

    &__indicators {
        @include center-flex(x);
        position: absolute;
        z-index: $z-index-container-carousel-indicators;
        bottom: $bottom-container-carousel-indicators__mobile;
        left: $left-container-carousel-indicators;
        right: $right-container-carousel-indicators;
        padding-left: 0; // override <ol> default
        // Use the .carousel-control's width as margin so we don't overlay those
        margin-right: $margin-x-container-carousel-indicators;
        margin-left: $margin-x-container-carousel-indicators;
        list-style: none;

        @include media-breakpoint-up(md) {
            bottom: $bottom-container-carousel-indicators;
        }

        li {
            @include transition-carousel($transition-icon-carousel-indicator);
            @include vfi-pseudo(after, 3px);
            flex: 0 1 auto;
            width: $width-icon-carousel-indicator;
            height: $height-icon-carousel-indicator;
            opacity: $opacity-icon-carousel-indicator;
            margin-right: $margin-x-icon-carousel-indicator;
            margin-left: $margin-x-icon-carousel-indicator;
            border: $border-icon-carousel-indicator;
            border-radius: $border-radius-icon-carousel-indicator;
            background-color: $color-white;
            background-clip: padding-box;
            box-sizing: content-box;
            text-indent: -999px;
            cursor: pointer;
        }

        .active {
            opacity: $opacity-icon-carousel-indicator--active;
        }

        > div {
            display: none;
        }
    }

    &.hide-arrows {
        .msc-carousel__control__prev,
        .msc-carousel__control__next {
            display: none;
        }
    }
}

.msc-carousel__item__next:not(.msc-carousel__item__left),
.active.msc-carousel__item__right {
    transform: translateX(100%);
}

.msc-carousel__item__prev:not(.msc-carousel__item__right),
.active.msc-carousel__item__left {
    transform: translateX(-100%);
}

.screen-reader-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    white-space: nowrap;
}