// Shipping Map Fill
$color-shape-shipping-map__fill: $color-silver;
$color-shape-shipping-map__fill--active: $color-breakerbay;

// Shipping Map Stroke
$stroke-width-shape-shipping-map: 2;
$stroke-linejoin-shape-shipping-map: round;
$stroke-linecap-shape-shipping-map: round;
$stroke-opacity-shape-shipping-map: 0.25;
$color-shape-shipping-map__stroke: $color-white;

// Shipping Map Legend
$margin-top-container-shipping-map-legend: 25px;

// Shipping Map Legend Item Text
$margin-bottom-text-shipping-map-legend-item-text: 5px;
$line-height-text-shipping-map-legend-item-text: 18px;

// Shipping Map Legend Item Box
$size-container-shipping-map-legend-item-box: 14px;
$margin-right-container-shipping-map-legend-item-box: 10px;
$border-radius-container-shipping-map-legend-item-box: 50%;
$color-container-shipping-map-legend-item-box: $color-shape-shipping-map__fill;
$color-container-shipping-map-legend-item-box--active: $color-shape-shipping-map__fill--active;

// Shipping Map List
$margin-top-container-shipping-map-list: 10px;

// Shipping Map List Item Quantity
$margin-left-container-shipping-map-item-quantity: 3px;
