// Cart Heading
$margin-bottom-heading-cart: 15px;
$text-transform-heading-cart: capitalize;

%heading-cart {
    @include heading-text-transform($text-transform-heading-cart);
}

// Cart Error Icon
$icon-cart-error: $icon-warning;
$margin-right-icon-cart-error: 5px;

%icon-cart-error {
    @include icon-content($icon-warning);

    &::before {
        display: none;

        @include media-breakpoint-up(xs) {
            display: inline-block;
            margin-right: $margin-right-icon-cart-error;
        }
    }
}

// Cart Error
$margin-bottom-alert-cart-error: 25px;

%alert-cart-error {
    @extend %alert;
    @extend %alert-danger;
    @extend %icon-cart-error;
}

// Cart Checkout Button
$margin-top-button-cart-checkout: 20px;

%button-cart-checkout {
    @extend %button-primary;

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}

// Cart Shop Button
$margin-bottom-button-cart-shop: 10px;

%button-cart-shop {
    @extend %button-secondary;

    &:disabled,
    &.disabled {
        @extend %button-secondary-disabled;
    }
}

// Cart Shop Button Empty
$margin-top-button-cart-shop-empty: 20px;

%button-cart-shop-empty {
    @extend %button-primary;

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}

// Cart Items
$margin-container-cart-items: 15px 0;

// Cart Items Heading
%heading-cart-items {
    @extend %heading-h6;
}

// Cart Items Product
$width-container-cart-items-product: 50%;
$margin-right-container-cart-items-product: 15px;

%text-cart-items-product {
    @extend %heading-h4;
}

// Cart Items Quantity
$width-container-cart-items-quantity: 20%;
$width-container-cart-items-quantity__mobile: 30%;

// Cart Items Quantity Select
$width-select-cart-item-quantity: 60px;
$height-select-cart-item-quantity: 30px;
$padding-select-cart-item-quantity: 7px 0 4px 3px;
$margin-right-select-cart-item-quantity: 10px;
$margin-right-select-cart-item-quantity__mobile: 5px;
$text-align-select-cart-item-quantity: center;

%select-cart-item-quantity {
    @extend %select;
    @include input-width($width-select-cart-item-quantity);
    text-align: $text-align-select-cart-item-quantity;
    text-align-last: $text-align-select-cart-item-quantity;
}

// Cart Items Remove Icon
$icon-cart-items-remove: $asset-icon-delete;
$size-icon-cart-items-remove: 14px;
$opacity-icon-cart-items-remove: 0.5;
$opacity-icon-cart-items-remove--hover: 1;

%icon-cart-items-remove {
    @include icon-image($icon-cart-items-remove, before, $size-icon-cart-items-remove);
}

// Cart Items Price
%text-cart-items-price {
    @extend %text;
}

// Cart Items Unit Price
$width-container-cart-items-price-unit: 20%;

// Cart Items Total Price
$width-container-cart-items-price-total: 10%;
$width-container-cart-items-price-total__mobile: 20%;

// Cart Items Discount
$color-text-cart-items-discount: $color-text-accent;
$font-size-text-cart-items-discount: 12px;

// Cart Item
$padding-container-cart-item: 20px 0;

// Cart Item Error
$margin-alert-cart-item-error: 5px 0;

// Cart Order Summary
$width-container-cart-order-summary__desktop-only: 50%;
$margin-container-cart-order-summary: 30px 0 30px auto;
$margin-container-cart-order-summary__mobile: 30px 0;

// Cart Order Summary Items
$margin-bottom-container-cart-order-summary-items: 3px;

%text-cart-order-summary-items {
    @extend %text;
    @extend %text-small;
}

// Cart Order Summary Net
$margin-bottom-container-cart-order-summary-net: 10px;

// Cart Order Summary Total
$padding-top-container-cart-order-summary-total: 20px;
$margin-container-cart-order-summary-total: 15px 0 0;
$border-top-container-cart-order-summary-total: 1px solid $color-border;

// Cart Order Summary Total Label
$color-text-cart-order-summary-total-label: $color-button-primary__background;
$text-transform-text-cart-order-summary-total-label: capitalize;

%text-cart-order-summary-total-label {
    @extend %heading-h4;
    @include text-text-transform($text-transform-text-cart-order-summary-total-label);
    color: $color-text-cart-order-summary-total-label;
}

// Cart Order Summary Total Value
%text-cart-order-summary-total-value {
    @extend %text;
}

// Cart Gift Card
%text-cart-gift-card {
    @extend %text-small;
}

%input-cart-gift-card {
    @extend %input;
}

%label-cart-gift-card {
    @extend %label;
}

%text-cart-gift-card-message {
    @extend %text-appellation;
}
