%how-to-buy {
    @include width-container-with-margin(
        $width-container-howtobuy,
        $width-container-howtobuy__tablet,
        $width-container-howtobuy__mobile
    );
    margin: $margin-container-howtobuy__mobile;
    background-color: $color-background-container-howtobuy;
    text-align: $text-align-container-howtobuy;

    @include media-breakpoint-up(sm) {
        margin: $margin-container-howtobuy__tablet;
    }

    @include media-breakpoint-up(lg) {
        margin: $margin-container-howtobuy;
    }
    
    .alert,
    .alert-danger,
    .text-input-validation-error,
    .find-wine-query,
    .find-wine-query-int {
        position: relative;
        width: $width-input-howtobuy__mobile;
        margin: $margin-input-howtobuy;
        margin-top: 10px;

        &-title {
            display: none;
        }

        &-location-input {
            padding-right: $padding-right-input-howtobuy;
            &:focus {
                box-shadow: none !important;
                outline: 3px solid #0055B3 !important;
            }
        }

        &-search-button {
            @include button-remove;
            @include center-flex;
            @extend %icon-howtobuy-button;
            position: absolute;
            top: 0;
            right: 0;
            width: $size-howtobuy-button;
            height: $size-howtobuy-button;
            font-size: 0;

            @include plain-hover-all {
                @include button-remove;
            }

            &:disabled,
            &.disabled {
                @extend %icon-howtobuy-button-disabled;
            }
        }
    }

    .find-wine-result {
        @include width-container-with-margin(
            $width-container-howtobuy-results,
            $width-container-howtobuy-results__tablet,
            $width-container-howtobuy-results__mobile
        );
    }

    .find-wine-results-page > *:not(:last-child) {
        @include media-breakpoint-up(sm) {
            @include flex-width($width-container-howtobuy-results-item__tablet);
        }

        @include media-breakpoint-up(xl) {
            @include flex-width($width-container-howtobuy-results-item);
        }
    }
}

.how-to-buy-banner,
.more-ways-to-shop {
    @extend %how-to-buy;
}

.how-to-buy-banner__image img {
    width: 100%;
}

.how-to-buy-banner__content {
    padding: $padding-top-container-howtobuy-banner__mobile 0 $padding-bottom-container-howtobuy-banner__mobile;

    @include media-breakpoint-up(sm) {
        padding: $padding-top-container-howtobuy-banner__tablet 0 $padding-bottom-container-howtobuy-banner__tablet;
    }

    @include media-breakpoint-up(lg) {
        padding: $padding-top-container-howtobuy-banner 0 $padding-bottom-container-howtobuy-banner;
    }

    > .container-with-header-header,
    .find-wine {
        padding: 0 $padding-x-container-howtobuy-banner__mobile;

        @include media-breakpoint-up(sm) {
            padding: 0 $padding-x-container-howtobuy-banner__tablet;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 $padding-x-container-howtobuy-banner;
        }
    }

    > .container-with-header-header > .container-with-header-title {
        margin-bottom: $margin-bottom-heading-howtobuy-banner__mobile;

        @include media-breakpoint-up(lg) {
            margin-bottom: $margin-bottom-heading-howtobuy-banner;
        }
    }

    .alert,
    .alert-danger,
    .find-wine-query,
    .text-input-validation-error
     {
        @include media-breakpoint-up(sm) {
            width: $width-input-howtobuy-banner__tablet;
        }

        @include media-breakpoint-up(lg) {
            width: $width-input-howtobuy-banner;
        }
    }
}

.more-ways-to-shop {
    padding: $padding-y-container-howtobuy-moreways__mobile 0;

    @include media-breakpoint-up(sm) {
        padding: $padding-y-container-howtobuy-moreways__tablet 0;
    }

    @include media-breakpoint-up(lg) {
        padding: $padding-y-container-howtobuy-moreways 0;
    }

    &__delivery,
    &__local > .container-with-header-header,
    &__local .find-wine {
        padding: 0 $padding-x-container-howtobuy-moreways__mobile;

        @include media-breakpoint-up(sm) {
            padding: 0 $padding-x-container-howtobuy-moreways__tablet;
        }

        @include media-breakpoint-up(xxl) {
            padding: 0 $padding-x-container-howtobuy-moreways;
        }
    }

    > .container-with-header-header > .container-with-header-title {
        margin-bottom: $margin-bottom-heading-howtobuy-moreways__mobile;

        @include media-breakpoint-up(lg) {
            margin-bottom: $margin-bottom-heading-howtobuy-moreways;
        }
    }

    &__delivery > .container-with-header-slots {
        @include center-flex;
        flex-wrap: wrap;

        > * {
            @include flex-width($width-container-howtobuy-moreways-delivery-item);
            margin-right: $margin-x-container-howtobuy-moreways-delivery-item;

            &:last-child {
                margin-right: 0;
            }
        }

        img {
            max-height: $max-height-image-howtobuy-moreways-delivery-item__mobile;
            margin: auto;

            @include media-breakpoint-up(sm) {
                max-height: $max-height-image-howtobuy-moreways-delivery-item__tablet;
            }

            @include media-breakpoint-up(lg) {
                max-height: $max-height-image-howtobuy-moreways-delivery-item;
            }
        }
    }

    &__local {
        margin-top: $margin-top-container-howtobuy-moreways-local__mobile;

        @include media-breakpoint-up(sm) {
            margin-top: $margin-top-container-howtobuy-moreways-local__tablet;
        }

        @include media-breakpoint-up(lg) {
            margin-top: $margin-top-container-howtobuy-moreways-local;
        }
    }

    .alert,
    .alert-danger,
    .find-wine-query,
    .text-input-validation-error
     {
        @include media-breakpoint-up(sm) {
            width: $width-input-howtobuy-moreways__tablet;
        }

        @include media-breakpoint-up(lg) {
            width: $width-input-howtobuy-moreways;
        }
    }
}

.how-to-buy-container {
    background-color: $color-background-container-howtobuy-container;

    .find-wine,
    .find-wine-result,
    .find-wine-no-result {
        @include width-container-with-margin(
            $width-container-howtobuy-container,
            $width-container-howtobuy-container__tablet,
            $width-container-howtobuy-container__mobile
        );
        padding: $padding-container-howtobuy-container__mobile;

        @include media-breakpoint-up(sm) {
            padding: $padding-container-howtobuy-container__tablet;
        }

        @include media-breakpoint-up(lg) {
            padding: $padding-container-howtobuy-container;
        }
    }

    .find-wine {
        &-title {
            @extend %heading-howtobuy-container;
        }

        &-info-text {
            margin-bottom: $margin-bottom-heading-howtobuy-container__mobile;

            @include media-breakpoint-up(sm) {
                margin-bottom: $margin-bottom-heading-howtobuy-container;
            }
        }

        &-query,
        &-query-int {
            display: flex;
            flex-wrap: wrap;

            @include media-breakpoint-up(sm) {
                max-width: $max-width-container-howtobuy-container-query;
            }

            &-location-input,
            &-dropdown,
            &-choose-wine,
            &-search-button {
                @include flex-width(100%);
            }

            &-location-input,
            &-dropdown,
            &-choose-wine {
                margin-bottom: $margin-bottom-input-howtobuy-container__mobile;

                @include media-breakpoint-up(sm) {
                    margin-bottom: 0;
                    margin-right: $margin-right-input-howtobuy-container__tablet;
                }

                @include media-breakpoint-up(lg) {
                    margin-right: $margin-right-input-howtobuy-container;
                }
            }

            &-location-input {
                @include media-breakpoint-up(sm) {
                    @include flex-width($width-input-howtobuy-container);
                    order: 1;
                }
            }

            &-dropdown,
            &-choose-wine {
                @include media-breakpoint-up(sm) {
                    @include flex-width(auto);
                    flex: 1;
                    order: 2;
                }
            }

            &-search-filters {
                margin-bottom: $margin-bottom-input-howtobuy-container__mobile;

                @include media-breakpoint-up(sm) {
                    @include flex-width(100%);
                    display: flex;
                    order: 4;
                    margin-top: $margin-top-container-howtobuy-container-filters;
                    margin-bottom: 0;
                }
            }

            &-search-button {
                order: 3;

                @include media-breakpoint-up(sm) {
                    @include flex-width(auto);
                    order: 3;
                }
            }
        }
    }
}

.how-to-buy-delivery {
    text-align: $text-align-container-howtobuy;

    .more-ways-to-shop__delivery > .container-with-header-header > .container-with-header-title {
        @extend %heading-howtobuy-delivery;
        margin-bottom: $margin-bottom-heading-howtobuy-delivery__mobile;

        @include media-breakpoint-up(lg) {
            margin-bottom: $margin-bottom-heading-howtobuy-delivery;
        }
    }
}
