// Gift Card Balance
$max-width-container-gift-card-balance: 500px;

// Gift Card Balance Label
%label-gift-card-balance {
    @extend %label;
}

// Gift Card Balance Input
%input-gift-card-balance {
    @extend %input;
}

// Gift Card Balance Message
$margin-top-alert-gift-card-balance-error: 10px;

%alert-gift-card-balance-message {
    @extend %alert;
    @extend %alert-success;
    margin-top: $margin-top-alert-gift-card-balance-error;
}

// Gift Card Balance Error
$margin-top-alert-gift-card-balance-error: 10px;

%alert-gift-card-balance-error {
    @extend %alert;
    @extend %alert-danger;
    margin-top: $margin-top-alert-gift-card-balance-error;
}

// Gift Card Balance Button
$margin-top-button-gift-card-balance: 20px;

%button-gift-card-balance {
    @include button-width-full;
    @extend %button-primary;
    margin-top: $margin-top-button-gift-card-balance;

    @include media-breakpoint-up(sm) {
        @include button-width-auto;
    }

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}
