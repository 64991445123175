.ms-sign-in {
    &__sign-in-heading {
        @extend %heading-b2c;
        @extend %heading-h2;
    }

    &__forget-password {
        @extend %text-b2c-disclaimer;
        display: block;
        margin: ($margin-bottom-container-b2c-input-group * 2) 0;
        text-align: center;
    }

    &__sign-in-disclaimer {
        @extend %text-b2c-disclaimer;
    }

    &__sign-in-button {
        @extend %button-b2c-primary;
        margin-top: $margin-y-button-b2c;
    }

    &__sign-up-link {
        @extend %button-b2c-secondary;
    }

    &__password-icon {
        @extend %icon-b2c;

        &-show {
            @extend %icon-b2c-show;
        }

        &-hide {
            @extend %icon-b2c-hide;
        }
    }

    &__account-item-input.ms-sign-in__account-item-password {
        @extend %input-b2c-with-icon;
    }

    &__sign-up-heading,
    &__social-accounts {
        display: none;
    }
}
