.product-container-slots {
    > .container-with-header > .container-with-header-slots {
        @include media-breakpoint-up(xl) {
            @include center-flex(y);
            margin: $margin-container-product-details__desktop-only;
        }

        &:empty {
            display: none;
        }

        > .ms-accordion {
            margin: $margin-container-product-accordion__mobile;

            @include media-breakpoint-up(sm) {
                margin: $margin-container-product-accordion__tablet;
            }

            @include media-breakpoint-up(xl) {
                flex: 1;
                margin: $margin-container-product-accordion__desktop;
            }

            @include media-breakpoint-up(xxl) {
                margin: $margin-container-product-accordion;
            }

            .ms-accordion-header-section {
                display: none;
            }

            .collapse.show {
                @include media-breakpoint-up(xl) {
                    height: auto !important;
                }
            }

            .collapse:not(.show) {
                @include media-breakpoint-up(xl) {
                    display: block;
                }
            }

            .collapsing {
                @include media-breakpoint-up(xl) {
                    height: auto;
                    transition: none;
                }
            }

            .drawer__button {
                @extend %heading-product-accordion;

                @include media-breakpoint-up(xl) {
                    pointer-events: none;
                }

                &::after {
                    @include media-breakpoint-up(xl) {
                        display: none;
                    }
                }
            }

            .ms-accordion-item {
                padding: $padding-container-product-accordion-item__mobile;

                @include media-breakpoint-up(xl) {
                    padding: $padding-container-product-accordion-item;
                    border: 0;
                }

                &-section {
                    border: 0;
                }

                &-content {
                    padding: $padding-container-product-accordion-content;
                }

                &.nutritional-info .pdp-attribute {
                    display: flex;

                    .pdp-attribute-title::after {
                        content: $content-separator-product-attribute;
                        margin-right: $margin-right-separator-product-attribute;
                    }
                }

                &.nutritional-info .pdp-attribute.serving-size {
                    @extend %text-product-attribute-serving;
                    margin-top: $margin-top-text-product-attribute-serving;

                    .pdp-attribute-title::after {
                        content: '';
                    }
                }

                &.fact-sheet {
                    padding: 0;
                    border-bottom: 0;

                    .collapse.show {
                        height: auto !important;
                    }

                    .collapse:not(.show) {
                        display: block;
                    }

                    .collapsing {
                        height: auto;
                        transition: none;
                    }

                    .drawer__button {
                        display: none;
                    }

                    .ms-accordion-item-content {
                        padding: 0;
                    }

                    .product-attribute-download-cta {
                        @extend %heading-product-accordion;
                        @extend %icon-product-attribute-download;
                        @include center-flex(y);
                        padding: $padding-container-product-accordion-item__mobile;

                        @include media-breakpoint-up(xl) {
                            padding: $padding-container-product-accordion-item;
                        }

                        @include plain-hover-all {
                            @include link-text-decoration(none);
                        }

                        &::before {
                            margin-right: $margin-right-icon-product-attribute-download;
                            margin-bottom: $margin-bottom-icon-product-attribute-download;
                        }
                    }
                }
            }
        }

        > .ms-content-block,
        > .pdp-dynamic-image-wrapper {
            @include media-breakpoint-up(sm) {
                width: $width-image-product-details__tablet;
                margin: $margin-image-product-details;
            }

            @include media-breakpoint-up(xl) {
                @include flex-width($width-image-product-details);
                margin: 0;
            }

            .ms-content-block__image {
                margin-bottom: 0;
            }

            img {
                width: 100%;
                margin-bottom: 0;
            }
        }
    }
}

.product {
    @include media-breakpoint-down(xs) {
        margin-bottom: $height-container-sku-selector__mobile;
    }

    .ms-breadcrumb {
        position: absolute;
        z-index: 1;
        padding: $padding-container-product-breadcrumb;
    }
}

.product-container.ticket .pdp-attributes-list {
    @extend %text;
    @extend %text-small;
}
