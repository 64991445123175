.ms-search-result-container {
    min-height: 350px;

    @include media-breakpoint-up(sm) {
        min-height: 450px;
    }

    @include media-breakpoint-up(xl) {
        min-height: 550px;
    }

    &__no-results-message {
        text-align: center;
    }

    &__refiner-section {
        @include center-flex(y);
        flex-direction: column;
        position: relative;
        z-index: 2;
        margin-bottom: 35px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 50px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 35px;
        }

        .ms-search-result-container__refiner-pulldown-button {
            @include button-remove;
            @extend %heading-h6;
            @extend %icon-refiner-pulldown-heading-expand;
            @include center-flex(y);
            @include flex-width(100%);
            justify-content: space-between;
            z-index: 2;
            padding: 15px 15px 12px calc(1/16 * 16/14 * 100%);
            border: 1px solid $color-border;
            background-color: $color-background;

            @include media-breakpoint-up(sm) {
                @include flex-width(calc(6/16 * 16/14 * 100%));
                padding: 15px 20px 12px;
            }

            @include media-breakpoint-up(xl) {
                @include flex-width(calc(4/16 * 16/14 * 100%));
            }

            &.refiner-pulldown-button-expanded {
                @extend %icon-refiner-pulldown-heading-collapse;
                border-bottom-color: $color-background;
            }

            &-content {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                max-width: 90%;
                text-align: left;
            }
        }

        .ms-search-result-container__refiner-pulldown-container {
            @include animation-fadeIn;
            @include center-flex(y);
            @extend %scrollbar-y-no-padding;
            flex-direction: column;
            position: absolute;
            z-index: 1;
            top: calc(100% - 2px);
            width: 100%;
            max-height: 300px;
            padding: 35px calc(1/16 * 16/14 * 100%);
            border: 1px solid $color-border;
            background-color: transparentize($color-background, 0.15);

            @include media-breakpoint-up(sm) {
                max-height: 400px;
                padding: calc(0.5/16 * 16/14 * 100%) calc(0.75/16 * 16/14 * 100%);
            }

            @include media-breakpoint-up(xl) {
                max-height: 500px;
            }

            &.pulldown-hide {
                display: none;
            }
        }

        .ms-search-result-container__refine-menu {
            @include flex-width(100%);

            @include media-breakpoint-up(xl) {
                display: flex;
                flex-wrap: wrap;
            }

            > * {
                margin-bottom: 35px;

                &:last-child {
                    margin-bottom: 0;
                }

                @include media-breakpoint-up(xl) {
                    margin-right: calc(1/16 * 16/12 * 100%);
                    margin-bottom: 0;

                    &:last-child {
                        margin-right: 0;
                    }

                    // Skipping hidden price refiner (2nd child)
                    &:nth-child(1),
                    &:nth-child(4) {
                        @include flex-width(calc(3/16 * 16/12 * 100%));
                    }

                    &:nth-child(3) {
                        @include flex-width(calc(4/16 * 16/12 * 100%));
                    }
                }
            }
        }

        .ms-search-result-container__refiner-close-pulldown-button {
            @include button-remove;
            @include flex-width(100%);
            @extend %heading-h6;
            padding: 15px 20px 12px;
            margin-top: 25px;
            border: 1px solid $color-border;
            background-color: $color-background;

            @include media-breakpoint-up(sm) {
                @include flex-width(calc(6/16 * 16/12 * 100%));
                margin-top: 35px;
            }

            @include media-breakpoint-up(xl) {
                @include flex-width(calc(4/16 * 16/12 * 100%));
                margin-top: 50px;
            }
        }
    }

    &__title,
    .msc-choice-summary {
        display: none;
    }

    &__Products .list-unstyled {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;

        > * {
            @include flex-width(calc(6.5/16 * 16/14 * 100%));
            margin-right: calc(1/16 * 16/14 * 100%);
            margin-bottom: calc(1/16 * 16/14 * 100%);

            &:nth-child(2n) {
                margin-right: 0;
            }

            @include media-breakpoint-up(xl) {
                @include flex-width(calc(4/16 * 16/14 * 100%));
                margin-bottom: 0;

                &:nth-child(2n) {
                    margin-right: calc(1/16 * 16/14 * 100%);
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:nth-child(3n-1) {
                    margin-top: calc(0.75/16 * 16/14 * 100%);
                }
            }
        }
    }
}
