// Address Add Button Icon
$margin-right-icon-address-add: 10px;
$margin-bottom-icon-address-add: 3px;
$icon-address-add: $icon-plus;

%icon-address-add {
    @include icon-content($icon-address-add, before);

    &::before {
        margin-right: $margin-right-icon-address-add;
        margin-bottom: $margin-bottom-icon-address-add;
    }
}

// Address Add Button
$max-width-button-address-add__desktop-only: 135px;
$padding-button-address-add: 20px 30px 15px 25px;
$margin-left-button-address-add__desktop-only: 25px;
$margin-top-button-address-add__mobile: 30px;
$border-button-address-add: 1px dashed $color-border;
$text-align-button-address-add__desktop-only: left;

%button-address-add {
    @extend %button;
    @include button-padding($padding-button-address-add);
    @include button-width-full;
    @extend %icon-address-add;
    margin-top: $margin-top-button-address-add__mobile;
    border: $border-button-address-add;

    @include media-breakpoint-up(lg) {
        @include button-width-auto;
        margin-top: 0;
    }
}

// Address List Primary
$width-container-address-list-primary__desktop-only: 170px;

// Address List Other
$width-container-address-list-other__desktop-only: 170px;
$margin-top-container-address-list-other: 30px;
$margin-bottom-container-address-list-other: 25px;
$margin-right-container-address-list-other__desktop-only: 30px;

// Address List Button
$margin-top-button-address-list: 10px;
$margin-left-button-address-list: 10px;

%button-address-list {
    @include button-remove;
    @extend %text-appellation;
    @extend %link;
}

// Address Detail
%text-address-detail {
    @extend %text;
    @extend %text-small;
}

// Address Detail Item
$margin-left-container-address-detail-item: 5px;

// Address Detail Separator
$content-separator-address-detail: ',';

%separator-address-detail {
    content: $content-separator-address-detail;
}

// Address Select Button
%button-address-select {
    @extend %button-primary;

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}

// Address Select Item
$margin-bottom-container-address-select-item: 15px;

// Address Select Radio
$margin-radio-address-select: 4px 10px 0 0;

%radio-address-select {
    margin: $margin-radio-address-select;
}

// Address Form Item
$margin-bottom-container-address-form-item: 10px;

// Address Form Label
%label-address-form {
    @extend %label;
}

// Address Form Input
%input-address-form {
    @extend %input;
}

// Address Form Select
%select-address-form {
    @extend %select;
}

// Address Form Alert
$margin-bottom-alert-address-form: 10px;

%alert-address-form {
    @extend %alert;
    display: block;
    margin-bottom: $margin-bottom-alert-address-form;

    &-danger {
        @extend %alert-danger;
    }

    &-warning {
        @extend %alert-warning;
    }
}

// Address Form Button
$margin-top-button-address-form: 10px;
$margin-right-button-address-form: 10px;

// Address Form Button Save
%button-address-form-save {
    @extend %button-primary;
}

// Address Form Button Cancel
%button-address-form-cancel {
    @extend %button-secondary;
}