.msc-modal {
    @extend %modal;

    &__dialog {
        @extend %modal-dialog;
    }

    &__content {
        @extend %modal-content;
    }

    &__header {
        @extend %modal-header;
    }

    &__body {
        @extend %modal-body;
    }

    &__close-button {
        @extend %modal-close;
    }

    &__backdrop {
        @extend %modal-backdrop;
    }

    &__title,
    &__footer {
        display: none;
    }
}
