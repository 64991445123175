.ms-accordion {
    &:not(:last-child) {
        margin-bottom: $margin-bottom-container-accordion;
    }

    &-header-section {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: $margin-bottom-heading-accordion;

        &__heading {
            text-align: left;
        }

        &-toggle-button-container {
            @extend %text-accordion-toggle;
            text-align: right;

            button {
                @extend %link-accordion-toggle;
            }
        }
    }

    // Accordion Item
    &-item {
        @extend %divider-accordion;
        padding: $padding-container-accordion-item;

        // Accordion Content
        &-content {
            @extend %text-accordion;
            padding: $padding-container-accordion-content;

            > * {
                margin-top: 0 !important;
            }
        }

        &-section {
            @extend %divider-accordion;
            border-width: 0 0 $border-width-divider-accordion-edge;
        }
    }

    // Accordion Button
    .drawer__button {
        @include center-flex(y);
        @extend %button-accordion;
        text-align: left;

        &text {
            flex: 1;
        }

        &[aria-expanded='true'] {
            @extend %icon-accordion-collapse;
        }

        &[aria-expanded='false'] {
            @extend %icon-accordion-expand;
        }

        &::after {
            margin-left: $margin-left-icon-accordion;
        }
    }

    // Extension to hide accordion item when content is empty
    .accordion-item-hide {
        display: none;
    }
}
