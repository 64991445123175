.mailing-list-container > .container-with-header-slots {
    @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    > .ms-content-block {
        @include content-alt-image;
        display: flex;

        @include media-breakpoint-up(md) {
            @include flex-width($width-container-detail-image);
        }
    }

    > .mailing-list {
        width: $width-container-mailing-list-page-content__mobile;
        margin: $margin-container-mailing-list-page-content__mobile;

        @include media-breakpoint-up(sm) {
            width: $width-container-mailing-list-page-content__tablet;
            margin: $margin-container-mailing-list-page-content__tablet;
        }

        @include media-breakpoint-up(md) {
            @include flex-width($width-container-mailing-list-page-content__desktop);
            @include center-flex(x);
            flex-direction: column;
            margin: $margin-container-mailing-list-page-content__desktop;
        }

        @include media-breakpoint-up(xl) {
            @include flex-width($width-container-mailing-list-page-content);
            margin: $margin-container-mailing-list-page-content;
        }

        .mailing-list-form-marketing {
            margin-bottom: $margin-bottom-container-mailing-list-page-marketing;
        }

        .text-input-group {
            margin-bottom: $margin-bottom-input-mailing-list-page;
        }

        input {
            @extend %input-mailing-list-page;
        }

        .input-group-mailing-list-button {
            @extend %button-mailing-list-page;
        }

        .mailing-list-success-message {
            padding: $padding-container-mailing-list-page-success;

            > * {
                @extend %text-mailing-list-page-success;
            }
        }
    }
}
