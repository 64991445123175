$animation-count: 1 !default;
$animation-duration: 0.3s !default;
$animation-delay: 0s !default;
$animation-function: ease !default;
$animation-fill: both;
$animation-visibility: hidden !default;
$animation-transform-origin: center center;
$animation-transform-style: flat;

@mixin animation-count($count: $animation-count) {
    animation-iteration-count: $count;
}

@mixin animation-duration($duration: $animation-duration) {
    animation-duration: $duration;
}

@mixin animation-delay($delay: $animation-delay) {
    animation-delay: $delay;
}

@mixin animation-function($function: $animation-function) {
    animation-timing-function: $function;
}

@mixin animation-fill($fill: $animation-fill) {
    animation-fill-mode: $fill;
}

@mixin animation-visibility($visibility: $animation-visibility) {
    backface-visibility: $visibility;
}

@mixin animation-transform($property) {
    transform: $property;
}

@mixin animation-transform-origin($transform-origin: $animation-transform-origin) {
    transform-origin: $transform-origin;
}

@mixin animation-transform-style($transform-style: $animation-transform-style) {
    transform-style: $transform-style;
}

@mixin animation-name($animation-name) {
    animation-name: $animation-name;
}

@mixin animation-keyframes($animation-name) {
    @keyframes #{$animation-name} {
        @content;
    }
}

// Fade In
@mixin animation-fadeIn(
    $count: $animation-count,
    $duration: $animation-duration,
    $delay: $animation-delay,
    $function: $animation-function,
    $fill: $animation-fill,
    $visibility: $animation-visibility
) {
    @include animation-name(fadeIn);
    @include animation-count($count);
    @include animation-duration($duration);
    @include animation-delay($delay);
    @include animation-function($function);
    @include animation-fill($fill);
    @include animation-visibility($visibility);
}

@include animation-keyframes(fadeIn) {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// Fade Out
@mixin animation-fadeOut(
    $count: $animation-count,
    $duration: $animation-duration,
    $delay: $animation-delay,
    $function: $animation-function,
    $fill: $animation-fill,
    $visibility: $animation-visibility
) {
    @include animation-name(fadeOut);
    @include animation-count($count);
    @include animation-duration($duration);
    @include animation-delay($delay);
    @include animation-function($function);
    @include animation-fill($fill);
    @include animation-visibility($visibility);
}

@include animation-keyframes(fadeOut) {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

// Fade In Up
@mixin animation-fadeInUp(
    $count: $animation-count,
    $duration: $animation-duration,
    $delay: $animation-delay,
    $function: $animation-function,
    $fill: $animation-fill,
    $visibility: $animation-visibility
) {
    @include animation-name(fadeInUp);
    @include animation-count($count);
    @include animation-duration($duration);
    @include animation-delay($delay);
    @include animation-function($function);
    @include animation-fill($fill);
    @include animation-visibility($visibility);
}

@include animation-keyframes(fadeInUp) {
    0% {
        opacity: 0;
        transform: translateY(5px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

// Fade In Down
@mixin animation-fadeInDown(
    $count: $animation-count,
    $duration: $animation-duration,
    $delay: $animation-delay,
    $function: $animation-function,
    $fill: $animation-fill,
    $visibility: $animation-visibility
) {
    @include animation-name(fadeInDown);
    @include animation-count($count);
    @include animation-duration($duration);
    @include animation-delay($delay);
    @include animation-function($function);
    @include animation-fill($fill);
    @include animation-visibility($visibility);
}

@include animation-keyframes(fadeInDown) {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


// Fade In Down Delayed
@mixin animation-fadeInDownDelay(
    $count: $animation-count,
    $duration: $animation-duration,
    $delay: $animation-delay,
    $function: $animation-function,
    $fill: $animation-fill,
    $visibility: $animation-visibility
) {
    @include animation-name(fadeInDownDelay);
    @include animation-count($count);
    @include animation-duration($duration);
    @include animation-delay(0.5s);
    @include animation-function($function);
    @include animation-fill($fill);
    @include animation-visibility($visibility);
}

@include animation-keyframes(fadeInDownDelay) {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

// Fade Out Up
@mixin animation-fadeOutUp(
    $count: $animation-count,
    $duration: $animation-duration,
    $delay: $animation-delay,
    $function: $animation-function,
    $fill: $animation-fill,
    $visibility: $animation-visibility
) {
    @include animation-name(fadeOutUp);
    @include animation-count($count);
    @include animation-duration($duration);
    @include animation-delay($delay);
    @include animation-function($function);
    @include animation-fill($fill);
    @include animation-visibility($visibility);
}

@include animation-keyframes(fadeOutUp) {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}

// Fade In Right
@mixin animation-fadeInRight(
    $count: $animation-count,
    $duration: $animation-duration,
    $delay: $animation-delay,
    $function: $animation-function,
    $fill: $animation-fill,
    $visibility: $animation-visibility
) {
    @include animation-name(fadeInRight);
    @include animation-count($count);
    @include animation-duration($duration);
    @include animation-delay($delay);
    @include animation-function($function);
    @include animation-fill($fill);
    @include animation-visibility($visibility);
}

@include animation-keyframes(fadeInRight) {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

// Fade Out Left
@mixin animation-fadeOutLeft(
    $count: $animation-count,
    $duration: $animation-duration,
    $delay: $animation-delay,
    $function: $animation-function,
    $fill: $animation-fill,
    $visibility: $animation-visibility
) {
    @include animation-name(fadeOutLeft);
    @include animation-count($count);
    @include animation-duration($duration);
    @include animation-delay($delay);
    @include animation-function($function);
    @include animation-fill($fill);
    @include animation-visibility($visibility);
}

@include animation-keyframes(fadeOutLeft) {
    0% {
        opacity: 0;
        transform: translateX(0);
    }

    100% {
        opacity: 1;
        transform: translateX(-100%);
    }
}

// Fade In Translucent
@mixin animation-fadeInTranslucent(
    $count: $animation-count,
    $duration: $animation-duration,
    $delay: $animation-delay,
    $function: $animation-function,
    $fill: $animation-fill,
    $visibility: $animation-visibility
) {
    @include animation-name(fadeInTranslucent);
    @include animation-count($count);
    @include animation-duration($duration);
    @include animation-delay($delay);
    @include animation-function($function);
    @include animation-fill($fill);
    @include animation-visibility($visibility);
}

@include animation-keyframes(fadeInTranslucent) {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

// Fade Out Translucent
@mixin animation-fadeOutTranslucent(
    $count: $animation-count,
    $duration: $animation-duration,
    $delay: $animation-delay,
    $function: $animation-function,
    $fill: $animation-fill,
    $visibility: $animation-visibility
) {
    @include animation-name(fadeOutTranslucent);
    @include animation-count($count);
    @include animation-duration($duration);
    @include animation-delay($delay);
    @include animation-function($function);
    @include animation-fill($fill);
    @include animation-visibility($visibility);
}

@include animation-keyframes(fadeOutTranslucent) {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
}
