// Mailing List Page Image
$width-container-mailing-list-page-image: $width-container-detail-image;

// Mailing List Page Content
$width-container-mailing-list-page-content: $width-container-detail-content;
$width-container-mailing-list-page-content__desktop: $width-container-detail-content__desktop;
$width-container-mailing-list-page-content__tablet: $width-container-detail-content__tablet;
$width-container-mailing-list-page-content__mobile: $width-container-detail-content__mobile;
$margin-container-mailing-list-page-content: $margin-container-detail-content;
$margin-container-mailing-list-page-content__desktop: $margin-container-detail-content__desktop;
$margin-container-mailing-list-page-content__tablet: $margin-container-detail-content__tablet;
$margin-container-mailing-list-page-content__mobile: $margin-container-detail-content__mobile;

// Mailing List Page Marketing
$margin-bottom-container-mailing-list-page-marketing: 35px;

// Mailing List Page Input
$margin-bottom-input-mailing-list-page: 25px;

%input-mailing-list-page {
    @extend %input;
}

// Mailing List Page Button
%button-mailing-list-page {
    @extend %button-primary;

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}

// Mailing List Page Success Message
$padding-container-mailing-list-page-success: 8px 15px;

%text-mailing-list-page-success {
    @extend %heading-h4;
}
