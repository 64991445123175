.ms-cart,
.msc-cart {
    .msc-cart__heading {
        @extend %heading-cart;
        margin-bottom: $margin-bottom-heading-cart;
    }
    .msc-cartline-wraper{
        table{
            width: 100%;
            th{
                font-weight: inherit;
            }
          }
    }
    .msc-cart__empty-cart .msc-cart__btn-backtoshopping {
        @extend %button-cart-shop-empty;
        margin-top: $margin-top-button-cart-shop-empty;
    }

    .cart-lineitems-header {
        @extend %heading-cart-items;
    }

    .cart-lineitems-header,
    .cart-lineitems-container .cart-line-item-product {
        display: flex;

        > td {
            @include center-flex(y);
            justify-content: flex-start;
        }

        .header-items,
        .cart-line-item-product-details {
            width: $width-container-cart-items-product;
        }

        .msc-cart-line__product-title {
            @include center-flex(y);
            @include link-text-decoration(none);
            width: 100%;
        }

        .cart-line-item-product-image {
            @extend .msc-cart-line__product-image;
        }

        .cart-line-item-product-heading {
            flex: 1;
            margin-right: $margin-right-container-cart-items-product;
        }

        .cart-line-item-product-brand {
            @extend .msc-cart-line__brand;
        }

        .cart-line-item-product-name {
            @extend %text-cart-items-product;
        }

        .header-qty {
            width: $width-container-cart-items-quantity__mobile;

            @include media-breakpoint-up(md) {
                width: $width-container-cart-items-quantity;
            }

            .msc-cart-line__quantity__select-menu {
                @extend %select-cart-item-quantity;
                height: $height-select-cart-item-quantity;
                padding: $padding-select-cart-item-quantity;
                margin-right: $margin-right-select-cart-item-quantity__mobile;

                @include media-breakpoint-up(sm) {
                    margin-right: $margin-right-select-cart-item-quantity;
                }
            }

            .msc-cart-line__remove-item {
                @extend %icon-cart-items-remove;
                @include button-remove;
                @include transition;
                opacity: $opacity-icon-cart-items-remove;

                @include hover-all {
                    opacity: $opacity-icon-cart-items-remove--hover;
                }
            }
        }

        .header-unitprice,
        .msc-cart-line__product-price {
            width: $width-container-cart-items-price-unit;

            @include media-breakpoint-down(sm) {
                display: none !important;
            }
        }

        .header-total {
            justify-content: flex-end;
            text-align: end;
        }

        .msc-cart-line__product-price,
        .cart-line-item-price {
            @include center-flex(x);
            flex-direction: column;
            color: $color-text-cart-items-discount;
            font-size: $font-size-text-cart-items-discount;
        }

        .msc-cart-line__product-price {
            align-items: flex-start;
        }

        .cart-line-item-price {
            align-items: flex-end;
            text-align: right;
        }

        .msc-cart-line__product-price .price-actual,
        .cart-line-item-price .msc-cart-price > div > div {
            @extend %text-cart-items-price;
        }
        .cart-line-item-price .msc-cart-price > div {
            color: #767676;
        }

        .header-total,
        .cart-line-item-price {
            width: $width-container-cart-items-price-total__mobile;

            @include media-breakpoint-up(md) {
                width: $width-container-cart-items-price-total;
            }
        }
    }

    .cart-lineitems-container {
        margin: $margin-container-cart-items;

        .cart-line-item {
            padding: $padding-container-cart-item;
        }
    }

    .cart-error,
    .cart-line-item-error {
        @extend %alert-cart-error;
        display: inline-block;
    }

    .cart-error {
        margin-bottom: $margin-bottom-alert-cart-error;
    }

    .cart-line-item-error {
        margin: $margin-alert-cart-item-error;
    }

    .msc-add-to-wishlist {
        display: none;
    }

    .msc-order-summary-wrapper {
        margin: $margin-container-cart-order-summary__mobile;

        @include media-breakpoint-up(lg) {
            width: $width-container-cart-order-summary__desktop-only;
            margin: $margin-container-cart-order-summary;
        }

        .msc-order-summary__heading {
            display: none;
        }

        .msc-order-summary__items > * {
            @include center-flex(y);
            @extend %text-cart-order-summary-items;
            justify-content: space-between;
            margin-bottom: $margin-bottom-container-cart-order-summary-items;
        }

        .msc-order-summary__line-sub-total {
            margin-bottom: $margin-bottom-container-cart-order-summary-net;
        }

        .msc-order-summary__line-sub-total,
        .msc-order-summary__line-total {
            .msc-order-summary__label {
                @extend %text-cart-order-summary-total-label;
            }

            .msc-order-summary__value {
                @extend %text-cart-order-summary-total-value;
            }
        }

        .msc-order-summary__line-total {
            padding-top: $padding-top-container-cart-order-summary-total;
            margin: $margin-container-cart-order-summary-total;
            border-top: $border-top-container-cart-order-summary-total;
        }

        .cart-order-summary-asterix {
            @extend %text-appellation;
            @extend %text-appellation-accent;
        }

        .checkout-btn-wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: $margin-top-button-cart-checkout;

            > * {
                flex: 0 0 100%;

                @include media-breakpoint-up(sm) {
                    flex: none;
                }

                @include media-breakpoint-up(lg) {
                    flex: 0 0 100%;
                }

                @include media-breakpoint-up(xl) {
                    flex: none;
                }
            }
        }

        .msc-cart__btn-backtoshopping {
            @extend %button-cart-shop;
            margin-bottom: $margin-bottom-button-cart-shop;

            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: $margin-bottom-button-cart-shop;
            }

            @include media-breakpoint-up(xl) {
                margin-bottom: 0;
            }
        }

        .msc-cart__btn-checkout {
            @extend %button-cart-checkout;
        }
    }

    .gift-cart-form {
        @extend %text-cart-gift-card;

        label {
            @extend %label-cart-gift-card;
        }

        input,
        textarea {
            @extend %input-cart-gift-card;
        }

        p {
            @extend %text-cart-gift-card-message;
        }
    }
}
