//==============================================================================
// BASE COLORS
//==============================================================================

// Standard
$color-white: #fff;
$color-black: #000;
$color-gallery: #efefef;
$color-silver: #ccc;
$color-dustygray: #999;
$color-dovegray: #666;
$color-codgray: #0a0a0a;
$color-red: #ff0000;
$color-verdungreen: #4a7801;
$color-breakerbay: #53a490;

// Theme
$color-pampas: #f1eee9;
$color-westar: #dfdcd7;
$color-akaroa: #d4c9a2;
$color-husk: #bda550;
$color-driftwood: #987018;
$color-masala: #423838;
$color-mineshaft: #2c2626;
$color-darkbrown: #423737;

//==============================================================================
// COMPONENT COLORS
//==============================================================================

// Border
$color-border: transparentize($color-masala, 0.8);
$color-border-inverse: $color-white;
$color-border-accent: transparentize($color-masala, 0.9);
$color-border-muted: $color-westar;

// Background
$color-background: $color-white;
$color-background-inverse: $color-mineshaft;
$color-background-accent: $color-pampas;
$color-background-page: lighten($color-background-accent, 5);

// Text
$color-text: $color-mineshaft;
$color-text-inverse: $color-white;
$color-text-accent: transparentize($color-mineshaft, 0.5);
$color-text-muted: transparentize($color-mineshaft, 0.7);

$color-text-featured: $color-text;
$color-text-featured-inverse: $color-text-inverse;
$color-text-featured-accent: $color-text-accent;
$color-text-featured-muted: $color-text-muted;

$color-text-appellation: $color-text;
$color-text-appellation-inverse: $color-text-inverse;
$color-text-appellation-accent: $color-text-accent;
$color-text-appellation-muted: $color-text-muted;

// Heading
$color-heading: $color-mineshaft;
$color-heading-inverse: $color-white;
$color-heading-accent: transparentize($color-mineshaft, 0.7);
$color-heading-muted: transparentize($color-mineshaft, 0.7);

// Link
$color-link: $color-mineshaft;
$color-link--hover: $color-link;

$color-link-inverse: $color-white;
$color-link-inverse--hover: $color-link-inverse;

$color-link-accent: transparentize($color-mineshaft, 0.5);
$color-link-accent--hover: $color-mineshaft;

$color-link-muted: transparentize($color-mineshaft, 0.7);
$color-link-muted--hover: $color-mineshaft;

// Button
$color-button__border: $color-mineshaft;
$color-button__background: transparent;
$color-button__text: $color-mineshaft;
$color-button__border--hover: $color-button__border;
$color-button__background--hover: transparent;
$color-button__text--hover: $color-button__text;

$color-button-inverse__border: $color-white;
$color-button-inverse__background: transparent;
$color-button-inverse__text: $color-white;
$color-button-inverse__border--hover: $color-button-inverse__border;
$color-button-inverse__background--hover: transparent;
$color-button-inverse__text--hover: $color-button-inverse__text;

$color-button-disabled__border: transparentize($color-mineshaft, 0.7);
$color-button-disabled__background: transparent;
$color-button-disabled__text: transparentize($color-mineshaft, 0.7);

$color-button-primary__border: $color-driftwood;
$color-button-primary__background: $color-driftwood;
$color-button-primary__text: $color-white;
$color-button-primary__border--hover: $color-driftwood;
$color-button-primary__background--hover: $color-driftwood;
$color-button-primary__text--hover: $color-white;

$color-button-primary-inverse__border: $color-button-primary__border;
$color-button-primary-inverse__background: $color-button-primary__background;
$color-button-primary-inverse__text: $color-button-primary__text;
$color-button-primary-inverse__border--hover: $color-button-primary__border--hover;
$color-button-primary-inverse__background--hover: $color-button-primary__background--hover;
$color-button-primary-inverse__text--hover: $color-button-primary__text--hover;

$color-button-primary-disabled__border: $color-akaroa;
$color-button-primary-disabled__background: $color-akaroa;
$color-button-primary-disabled__text: transparentize($color-white, 0.25);

$color-button-secondary__border: $color-black;
$color-button-secondary__background: transparent;
$color-button-secondary__text: $color-black;
$color-button-secondary__border--hover: $color-black;
$color-button-secondary__background--hover: transparent;
$color-button-secondary__text--hover: $color-black;

$color-button-secondary-inverse__border: $color-white;
$color-button-secondary-inverse__background: transparent;
$color-button-secondary-inverse__text: $color-white;
$color-button-secondary-inverse__border--hover: $color-white;
$color-button-secondary-inverse__background--hover: transparent;
$color-button-secondary-inverse__text--hover: $color-white;

$color-button-secondary-disabled__border: $color-akaroa;
$color-button-secondary-disabled__background: transparent;
$color-button-secondary-disabled__text: $color-akaroa;

// Input
$color-input__border: transparentize($color-mineshaft, 0.8);
$color-input__background: transparent;
$color-input__text: $color-mineshaft;
$color-input__border--active: transparentize($color-mineshaft, 0.4);
$color-input__background--active: transparent;
$color-input__text--active: $color-input__text;
$color-input__border--error: $color-red;
$color-input__background--error: transparent;
$color-input__text--error: $color-input__text;
