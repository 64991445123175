@mixin center-absolute($axis: both) {
    position: absolute;

    @if ($axis == both) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } @else if ($axis == x) {
        left: 50%;
        transform: translate(-50%, 0);
    } @else if ($axis == y) {
        top: 50%;
        transform: translate(0, -50%);
    }
}

@mixin center-flex($axis: both) {
    display: flex;

    @if ($axis == both) {
        justify-content: center;
        align-items: center;
    } @else if ($axis == x) {
        justify-content: center;
    } @else if ($axis == y) {
        align-items: center;
    }
}
