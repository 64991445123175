.ms-account-profile-edit {
    &__heading {
        @extend %heading-b2c;
    }

    &__save-button {
        @extend %button-b2c-primary;
        margin-top: $margin-y-button-b2c;
    }

    &__cancel-button {
        @extend %button-b2c-secondary;
    }
}
