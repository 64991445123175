.sku-selector {
    @include media-breakpoint-down(xs) {
        display: flex;
        justify-content: space-between;
        position: fixed;
        z-index: $z-index-container-sku-selector__mobile;
        left: 0;
        right: 0;
        bottom: 0;
        height: $height-container-sku-selector__mobile;
        background-color: $color-background-container-sku-selector__mobile;
        box-shadow: $box-shadow-container-sku-selector__mobile;
    }

    @include media-breakpoint-up(sm) {
        margin-top: $margin-top-container-sku-selector__desktop-only;
    }

    &__price {
        @extend %text-sku-selector-price;
        display: flex;

        @include media-breakpoint-down(xs) {
            position: relative;
            align-items: center;
            flex: 1;
            padding: $padding-container-sku-selector-price__mobile;
            border-top: $border-top-container-sku-selector-price__mobile;
        }

        @include media-breakpoint-up(sm) {
            margin-bottom: $margin-bottom-container-sku-selector-price__tablet;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: $margin-bottom-container-sku-selector-price;
        }

        &-default {
            @extend %text-sku-selector-price-default;
        }

        &-club {
            display: flex;
            margin-left: $margin-left-text-sku-selector-price-club__mobile;

            @include media-breakpoint-up(sm) {
                margin-left: $margin-left-text-sku-selector-price-club;
            }

            .product-price {
                margin-right: $margin-right-text-sku-selector-price-club;
            }
        }

        .product-price.keyed-in {
            position: relative;

            @include media-breakpoint-down(xs) {
                position: absolute;
                top: $top-input-sku-selector-price__mobile;
                left: 0;
                width: 100%;
            }

            .product-price::before {
                @extend %prefix-sku-selector-price;
                position: absolute;
                top: $top-prefix-input-sku-selector-price__mobile;
                left: $left-prefix-input-sku-selector-price;

                @include media-breakpoint-up(sm) {
                    top: $top-prefix-input-sku-selector-price;
                }
            }

            input {
                @extend %input-sku-selector-price;

                @include media-breakpoint-down(xs) {
                    border: 0;
                }

                @include media-breakpoint-up(sm) {
                    width: $width-input-sku-selector-price;
                }
            }
        }
    }

    &__cart {
        display: flex;

        @include media-breakpoint-up(sm) {
            position: relative;
        }

        > * {
            display: flex;
        }

        .msc-add-to-cart,
        .msc-quantity-input,
        .ms-buybox__quantity__select-menu {
            height: 100%;
        }

        &-quantity {
            label {
                display: none;
            }

            .ms-buybox__quantity {
                z-index: 1;
            }

            .msc-quantity-input,
            .ms-buybox__quantity__select-menu {
                @extend %input-sku-selector;
                width: $width-input-sku-selector__mobile;
                border-right: 0;

                @include media-breakpoint-down(xs) {
                    border-bottom: 0;
                }

                @include media-breakpoint-up(sm) {
                    width: $width-input-sku-selector;
                }
            }

            .ms-buybox__quantity__select-menu {
                padding-right: 0;
            }
        }

        &-button {
            .msc-add-to-cart {
                @extend %button-sku-selector-cart;

                @include media-breakpoint-down(xs) {
                    &:not(.disabled):not(:disabled) {
                        @include button-sku-selector-cart;
                    }

                    &.disabled,
                    &:disabled {
                        @include button-sku-selector-cart-disabled;
                    }
                }
            }
        }

        .msc-alert {
            @extend %alert-sku-selector;
            position: absolute;
            z-index: 5;
            bottom: calc(100% + 10px);
            left: $x-alert-sku-selector__mobile;
            right: $x-alert-sku-selector__mobile;

            @include media-breakpoint-up(sm) {
                top: $top-alert-sku-selector;
                bottom: unset;
                left: 0;
            }

            &-danger {
                @extend %alert-sku-selector-danger;
            }
        }
    }
}
