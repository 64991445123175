.msc-address-detail {
    @extend %text-address-detail;
}

.msc-address-detail__item-newline,
.msc-address-detail__item-address-detail_Phone {
    display: block;
}

.msc-address-detail__item-city::after {
    @extend %separator-address-detail;
}

.msc-address-detail__item-state {
    margin-left: $margin-left-container-address-detail-item;
}

.msc-address-detail__item-empty,
.msc-address-detail__item-county,
.msc-address-detail__item-phone-label {
    display: none;
}
