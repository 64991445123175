// How to Buy
$width-container-howtobuy: $width-content-lg;
$width-container-howtobuy__tablet: $width-container-howtobuy;
$width-container-howtobuy__mobile: $width-container-howtobuy;
$margin-container-howtobuy: 100px auto;
$margin-container-howtobuy__tablet: 75px auto;
$margin-container-howtobuy__mobile: 35px auto;
$color-background-container-howtobuy: $color-background-accent;
$text-align-container-howtobuy: center;

// How to Buy Input
$width-input-howtobuy__mobile: 100%;
$padding-right-input-howtobuy: 50px;
$margin-input-howtobuy: 0 auto;

// How to Buy Input Button
$size-howtobuy-button: 50px;
$icon-howtobuy-button: $asset-icon-submit;
$width-icon-howtobuy-button: 14px;
$height-icon-howtobuy-button: 12px;

%icon-howtobuy-button {
    @include icon-image($icon-howtobuy-button, before, $width-icon-howtobuy-button, $height-icon-howtobuy-button);

    &-disabled {
        opacity: 0.5;
    }
}

// How to Buy Results
$width-container-howtobuy-results: $width-content-md;
$width-container-howtobuy-results__tablet: $width-content-lg;
$width-container-howtobuy-results__mobile: $width-content-lg;

// How to Buy Results Item
$width-container-howtobuy-results-item: calc(1/4 * 100%);
$width-container-howtobuy-results-item__tablet: calc(1/2 * 100%);

// How to Buy Banner
$padding-top-container-howtobuy-banner: 70px;
$padding-top-container-howtobuy-banner__tablet: 30px;
$padding-top-container-howtobuy-banner__mobile: 25px;
$padding-bottom-container-howtobuy-banner: 50px;
$padding-bottom-container-howtobuy-banner__tablet: 25px;
$padding-bottom-container-howtobuy-banner__mobile: 20px;
$padding-x-container-howtobuy-banner: calc(3/16 * 100%);
$padding-x-container-howtobuy-banner__tablet: calc(2/16 * 100%);
$padding-x-container-howtobuy-banner__mobile: calc(1/16 * 100%);

// How to Buy Banner Heading
$margin-bottom-heading-howtobuy-banner: 35px;
$margin-bottom-heading-howtobuy-banner__mobile: 25px;

// How to Buy Banner Input
$width-input-howtobuy-banner: calc(4/8 * 100%);
$width-input-howtobuy-banner__tablet: calc(6/10 * 100%);

// More Ways to Shop
$padding-y-container-howtobuy-moreways: 70px;
$padding-y-container-howtobuy-moreways__tablet: 30px;
$padding-y-container-howtobuy-moreways__mobile: 25px;
$padding-x-container-howtobuy-moreways: calc(4/16 * 100%);
$padding-x-container-howtobuy-moreways__tablet: calc(3/16 * 100%);
$padding-x-container-howtobuy-moreways__mobile: calc(1/16 * 100%);

// More Ways to Shop Heading
$margin-bottom-heading-howtobuy-moreways: 30px;
$margin-bottom-heading-howtobuy-moreways__mobile: 25px;

// More Ways to Shop Delivery Item
$width-container-howtobuy-moreways-delivery-item: calc(1.25/6 * 100%);
$margin-x-container-howtobuy-moreways-delivery-item: calc((1/3)/6 * 100%);

// More Ways to Shop Delivery Item Image
$max-height-image-howtobuy-moreways-delivery-item: 110px;
$max-height-image-howtobuy-moreways-delivery-item__tablet: 80px;
$max-height-image-howtobuy-moreways-delivery-item__mobile: 60px;

// More Ways to Shop Local
$margin-top-container-howtobuy-moreways-local: 50px;
$margin-top-container-howtobuy-moreways-local__tablet: 40px;
$margin-top-container-howtobuy-moreways-local__mobile: 25px;

// More Ways to Shop Input
$width-input-howtobuy-moreways: calc(4/6 * 100%);
$width-input-howtobuy-moreways__tablet: calc(6/8 * 100%);

// How to Buy Page
$width-container-howtobuy-container: $width-content-md;
$width-container-howtobuy-container__tablet: $width-content-lg;
$width-container-howtobuy-container__mobile: $width-content-lg;
$padding-container-howtobuy-container: 100px 0;
$padding-container-howtobuy-container__tablet: 50px 0;
$padding-container-howtobuy-container__mobile: 35px 0;
$color-background-container-howtobuy-container: $color-background-accent;

// How to Buy Page Heading
$margin-bottom-heading-howtobuy-container: 35px;
$margin-bottom-heading-howtobuy-container__mobile: 25px;

%heading-howtobuy-container {
    @extend %heading-h1;
}

// How to Buy Page Query
$max-width-container-howtobuy-container-query: 775px;

// How to Buy Page Input
$width-input-howtobuy-container: 175px;
$margin-right-input-howtobuy-container: 20px;
$margin-right-input-howtobuy-container__tablet: 15px;
$margin-bottom-input-howtobuy-container__mobile: 15px;

// How to Buy Page Filters
$margin-top-container-howtobuy-container-filters: 15px;

// How to Buy Page Delivery
$margin-bottom-heading-howtobuy-delivery: 50px;
$margin-bottom-heading-howtobuy-delivery__mobile: 30px;

%heading-howtobuy-delivery {
    @extend %heading-h4;
}
