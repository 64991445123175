// Order Details Text
%text-order-details {
    @extend %text;
    @extend %text-small;
}

// Order Details Heading
%heading-order-details {
    @extend %heading-h6;
}

// Order Details Button
$margin-top-button-order-details: 5px;

%button-order-details {
    @extend %button;
    @extend %button-underlined;

    &:disabled,
    &.disabled {
        @extend %button-disabled;
    }
}

// Order Details Separator
$content-separator-order-details: '/';
$margin-separator-order-details: 0 10px;
$color-separator-order-details: $color-text-muted;

// Order Details Section
$margin-bottom-container-order-details-section__mobile: 30px;

// Order Details Information
$width-container-order-details-information: 20%;
$width-container-order-details-information__tablet: 30%;
$margin-right-container-order-details-information__tablet: 5%;

// Order Details Groups
$width-container-order-details-groups__desktop-only: 27%;

// Order Details Delivery Items
$margin-right-text-order-details-delivery-items: 5px;

%text-order-details-delivery-items {
    @extend %text-appellation;
    @extend %text-appellation-accent;
}

// Order Details Sales Lines
$margin-top-container-order-details-sales-lines: 25px;
$margin-left-container-order-details-sales-lines: 50px;

// Order Details Product Title
$margin-bottom-heading-order-details-product-title: 5px;

%heading-order-details-product-title {
    @extend %heading-h5;
}

// Order Details Sales Status
$margin-text-order-details-sales-status: 5px 0 20px;

%text-order-details-sales-status {
    @extend %text-accent;
}

// Order Details Summary
$width-container-order-details-summary: 20%;
$width-container-order-details-summary__tablet: 30%;
$max-width-container-order-details-summary: 220px;
$margin-right-container-order-details-summary: 5%;

// Order Details Summary Total
$font-weight-text-order-details-summary-total: $font-weight-bold;

%text-order-details-summary-total {
    @include text-font-weight($font-weight-text-order-details-summary-total);
}

// Order Details Payment
$width-container-order-details-payment: 18%;
$width-container-order-details-payment__tablet: 30%;
$margin-right-container-order-details-payment__desktop-only: 5%;
