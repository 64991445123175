.find-wine {
    &-tab {
        margin-bottom: $margin-bottom-container-wine-finder-tabs__mobile;

        @include media-breakpoint-up(sm) {
            margin-bottom: $margin-bottom-container-wine-finder-tabs;
        }

        > * {
            @extend %button-wine-finder-tab;
            margin-right: $margin-right-button-wine-finder-tab__mobile;

            @include media-breakpoint-up(sm) {
                margin-right: $margin-right-button-wine-finder-tab;
            }

            &.active {
                @extend %button-wine-finder-tab-active;
            }
        }
    }

    &-query,
    &-query-int {
        &.active {
            @include animation-fadeIn;
            display: flex ;
        }

        &:not(.active) {
            display: none !important;
        }

        &-title,
        &-country {
            display: none;
        }

        &-location-input {
            @extend %input-wine-finder;
        }

        &-dropdown,
        &-choose-wine-control {
            @extend %select-wine-finder;
        }

        &-search-button {
            @extend %button-wine-finder;
        }

        &-search-filters {
            display: flex;

            > * {
                margin-right: $margin-right-container-wine-finder-filter;

                &:last-child {
                    margin-right: 0;
                }
            }

            label {
                margin-bottom: 0;

                > span {
                    @extend %label-wine-finder-filter;
                }
            }
        }
    }

    &-result,
    &-no-result {
        &-back-link,
        &-show-all {
            @extend %text-wine-finder-results;
        }

        &-back-link {
            float: left;
        }

        &-show-all {
            float: right;
        }
    }

    &-no-result-search-again {
        @extend %button-primary;
    }

    &-results {
        clear: both;
        padding-top: $padding-top-container-wine-finder-results__mobile;
        text-align: $text-align-container-wine-finder-results;

        @include media-breakpoint-up(sm) {
            padding-top: $padding-top-container-wine-finder-results__tablet;
        }

        @include media-breakpoint-up(xl) {
            padding-top: $padding-top-container-wine-finder-results;
        }

        &-page {
            border-top: $border-top-container-wine-finder-results;

            @include media-breakpoint-up(sm) {
                display: flex;
                flex-wrap: wrap;
            }

            > * {
                @include border(
                    $border-width-container-wine-finder-results-item,
                    $border-style-container-wine-finder-results-item,
                    $color-border-container-wine-finder-results-item
                );
                padding: $padding-container-wine-finder-results-item__mobile;
                margin-top: $margin-top-container-wine-finder-results-item;

                @include media-breakpoint-up(sm) {
                    @include flex-width($width-container-wine-finder-results-item__tablet);
                    padding: $padding-container-wine-finder-results-item__tablet;
                    padding-left: 0;
                }

                @include media-breakpoint-up(xl) {
                    @include flex-width($width-container-wine-finder-results-item);
                    padding: $padding-container-wine-finder-results-item;
                    padding-left: 0;
                }
            }
        }

        &-page-info {
            @include flex-width($width-container-wine-finder-pages);
            padding: $padding-container-wine-finder-pages__mobile;
            border-top: $border-top-container-wine-finder-pages;
            border-bottom: none;
            text-align: $text-align-container-wine-finder-pages;

            @include media-breakpoint-up(sm) {
                padding: $padding-container-wine-finder-pages__tablet;
            }

            @include media-breakpoint-up(xl) {
                padding: $padding-container-wine-finder-pages;
            }
        }

        &-page-info-border-page-count {
            display: none;
        }
    }

    &-pager {
        @extend %text-wine-finder-page;
        display: inline-flex;

        > * {
            padding: $padding-text-wine-finder-page__mobile-small;

            @include media-breakpoint-up(xs) {
                padding: $padding-text-wine-finder-page__mobile;
            }

            @include media-breakpoint-up(sm) {
                padding: $padding-text-wine-finder-page;
            }
        }

        &-page {
            border-bottom: $border-bottom-text-wine-finder-page;

            &-active {
                @extend %text-wine-finder-page-active;
            }
            &:focus {
                border: 1px solid black;
            }

            &-back,
            &-forward {
                &:not(.find-wine-pager-disabled) {
                    &:focus {
                        border: 1px solid black;
                    }
                }
            }
        }

        &-disabled {
            @extend %text-wine-finder-page-disabled;
        }
    }

    &-display-item-domestic,
    &-display-item-int {
        @extend %text-wine-finder-results-item;

        &-title {
            @extend %heading-wine-finder-results-item;
        }

        &-location {
            line-height: $line-height-text-wine-finder-results-item-location;
        }

        &-group {
            display: flex;
            flex-wrap: wrap;
        }

        &-distance {
            margin-right: $margin-right-text-wine-finder-results-item-distance;
        }
    }

    &-wine-display {
        margin-top: $margin-top-container-wine-finder-results-item-products;

        &-toggle {
            @extend %button-wine-finder-results-item-products;
        }

        &-wines {
            @extend %text-wine-finder-results-item-products;
        }
    }
}
