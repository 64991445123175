.promo-code-wrap,
.msc-promo-code {
    padding: $padding-container-promo-code__mobile;
    margin-top: $margin-top-container-promo-code__mobile;
    background-color: $color-background-container-promo-code;

    @include media-breakpoint-up(sm) {
        padding: $padding-container-promo-code__tablet;
        margin-top: $margin-top-container-promo-code;
    }

    @include media-breakpoint-up(lg) {
        padding: $padding-container-promo-code__desktop;
    }

    @include media-breakpoint-up(xl) {
        padding: $padding-container-promo-code;
    }

    &-heading {
        @extend %heading-promo-code;
        margin-bottom: $margin-bottom-heading-promo-code;
    }

    &__input-box {
        @extend %input-promo-code;
    }

    &__apply-btn {
        @extend %button-promo-code;
        margin-top: $margin-top-button-promo-code;
    }

    .msc-alert-danger {
        @extend %alert-promo-code-error;
        margin-top: $margin-top-alert-promo-code-error;
    }

    &__applied {
        margin-top: $margin-top-container-promo-code-applied__mobile;

        @include media-breakpoint-up(sm) {
            margin-top: $margin-top-container-promo-code-applied__tablet;
        }

        @include media-breakpoint-up(xl) {
            margin-top: $margin-top-container-promo-code-applied;
        }

        &-heading {
            @extend %heading-promo-code-applied;
            margin-bottom: $margin-bottom-heading-promo-code-applied;
        }

        &-subheading {
            @extend %text-promo-code-applied-sub;
            margin-bottom: $margin-bottom-text-promo-code-applied-sub;
        }
    }

    &__line {
        &-container {
            @extend %text-promo-code-applied-line;
            display: flex;
            justify-content: space-between;
        }

        &-value-price {
            @extend %text-promo-code-applied-line-price;
            margin-left: $margin-left-text-promo-code-applied-line-price;
        }

        &__btn-remove {
            @extend %button-promo-code-applied-remove;
        }
    }
}
