// Buybox Media Gallery
$width-container-buybox-media-gallery__desktop-only: calc(8/16 * 100%);
$height-container-buybox-media-gallery: calc(100vh - #{$height-container-header});
$height-container-buybox-media-gallery__tablet: calc(100vh - #{$height-container-header__tablet});
$min-height-container-buybox-media-gallery: 600px;
$max-height-container-buybox-media-gallery: 950px;
$margin-bottom-container-buybox-media-gallery__tablet: 75px;

// Buybox Content
$width-container-buybox-content__desktop-only: calc(8/16 * 100%);
$padding-container-buybox-content: 45px calc(1.5/16 * 100%);
$padding-container-buybox-content__desktop: 45px calc(1/16 * 100%);
$padding-container-buybox-content__tablet: 55px calc(1/16 * 100%);
$padding-container-buybox-content__mobile: 25px calc(1/16 * 100%);

// Buybox Appellation
$margin-bottom-heading-buybox-appellation: 15px;
$margin-bottom-heading-buybox-appellation__tablet: 10px;
$margin-bottom-heading-buybox-appellation__mobile: 5px;

%heading-buybox-appellation {
    @extend %heading-h6;
}

// Buybox Title
$margin-bottom-heading-buybox-title: 15px;
$margin-bottom-heading-buybox-title__mobile: 10px;

// Buybox Sku Title
$margin-bottom-text-buybox-sku-title: 30px;
$margin-bottom-text-buybox-sku-title__mobile: 20px;

// Buybox Description
$margin-bottom-text-buybox-description: 35px;
$margin-bottom-text-buybox-description__mobile: 25px;

// Buybox Message
$z-index-text-buybox-message__mobile: 10;
$bottom-text-buybox-message__mobile: $height-container-sku-selector__mobile;
$padding-text-buybox-message__mobile: 8px 10px 2px;
$margin-top-text-buybox-message: 10px;
$color-background-text-buybox-message__mobile: transparentize($color-background, 0.05);
$text-align-text-buybox-message__mobile: right;

%text-buyboy-message {
    @extend %text;
    @extend %text-small;
}
