// Add to Cart by URL Button
$margin-top-button-add-to-cart: 25px;

%button-add-to-cart {
    @extend %button-primary;

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}
