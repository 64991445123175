$fa-font-path: 'https://use.fontawesome.com/releases/v6.6.0/webfonts' !default;
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/brands';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';

$icon-styles-map: (
    solid: fas,
    regular: far,
    brands: fab
);

@function icon-style($style) {
    $fa-code: map-get($icon-styles-map, $style);
    @return $fa-code;
}

@mixin icon-font($style: solid) {
    @extend .#{icon-style($style)};
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

@mixin icon-content(
    $icon,
    $selector: before,
    $style: solid
) {
    &::#{$selector} {
        @extend %fa-icon;
        @include icon-font($style);
        content: fa-content($icon);
    }
}

@mixin icon-image(
    $image,
    $selector: before,
    $width: 20px,
    $height: $width
) {
    &::#{$selector} {
        content: '';
        display: block;
        width: $width;
        height: $height;
        background-image: $image;
        background-repeat: no-repeat;
        background-size: $width $height;
    }
}

// Site Essentials
$icon-settings: $fa-var-cog;
$icon-nav: $fa-var-bars;
$icon-signin: $fa-var-sign-in-alt;
$icon-signout: $fa-var-sign-out-alt;
$icon-user: $fa-var-user;
$icon-contact: $fa-var-id-card;
$icon-cart: $fa-var-shopping-cart;
$icon-phone: $fa-var-phone;
$icon-address: $fa-var-map-marker-alt;
$icon-location: $fa-var-map-pin;
$icon-store: $fa-var-store-alt;
$icon-truck: $fa-var-truck;
$icon-filter: $fa-var-filter;
$icon-search: $fa-var-search;
$icon-history: $fa-var-history;
$icon-file: $fa-var-file-alt;
$icon-show: $fa-var-eye;
$icon-hide: $fa-var-eye-slash;

// Statuses
$icon-error: $fa-var-exclamation-circle;
$icon-warning: $fa-var-exclamation-triangle;
$icon-loading: $fa-var-spinner;

// Arrows
$icon-chevron-up: $fa-var-chevron-up;
$icon-chevron-down: $fa-var-chevron-down;
$icon-chevron-left: $fa-var-chevron-left;
$icon-chevron-right: $fa-var-chevron-right;
$icon-arrow-up: $fa-var-arrow-up;
$icon-arrow-down: $fa-var-arrow-down;
$icon-arrow-left: $fa-var-arrow-left;
$icon-arrow-right: $fa-var-arrow-right;

// Lists
$icon-list-bullet: $fa-var-circle;

// Actions
$icon-plus: $fa-var-plus;
$icon-plus-circle: $fa-var-plus-circle;
$icon-minus: $fa-var-minus;
$icon-minus-circle: $fa-var-minus-circle;
$icon-times: $fa-var-times;
$icon-times-circle: $fa-var-times-circle;
$icon-add: $icon-plus;
$icon-remove: $icon-minus;
$icon-edit: $fa-var-pencil-alt;
$icon-delete: $fa-var-trash-alt;
$icon-save: $fa-var-save;
$icon-cancel: $icon-times;
$icon-close: $icon-times;
$icon-more: $fa-var-ellipsis-h;

// Form Controls
$icon-selected: $fa-var-check;
$icon-checkbox: $fa-var-square;
$icon-checkbox-selected: $fa-var-check-square;
$icon-radiobutton: $fa-var-circle;
$icon-radiobutton-selected: $fa-var-dot-circle;
$icon-toggle-off: $fa-var-toggle-off;
$icon-toggle-on: $fa-var-toggle-on;

// Video Controls
$icon-play: $fa-var-play;
$icon-pause: $fa-var-pause;
$icon-mute: $fa-var-volume-mute;
$icon-unmute: $fa-var-volume-up;
$icon-fullscreen: $fa-var-expand;
$icon-normalscreen: $fa-var-compress;
$icon-closedcaption: $fa-var-closed-captioning;

// Customer Responses
$icon-like: $fa-var-thumbs-up;
$icon-dislike: $fa-var-thumbs-down;
$icon-heart: $fa-var-heart;
$icon-star: $fa-var-star;
$icon-star-half: $fa-var-star-half-alt;
$icon-comment: $fa-var-comment-alt;

// Social Media
$icon-facebook: $fa-var-facebook-f;
$icon-instagram: $fa-var-instagram;
$icon-twitter: $fa-var-twitter;
