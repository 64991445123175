.ms-order-history {
    &__alert {
        display: block;
    }

    &__header {
        display: none;
    }

    &__empty-message {
        margin-bottom: 0;
    }

    &__btn-keep-shopping,
    &__btn-order-details {
        @extend %button-order-history;
        margin-top: $margin-top-button-order-history;
    }

    &__sales-order {
        @extend %text-order-history-order;
        padding-bottom: $padding-bottom-container-order-history-order;
        margin-bottom: $margin-bottom-container-order-history-order;
        border-bottom: $border-bottom-container-order-history-order;

        &:last-child {
            padding: 0;
            margin-bottom: 0;
            border-bottom: 0;
        }
    }

    &__order-information {
        &-channel-name {
            @extend %text-order-history-channel;

            @include media-breakpoint-up(md) {
                float: right;
                margin-top: $margin-top-text-order-history-channel;
            }
        }

        &-sales-id {
            @extend %text-order-history-sales-id;
            margin-bottom: $margin-bottom-text-order-history-sales-id;
        }

        &-sales-id,
        &-channel-reference-id {
            display: block;
        }

        &-created-date,
        &-count {
            &::after {
                content: $content-separator-order-history;
                margin: $margin-separator-order-history;
                color: $color-separator-order-history;
            }
        }
    }

    &__groups {
        margin-top: $margin-top-container-order-history-groups;
    }

    &__devliery-type {
        @extend %text-order-history-delivery-type;
    }

    &__group-delivery {
        &-heading {
            margin-right: $margin-right-heading-order-history-delivery;
        }

        &-processing-label {
            float: right;
            color: $color-text-order-history-delivery-processing;
        }
    }

    &__address {
        &-header {
            display: none;
        }

        &-name,
        &-full-address {
            display: block;
        }
    }

    &__tracking-info {
        display: block;
    }

    &__sales-lines {
        display: none;
    }
}
