.ms-order-details__wine-club {
    @include text-font-size(14px);
    margin-top: 10px;

    &-label {
        @include text-font-weight($font-weight-bold);
        margin-right: 4px;
    }

    &-pickup {
        .ms-order-details__address-full-address,
        .ms-order-details__tracking-info {
            display: none;
        }
    }

    &-group {
        .msc-cart-line__product-image,
        .msc-cart-line__quantity,
        .ms-order-details__sales-line-btn-buy-it-again {
            display: none;
        }

        .ms-order-details__sales-status {
            margin-left: 0;
        }

        .msc-cart-line__product-title {
            pointer-events: none;
        }
    }
}
