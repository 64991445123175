// Account Heading
$margin-bottom-heading-account: 25px;
$margin-bottom-heading-account__mobile: 20px;

%heading-account {
    @include button-remove;
    @extend %heading-h3;
    @include center-flex(y);
    justify-content: space-between;
    margin-bottom: $margin-bottom-heading-account;
    text-align: left;
}

// Account Heading Icon
$margin-left-icon-account-heading: 10px;
$icon-account-heading-expand: $asset-icon-dropdown-expand;
$icon-account-heading-collapse: $asset-icon-dropdown-collapse;
$width-icon-account-heading: 14px;
$height-icon-account-heading: 9px;

%icon-account-heading {
    @include transition;

    &-expand {
        @include icon-image($icon-account-heading-expand, after, $width-icon-account-heading, $height-icon-account-heading);
    }

    &-collapse {
        @include icon-image($icon-account-heading-collapse, after, $width-icon-account-heading, $height-icon-account-heading);
    }

    &-expand,
    &-collapse {
        @extend %icon-account-heading;
    }

    &::after {
        margin-left: $margin-left-icon-account-heading;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}

// Account Left Column
$width-container-account-left__desktop-only: calc(6/16 * 16/14 * 100%);
$margin-right-container-account-left__desktop-only: calc(1/16 * 16/14 * 100%);

// Account Right Column
$width-container-account-right__desktop-only: calc(7/16 * 16/14 * 100%);
$padding-container-account-right__desktop-only: 55px calc(0.75/16 * 100%);
$color-background-container-account-right__desktop-only: $color-background-accent;
