.event-schedule {
    @extend %text;
    @extend %text-small;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    font-weight: $font-weight-bold;

    > *:not(:last-child)::after {
        @extend %text-muted;
        content: '|';
        margin: 0 10px;
        font-weight: $font-weight-normal;
    }
}
