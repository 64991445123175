.ms-password-reset {
    &__heading {
        @extend %heading-b2c;
    }

    &__continue-button {
        @extend %button-b2c-primary;
        margin-top: $margin-y-button-b2c;
    }

    &__cancel-button {
        @extend %button-b2c-secondary;
    }
}

.password-reset-requirements div {
    padding: revert;
}
