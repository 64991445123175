.account {
    @extend %width-shop;
    @extend %width-shop-with-header;

    .container-with-header-text{
        p,a{
            color: #797676 !important;
            opacity: 1;
        }
    }

    > .container-with-header-slots {
        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        > * {
            // Left column
            &:first-child {
                @include media-breakpoint-up(md) {
                    @include flex-width($width-container-account-left__desktop-only);
                    margin-right: $margin-right-container-account-left__desktop-only;
                }
            }

            // Right column
            &:nth-child(2) {
                @include media-breakpoint-up(md) {
                    @include flex-width($width-container-account-right__desktop-only);
                    padding: $padding-container-account-right__desktop-only;
                    background-color: $color-background-container-account-right__desktop-only;
                }
            }
        }
    }

    .overflow-container {
        &-btn {
            @extend %heading-account;
            margin-bottom: $margin-bottom-heading-account__mobile;

            @include media-breakpoint-up(md) {
                margin-bottom: $margin-bottom-heading-account;
                pointer-events: none;
            }
        }

        &-opened {
            .overflow-container-btn {
                @extend %icon-account-heading-collapse;
            }

            .overflow-container-content {
                @include media-breakpoint-down(md) {
                    @include animation-fadeIn;
                }
            }
        }

        &-closed {
            .overflow-container-btn {
                @extend %icon-account-heading-expand;
            }

            .overflow-container-content {
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }
    }
}
