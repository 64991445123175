.club-newsletter {
    .ms-content-block__title {
        @include media-breakpoint-up(lg) {
            margin-right: 40px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 50px;
        }
    }

    .ms-content-block__cta > * {
        @extend %icon-product-attribute-download;
        @include center-flex;
        display: inline-flex;

        @include media-breakpoint-up(sm) {
            margin-right: 30px;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 40px;
        }

        @include media-breakpoint-up(xl) {
            margin-right: 50px;
        }

        &:last-child {
            margin-right: 0;
        }

        &::before {
            margin-right: $margin-right-icon-product-attribute-download;
            margin-bottom: $margin-bottom-icon-product-attribute-download;
        }
    }
}

.club-carousel {
    > .container-with-header-header > .container-with-header-title {
        text-align: center;

        @include media-breakpoint-up(xl) {
            margin-bottom: 50px;
        }
    }

    .ms-content-block {
        > .ms-content-block__details {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(sm) {
                margin-top: 40px;
                margin-bottom: 30px;
                height: 300px;
            }

            @include media-breakpoint-down(xs) {
                height: 260px;
            }

            @include media-breakpoint-up(md) {
                justify-content: flex-start;
            }

            @include media-breakpoint-up(xl) {
                @include flex-width($width-container-detail-content__desktop);
                margin: $margin-container-detail-content__desktop;
            }

            @include media-breakpoint-up(xxl) {
                @include flex-width($width-container-detail-content);
                margin: calc(0.75/16 * 100%) calc(1/16 * 100%) 35px;
            }

            > .ms-content-block__text {
                @extend %scrollbar-y;
                max-height: 165px;

                @include media-breakpoint-up(sm) {
                    max-height: 185px;
                }

                @include media-breakpoint-up(md) {
                    max-height: 220px;
                }
            }

            > .ms-content-block__cta {
                margin-top: auto;
            }
        }

        > .ms-content-block__image {
            @include media-breakpoint-down(sm) {
                height: 60vw;
            }

            img {
                @include image-cover;
            }
        }
    }

    .shipping-popup {
        position: relative;
        z-index: 1;
        margin: -20px calc(1/16 * 100%) 0;

        @include media-breakpoint-up(sm) {
            margin-left: calc(2/16 * 100%);
        }

        @include media-breakpoint-up(md) {
            margin-top: -20px;
            margin-left: calc(9.5/16 * 100%);
        }

        @include media-breakpoint-up(xxl) {
            margin-left: calc(10/16 * 100%);
        }

        button {
            @include button-remove;
            @extend %link;
        }
    }

    .msc-carousel__indicators {
        bottom: unset;
        margin-bottom: 0;

        @include media-breakpoint-down(sm) {
            top: 63vw;
        }

        @include media-breakpoint-up(md) {
            justify-content: flex-start;
            top: 10px;
            margin-left: calc(9.5/16 * 100%  - 10px);
        }

        @include media-breakpoint-up(xxl) {
            top: 20px;
            margin-left: calc(10/16 * 100%  - 10px);
        }

        li {
            &:not(.active) {
                opacity: 1;
                background-color: rgba(44,38,38,0.15);
            }

            &.active {
                opacity: $opacity-icon-media-gallery-carousel-indicator--active;
                background-color: $color-background-icon-media-gallery-carousel-indicator;
            }
        }
    }

    .carousel-fade {
        .msc-carousel__item {
            opacity: 1;

            .ms-content-block__image,
            .ms-content-block__text,
            .ms-content-block__title {
                @include transition-carousel($transition-carousel);
                opacity: 0;
                transition-property: opacity;
                transform: none;
            }
        }

        .msc-carousel__item.active,
        .msc-carousel__item__next.msc-carousel__item__left,
        .msc-carousel__item__prev.msc-carousel__item__right {
            .ms-content-block__image,
            .ms-content-block__text,
            .ms-content-block__title {
                z-index: 1;
                opacity: 1;
            }
        }

        .active.msc-carousel__item__left,
        .active.msc-carousel__item__right {
            .ms-content-block__image,
            .ms-content-block__text,
            .ms-content-block__title {
                @include transition-carousel(0 $transition-duration-carousel opacity);
                z-index: 0;
                opacity: 0;
            }
        }
    }

    .msc-carousel__control__prev,
    .msc-carousel__control__next {
        @include media-breakpoint-down(sm) {
            bottom: unset;
            top: 25vw;
        }
    }

    .msc-carousel__control__next {
        @include hover-focus {
            @include media-breakpoint-up(md) {
                &::before {
                    outline-color: $color-icon-carousel-control-inverse;
                }
            }
        }
    }

    /* .msc-carousel__control__next__icon {
        @include media-breakpoint-up(md) {
            background-image: $icon-carousel-control-next-inverse;
        }
    } */
}
