.event-list-empty {
    text-align: center;

    @include width-container-with-margin(
        $width-container-standard,
        $width-container-standard__tablet,
        $width-container-standard__mobile,
        separate
    );
}

.event-list {
    margin: $margin-container-alt__mobile;

    @include media-breakpoint-up(sm) {
        margin: $margin-container-alt__tablet;
    }

    @include media-breakpoint-up(xl) {
        margin: $margin-container-alt;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.event-list {
    &-event {
        margin: $margin-container-alt-content__mobile;

        @include media-breakpoint-up(sm) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: $margin-container-alt-content__tablet;
        }

        @include media-breakpoint-up(md) {
            margin: $margin-container-alt-content__desktop;
        }

        @include media-breakpoint-up(xl) {
            margin: $margin-container-alt-content;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:nth-child(even) {
            @include media-breakpoint-up(sm) {
                flex-direction: row-reverse;
            }
        }
    }

    &-image {
        @include media-breakpoint-down(xs) {
            width: $width-container-zigzag-content__mobile;
            margin: auto;
        }

        @include media-breakpoint-up(sm) {
            @include flex-width($width-container-zigzag-image);
        }
    }

    &-details {
        width: $width-container-zigzag-content__mobile;
        margin: $margin-container-zigzag-content__mobile;
        text-align: $text-align-container-zigzag-content__mobile;

        @include media-breakpoint-up(sm) {
            @include center-flex(x);
            flex-direction: column;
            @include flex-width($width-container-zigzag-content__tablet);
            margin: $margin-container-zigzag-content__tablet;
            text-align: $text-align-container-zigzag-content;
        }

        @include media-breakpoint-up(xxl) {
            @include flex-width(calc(4/16 * 100%));
            margin: 25px calc(1.5/16 * 100%);
        }
    }
}
