.gift-card-balance {
    @include center-flex(x);
    flex-direction: column;

    > * {
        max-width: $max-width-container-gift-card-balance;
    }

    &-label-text {
        @extend %label-gift-card-balance;
    }

    &-input-text {
        @extend %input-gift-card-balance;
    }

    &-message {
        @extend %alert-gift-card-balance-message;
    }

    &-error {
        @extend %alert-gift-card-balance-error;
    }

    &-button {
        @extend %button-gift-card-balance;
    }
}
