// Modal
$z-index-container-modal: 1050;

%modal {
    @extend .modal;
    z-index: $z-index-container-modal;
}

// Modal Dialog
$max-width-container-modal-dialog: 915px;
$min-width-container-modal-dialog: 220px;
$max-height-container-modal-dialog: 615px;
$min-height-container-modal-dialog: 330px;
$color-background-modal-dialog: $color-background;

%modal-dialog {
    @extend .modal-dialog;
    max-width: $max-width-container-modal-dialog;
    min-width: $min-width-container-modal-dialog;
    max-height: $max-height-container-modal-dialog;
    min-height: $min-height-container-modal-dialog;
    background-color: $color-background-modal-dialog;

    @include media-breakpoint-up(lg) {
        @include center-absolute(both);
        transform: translate(-50%, -50%) !important;
    }
}

// Modal Content
$border-radius-container-modal-content: 0;
$border-width-container-modal-content: 0;
$color-border-modal-content: transparent;

%modal-content {
    @extend .modal-content;
    @include border($color-border-modal-content, $border-width-container-modal-content);
    border-radius: $border-radius-container-modal-content;
    outline: 0;
    background-clip: border-box;
}

// Modal Header
$padding-container-modal-header: 25px;
$margin-bottom-container-modal-header: -50px;
$z-index-container-modal-header: 5;

%modal-header {
    @extend .modal-header;
    z-index: $z-index-container-modal-header;
    padding: $padding-container-modal-header;
    margin-bottom: $margin-bottom-container-modal-header;
    border-bottom: 0;
}

// Modal Body
$padding-container-modal-body: 50px;
$padding-container-modal-body__mobile: 50px 25px;

%modal-body {
    @extend .modal-body;
    padding: $padding-container-modal-body__mobile;

    @include media-breakpoint-up(lg) {
        padding: $padding-container-modal-body;
    }
}

// Modal Close Icon
$icon-modal-close: $asset-icon-close;
$size-icon-modal-close: 12px;

%modal-close {
    @extend .close;
    @include icon-image($icon-modal-close, before, $size-icon-modal-close);
    opacity: 1;

    @include hover-all {
        opacity: 1;
    }
}

// Modal Backdrop
%modal-backdrop {
    @extend .modal-backdrop;
}
