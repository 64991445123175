.wine-club-management {
    &__container {
        @extend %width-shop;
        @extend %width-shop-with-header;
        margin-top: 50px;

        @include media-breakpoint-up(md) {
            margin-top: 70px;
        }
    }

    &__content {
        margin-top: 30px;
    }

    &__note {
        @extend %text;
        @extend %text-small;
        @extend %text-accent;
        margin-top: 25px;
    }

    &__table {
        &-head,
        &-row {
            display: flex;
        }

        &-head {
            margin-bottom: 15px;

            .wine-club-management__table-cell {
                @extend %heading-h6;

                @include media-breakpoint-down(sm) {
                    font-size: 11px;
                    letter-spacing: 0.1em;
                }
            }
        }

        &-row {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            .wine-club-management__table-cell {
                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }
            }

            .wine-club-management__table-cell__status {
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }
            }
        }

        &-cell {
            margin-right: 10px;

            @include media-breakpoint-up(md) {
                margin-right: 20px;
            }

            &:last-child {
                margin-right: 0;
            }

            &__status {
                @include flex-width(50px);

                @include media-breakpoint-up(md) {
                    @include flex-width(80px);
                }
            }

            &__club {
                flex: 1;
                max-width: 400px;
                word-break: break-word;
            }

            &__date-start {
                @include flex-width(65px);

                @include media-breakpoint-up(md) {
                    @include flex-width(170px);
                }
            }

            &__date-next {
                @include flex-width(70px);

                @include media-breakpoint-up(md) {
                    @include flex-width(170px);
                }
            }

            &__paused {
                opacity: 0.6;
            }

            &__actions {
                @include flex-width(60px);

                @include media-breakpoint-up(md) {
                    @include flex-width(130px);
                    display: flex;
                }
            }
        }
    }

    &__action {
        display: block;

        @include media-breakpoint-up(md) {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }

        &-disabled {
            @extend %link-muted;
            pointer-events: none;
        }
    }

    &__modal {
        min-height: 0;

        label {
            @extend %label;
        }

        select {
            @extend %select;
            padding-left: 10px;
        }

        .modal-title {
            display: block;
        }

        .modal-body {
            padding: 25px;
        }

        &-header {
            margin-top: 10px;
            margin-bottom: 25px;
        }

        &-title {
            @extend %heading-h6;
        }

        &-error {
            @extend %alert;
            @extend %alert-danger;
        }

        &-confirmation-note {
            @extend %text;
            @extend %text-small;
            margin-top: 15px;
            color: $color-red;
        }

        &-buttons {
            margin-top: 25px;
        }

        &-button {
            margin-right: 15px;

            @include media-breakpoint-down(xs) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
            }

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }

            &-confirm {
                @extend %button-primary;

                &:disabled,
                &.disabled {
                    @extend %button-primary-disabled;
                }
            }

            &-cancel {
                @extend %button-secondary;

                &:disabled,
                &.disabled {
                    @extend %button-secondary-disabled;
                }
            }
        }
    }

    &__modal-edit {
        max-width: 1030px;
        padding: 20px 25px;
        margin: 10px 0;
        border-width: 1px 0;
        border-style: solid;
        border-color: $color-border;
        background-color: $color-background-page;

        input,
        select {
            max-width: 500px;
        }

        .wine-club-management__edit-details {
            @extend %text;
            @extend %text-small;
            @extend %text-accent;
            margin-top: -10px;
            margin-bottom: 15px;
        }

        .wine-club-management__edit-cc-message {
            @extend %text;
            @extend %text-small;
            @extend %text-accent;
            font-size: 13px;
        }

        .wine-club-management__edit-pickup {
            margin-top: 10px;

            &-option {
                display: flex;
                margin-bottom: 10px;

                &-input {
                    width: auto;
                    max-width: unset;
                    margin-right: 10px;
                }

                &-label {
                    margin-top: -5px;
                }
            }
        }

        .wine-club-management__edit-pickup,
        .wine-club-management__edit-shipping {
            &-single {
                margin-left: 20px;
            }

            &-option,
            &-single {
                &-name,
                &-address {
                    display: block;
                }

                &-name {
                    font-weight: $font-weight-bold;
                }
            }
        }

        .wine-club-management__edit-shipping-single {
            margin-top: 10px;
        }

        .wine-club-management__edit-add-address {
            @extend %button-address-add;
            margin-top: 5px;

            @include media-breakpoint-up(sm) {
                @include button-width-auto;
            }
        }
    }

    &__message-success {
        @extend %alert;
        @extend %alert-success;
        max-width: 1030px;
        margin-bottom: 15px !important;
    }

    &__selector-group {
        margin-bottom: 10px;

        &-label {
            @extend %label;
        }
    }

    &__empty .ms-content-block__image img {
        max-width: 600px;
    }
}
