// Order Confirmation ID
$margin-bottom-text-order-confirmation-id: 10px;

%text-order-confirmation-id {
    @extend %heading-h4;
}

// Order Confirmation Button
$margin-top-button-order-confirmation: 25px;

%button-order-confirmation {
    @extend %button;
    @extend %button-underlined;

    &:disabled,
    &.disabled {
        @extend %button-disabled;
    }
}
