// Age Gate Modal Dialog
$max-width-container-age-gate-modal-dialog: 560px;

// Age Gate Modal Body
$padding-container-age-gate-modal-body: 40px 75px 30px;
$padding-container-age-gate-modal-body__mobile: 35px 25px 25px;

// Age Gate Container
$text-align-container-age-gate: center;

// Age Gate Logo
$width-image-age-gate-logo: 250px;
$margin-image-age-gate-logo: 0 auto 35px;

// Age Gate Text
$margin-bottom-text-age-gate: 0;
$font-size-text-age-gate: 15px;
$font-size-text-age-gate__tablet: $font-size-text-age-gate;
$font-size-text-age-gate__mobile: $font-size-text-age-gate;

%text-age-gate {
    @include text(
        $color-text,
        $font-family-text,
        $font-size-text-age-gate,
        $font-size-text-age-gate__tablet,
        $font-size-text-age-gate__mobile,
        $font-weight-text,
        $font-style-text,
        $letter-spacing-text,
        $line-height-text,
        $line-height-text__tablet,
        $line-height-text__mobile,
        $text-transform-text
    );
}

// Age Gate Heading
$margin-bottom-heading-age-gate: 1.5rem;
$font-size-heading-age-gate: 18px;
$font-size-heading-age-gate__tablet: $font-size-heading-age-gate;
$font-size-heading-age-gate__mobile: $font-size-heading-age-gate;
$font-weight-heading-age-gate: $font-weight-bold;
$text-transform-heading-age-gate: uppercase;

%heading-age-gate {
    @include heading(
        $color-text,
        $font-family-text,
        $font-size-heading-age-gate,
        $font-size-heading-age-gate__tablet,
        $font-size-heading-age-gate__mobile,
        $font-weight-heading-age-gate,
        $font-style-text,
        $letter-spacing-text,
        $line-height-text,
        $line-height-text__tablet,
        $line-height-text__mobile,
        $text-transform-heading-age-gate
    );
}

// Age Gate Button
$padding-button-age-gate: 0.75rem 2rem;
$border-width-button-age-gate: 1px;
$border-style-button-age-gate: solid;
$border-radius-button-age-gate: 0;
$color-button-age-gate__border: $color-black;
$color-button-age-gate__background: transparent;
$color-button-age-gate__text: $color-black;
$color-button-age-gate__border--hover: $color-black;
$color-button-age-gate__background--hover: $color-black;
$color-button-age-gate__text--hover: $color-white;
$font-family-button-age-gate: $font-family-button;
$font-size-button-age-gate: 22px;
$font-size-button-age-gate__tablet: $font-size-button-age-gate;
$font-size-button-age-gate__mobile: $font-size-button-age-gate;
$font-weight-button-age-gate: $font-weight-button;
$font-style-button-age-gate: $font-style-button;
$letter-spacing-button-age-gate: 0.05em;
$line-height-button-age-gate: 1.4;
$line-height-button-age-gate__tablet: $line-height-button-age-gate;
$line-height-button-age-gate__mobile: $line-height-button-age-gate;
$text-transform-button-age-gate: uppercase;
$text-decoration-button-age-gate: none;
$text-decoration-button-age-gate--hover: none;

%button-age-gate {
    @include button(
        $padding-button-age-gate,
        $border-width-button-age-gate,
        $border-style-button-age-gate,
        $border-radius-button-age-gate,
        $color-button-age-gate__border,
        $color-button-age-gate__background,
        $color-button-age-gate__text,
        $color-button-age-gate__border--hover,
        $color-button-age-gate__background--hover,
        $color-button-age-gate__text--hover,
        $font-family-button-age-gate,
        $font-size-button-age-gate,
        $font-size-button-age-gate__tablet,
        $font-size-button-age-gate__mobile,
        $font-weight-button-age-gate,
        $font-style-button-age-gate,
        $letter-spacing-button-age-gate,
        $line-height-button-age-gate,
        $line-height-button-age-gate__tablet,
        $line-height-button-age-gate__mobile,
        $text-transform-button-age-gate,
        $text-decoration-button-age-gate,
        $text-decoration-button-age-gate--hover
    );
}

// Age Gate Button Subtext
$font-family-button-age-gate-subtext: $font-family-text;
$font-size-button-age-gate-subtext: 13px;

// Age Gate Links
$margin-top-container-age-gate-links: 1.5rem;
$font-size-text-age-gate-links: 11.5px;
$line-height-text-age-gate-links: 18px;

%text-age-gate-links {
    @include text-font-size($font-size-text-age-gate-links);
    @include text-line-height($line-height-text-age-gate-links);
}
