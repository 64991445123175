.ms-media-gallery {
    @include center-flex(x);
    position: relative;
    height: 100%;
    padding: $padding-container-media-gallery__mobile;

    @include media-breakpoint-up(sm) {
        padding: $padding-container-media-gallery__tablet;
    }

    @include media-breakpoint-up(xl) {
        padding: $padding-container-media-gallery;
    }

    &__carousel {
        position: static !important;
    }

    &__carousel,
    .msc-carousel__inner,
    picture {
        width: 100%;
        height: 100%;
    }

    picture {
        display: flex;
    }

    &__item {
        max-height: 100%;
        margin: auto;
    }

    .msc-carousel__indicators {
        bottom: $bottom-container-media-gallery-carousel-indicators__mobile;
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
            bottom: $bottom-container-media-gallery-carousel-indicators__tablet;
        }

        @include media-breakpoint-up(xl) {
            bottom: $bottom-container-media-gallery-carousel-indicators;
        }

        li {
            opacity: $opacity-icon-media-gallery-carousel-indicator;
            background-color: $color-background-icon-media-gallery-carousel-indicator;

            &.active {
                opacity: $opacity-icon-media-gallery-carousel-indicator--active;
            }
        }
    }

    .msc-carousel__control__prev__icon,
    .msc-carousel__control__next__icon,
    &__thumbnails-container {
        display: none !important;
    }
}

.product-container.bottled-wine .ms-media-gallery__item,
.product-container.canned-wine .ms-media-gallery__item {
    height: 100%;
    max-width: 150px;
}

.product-container.bottled-wine .ms-media-gallery__item {
    max-height: $max-height-image-media-gallery-item-wine-bottled;
}

.product-container.canned-wine .ms-media-gallery__item {
    max-height: $max-height-image-media-gallery-item-wine-canned;
}

.product-container {
    &.bottled-wine,
    &.canned-wine {
        .ms-media-gallery {
            position: relative;

            &::before {
                content: '';
                display: block;
                position: absolute;
                bottom: 40px;
                width: 45vh;
                height: 100px;
                background-image: $asset-background-shadow;
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center bottom;
            }
        }
    }

    &.bottled-wine .ms-media-gallery::before {
        min-width: 370px;
        max-width: 390px;

        @include media-breakpoint-up(sm) {
            min-width: 285px;
            bottom: 58px;
        }

        @include media-breakpoint-up(xl) {
            bottom: 83px;
        }

        @media screen and (max-width: $breakpoint-xl) and (min-height: 760px) {
            bottom: 50%;
            transform: translateY(285px);
        }

        @media screen and (min-width: $breakpoint-xl) and (min-height: 830px) {
            bottom: 50%;
            transform: translateY(285px);
        }
    }

    &.canned-wine .ms-media-gallery::before {
        min-width: 330px;
        max-width: 340px;

        @include media-breakpoint-up(sm) {
            bottom: 138px;
        }

        @include media-breakpoint-up(xl) {
            bottom: 142px;
        }

        @media screen and (max-width: $breakpoint-xl) and (min-height: 690px) {
            bottom: 50%;
            transform: translateY(163px);
        }

        @media screen and (min-width: $breakpoint-xl) and (min-height: 700px) {
            bottom: 50%;
            transform: translateY(160px);
        }
    }
}
