//==============================================================================
// BREAKPOINTS
//==============================================================================

$breakpoint-xxl: 1200px;
$breakpoint-xl: 1100px;
$breakpoint-lg: 1000px;
$breakpoint-md: 960px;
$breakpoint-sm: 680px;
$breakpoint-xs: 450px;
$breakpoint-xxs: 400px;

//==============================================================================
// LAYOUT
//==============================================================================

// Base
$width-page: 100%;
$width-page__tablet: 100%;
$width-page__mobile: 100%;

$min-width-page: 375px;
// $max-width-page: 1440px; // Use this if full-width makes image proportions an issue.
$max-width-page: none;

$margin-page: 0 auto;
$margin-page__tablet: $margin-page;
$margin-page__mobile: $margin-page;

$padding-page: 0 calc(1/16 * 100%);
$padding-page__tablet: $padding-page;
$padding-page__mobile: $padding-page;

//==============================================================================
// ELEMENTS
//==============================================================================

// Text
$margin-top-text: 15px;
$margin-bottom-text: 15px;

// Heading
$margin-top-heading: 20px;
$margin-bottom-heading: 15px;
$margin-bottom-subheading: 5px;

// List
$padding-left-list: 25px;
$margin-top-list: 10px;
$margin-bottom-list-item: 5px;

// Button
$margin-top-button: 15px;
$padding-button: 0;
$border-width-button: 0;
$border-style-button: none;
$border-radius-button: 0;

$padding-button-primary: 15px 30px 12px;
$border-width-button-primary: 1px;
$border-style-button-primary: solid;
$border-radius-button-primary: 0;

$padding-button-secondary: $padding-button-primary;
$border-width-button-secondary: $border-width-button-primary;
$border-style-button-secondary: $border-style-button-primary;
$border-radius-button-secondary: $border-radius-button-primary;

// Input
$padding-input: 15px 20px 12px;
$border-width-input: 1px;
$border-style-input: solid;
$border-radius-input: 0;
