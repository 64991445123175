// Account Profile
$padding-container-account-profile__mobile: 25px calc(1/16 * 16/14 * 100%);
$margin-container-account-profile: 0 0 30px 0;
$margin-container-account-profile__mobile: 0 calc(-1/16 * 16/14 * 100%) 30px;
$color-background-account-profile: transparent;
$color-background-account-profile__mobile: $color-background-accent;

// Account Profile Heading
$width-heading-account-profile: 60px;
$margin-top-heading-account-profile: 3px;
$margin-right-heading-account-profile: 10px;

// Account Profile Links
$margin-top-container-account-profile-links: 10px;

// Account Profile Link
%link-account-profile {
    @extend %text;
    @extend %text-small;
}

// Account Management Address
$margin-bottom-container-account-management-address__mobile: 30px;
