// Search
$z-index-container-search: 120;

// Search Input
$width-input-search: calc(3/16 * 100vw);
$min-width-input-search: 360px;
$padding-right-input-search: 40px;
$padding-left-input-search__mobile: 50px;

%input-search {
    @extend %input;
}

// Search Icon
$margin-x-icon-search: 20px;
$icon-search: $asset-icon-search;
$size-icon-search: 16px;

%icon-search {
    @include icon-image($icon-search, before, $size-icon-search);
}

// Search Close Icon
$margin-right-icon-search-close: calc(0.5/16 * 100%);
$icon-search-close: $asset-icon-close;
$size-icon-search-close: 12px;

%search-close {
    @include icon-image($icon-search-close, before, $size-icon-search-close);
}

// Search Placeholder
$width-container-search-placeholder: $size-icon-search-close;
$margin-left-container-search-placeholder: $margin-right-icon-search-close;

// Search Results
$top-container-search-results: calc(50% + (50px / 2) - 1px);
$width-container-search-results: $width-input-search;
$min-width-container-search-results: $min-width-input-search;
$padding-container-search-results: 15px 20px;
$border-container-search-results: 1px solid $color-border;
$box-shadow-container-search-results: 0 6px 6px transparentize($color-border, 0.5);

// Search Results Item
$padding-bottom-link-search-results-item: 8px;
$margin-bottom-link-search-results-item: 10px;
$border-bottom-link-search-results-item: 1px solid transparentize($color-border-muted, 0.6);
$text-decoration-link-search-results-item: none;
$text-decoration-link-search-results-item--hover: none;

%link-search-results-item {
    @extend %text;
    @extend %text-small;
    @include link-text-decoration($text-decoration-link-search-results-item, $text-decoration-link-search-results-item--hover);
}
