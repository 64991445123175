@mixin text(
    $color: $color-text,
    $font-family: $font-family-text,
    $font-size: $font-size-text,
    $font-size__tablet: $font-size-text__tablet,
    $font-size__mobile: $font-size-text__mobile,
    $font-weight: $font-weight-text,
    $font-style: $font-style-text,
    $letter-spacing: $letter-spacing-text,
    $line-height: $line-height-text,
    $line-height__tablet: $line-height-text__tablet,
    $line-height__mobile: $line-height-text__mobile,
    $text-transform: $text-transform-text
) {
    color: $color;
    font-family: $font-family;
    font-size: $font-size__mobile;
    font-weight: $font-weight;
    font-style: $font-style;
    letter-spacing: $letter-spacing;
    line-height: $line-height__mobile;
    text-transform: $text-transform;

    @include media-breakpoint-up(sm) {
        font-size: $font-size__tablet;
        line-height: $line-height__tablet;
    }

    @include media-breakpoint-up(xl) {
        font-size: $font-size;
        line-height: $line-height;
    }
}

@mixin text-color($color) {
    color: $color;
}

@mixin text-font-size($font-size, $font-size__tablet: $font-size, $font-size__mobile: $font-size) {
    font-size: $font-size__mobile;

    @include media-breakpoint-up(sm) {
        font-size: $font-size__tablet;
    }

    @include media-breakpoint-up(xl) {
        font-size: $font-size;
    }
}

@mixin text-font-weight($font-weight) {
    font-weight: $font-weight;
}

@mixin text-font-style($font-style) {
    font-style: $font-style;
}

@mixin text-line-height($line-height, $line-height__tablet: $line-height, $line-height__mobile: $line-height) {
    line-height: $line-height__mobile;

    @include media-breakpoint-up(sm) {
        line-height: $line-height__tablet;
    }

    @include media-breakpoint-up(xl) {
        line-height: $line-height;
    }
}

@mixin text-letter-spacing($letter-spacing) {
    letter-spacing: $letter-spacing;
}

@mixin text-text-transform($text-transform) {
    text-transform: $text-transform;
}
