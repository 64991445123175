.instagram-widget .ms-content-block__cta {
    margin-top: $margin-top-icon-instagram-widget__mobile;
    text-align: $text-align-icon-instagram-widget;

    @include media-breakpoint-up(sm) {
        margin-top: $margin-top-icon-instagram-widget__tablet;
    }

    @include media-breakpoint-up(lg) {
        margin-top: $margin-top-icon-instagram-widget;
    }

    > a {
        @extend %icon-instagram-widget;
        display: inline-block;

        &::before {
            margin: auto;
        }
    }
}
