%input {
    @include input;
}

%select {
    @include input;
    height: 100%;
}

%label {
    @extend %text;
    @extend %text-small;
    margin-bottom: 0;
}
