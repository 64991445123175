.ms-checkout-shipping-address {
    &__group-images-lines {
        display: flex;
        flex-flow: row wrap;
    }
    &__group-images-lines-product {
        height: 140px;
        width: auto;
        padding: 15px 0;
        margin-right: 15px;
        &:last-of-type {
            margin-right: 0;
        }
        img {
            height: 100%;
            width: auto;
        }
        .msc-empty_image {
            @include image-placeholder(40px);
            text-align: center;
        }
    }
    &__group-images-lines-line-quantity {
        display: none;
    }
}
