.msc-cta {
    @extend %button;

    &__primary,
    &__secondary {
        @extend .msc-cta;
    }
}

.msc-cta,
.msc-btn {
    &:disabled,
    &.disabled {
        @extend %button-disabled;
    }
}
