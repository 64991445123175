@mixin outline-offset(
    $outline-width: 1px,
    $outline-style: dashed,
    $outline-color: $color-black,
    $outline-offset: -1px
) {
    outline: $outline-width $outline-style $outline-color;
    outline-offset: $outline-offset;
}

@mixin vfi {
    &:focus {
        border: 1px dashed $color-white;
        outline: 1px dashed $color-black;
    }
}

@mixin vfi-pseudo(
    $selector: after,
    $offset: -2px,
    $position: relative
) {
    position: $position;

    &:focus {
        outline: 1px dashed $color-white;

        &::#{$selector} {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            outline: 1px dashed $color-black;
            outline-offset: $offset;
            background-color: transparent;
        }
    }
}
