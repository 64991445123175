.tasting-tours {
    &-content {
        margin-bottom: 15px;
    }

    &-app {
        .rc-legend {
            display: none;
        }

        .rc-label-input-pair {
            @include center-flex(y);
        }

        .rc-label-column {
            width: 30%;

            label {
                @extend %heading-h6;
            }
        }

        .rc-input-column,
        .rc-button-group {
            width: 70%;
        }

        .rc-required {
            display: none;
        }

        .rc-input {
            @extend %input;
        }

        .rc-button {
            @extend %button-primary;
            width: 100%;
            margin: 10px 0;
            background-image: none;
        }

        hr {
            display: none;
        }

        .rc-footnote {
            @include center-flex(y);
            opacity: 1;
            transition: opacity 0.6s ease;

            /* @include hover-all {
                opacity: 1;
            } */
            .rc-footer-column{
                span, a{
                    color: #767676
                }
            }
        }

        .rc-logo-reserve {
            margin-left: auto;
        }

        .rc-data-error {
            @extend %alert;
            @extend %alert-danger;
            margin: 10px 0;
        }

        .errorBox {
            &[hidden] {
                display: block !important;
            }

            &:empty {
                display: none !important;
            }
        }
    }

    &-hidden {
        display: none;
    }

    %icon-tasting-tours-button {
        $icon-tasting-tours-button: $asset-icon-submit;
        $width-icon-tasting-tours-button: 12px;
        $height-icon-tasting-tours-button: 10px;

        @include icon-image($icon-tasting-tours-button, after, $width-icon-tasting-tours-button, $height-icon-tasting-tours-button);

        &-disabled {
            opacity: 0.5;
        }
    }

    &-button {
        @include center-flex(y);
        @extend %button-primary;
        @extend %icon-tasting-tours-button;
        margin: 20px 0;

        &::after {
            @include image-invert;
            margin-left: 10px;
            margin-bottom: 2px;
        }

        &:disabled,
        &.disabled {
            &::after {
                @extend %icon-tasting-tours-button-disabled;
            }
        }
    }

    &-consent {
        @extend %text-appellation;
    }
}
