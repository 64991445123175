.ms-content-block {
    .ms-content-block__image:empty,
    .ms-content-block__image img[height="1px"],
    .ms-content-block__title:empty,
    .ms-content-block__text:empty,
    .ms-content-block__cta:empty {
        display: none;
    }

    &__details {
        color: inherit;
    }

    &__title {
        margin-bottom: $margin-bottom-heading-content-block;
        color: inherit;

        &:only-child {
            margin-bottom: 0;
        }
    }

    &__text {
        color: inherit;

        &.desktop {
            @include media-breakpoint-down(xs) {
                display: none;
            }
        }

        &.mobile {
            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6,
        ul,
        ol {
            color: inherit;
        }
    }

    &__cta,
    &__text a {
        margin-top: $margin-top-button-content-block;

        * {
            @include media-breakpoint-down(xxs) {
                @include button-width-full;
                margin-bottom: 10px;
            }

            @include media-breakpoint-up(xs) {
                margin-right: $margin-right-button-content-block;
            }

            &:last-child {
                @include media-breakpoint-down(xxs) {
                    margin-bottom: 0;
                }

                @include media-breakpoint-up(xs) {
                    margin-right: 0;
                }
            }
        }
    }

    &.textplacement__center {
        text-align: center;
    }

    &.textplacement__right {
        text-align: right;
    }

    &.ctastyle__default {
        .ms-content-block__cta > * {
            @extend %button-underlined;
            @include button-width-auto;
            margin-right: $margin-right-button-content-block;

            &:last-child {
                margin-right: 0;
            }
        }
        .ms-content-block__text a{
            @extend %button-underlined;
            @include button-width-auto;
            margin-right: $margin-right-button-content-block;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &.ctastyle__primary {
        .ms-content-block__cta > * {
            @extend %button-primary;
        }
    }

    &.ctastyle__secondary {
        .ms-content-block__cta > * {
            @extend %button-secondary;
        }
    }

    &__image {
        margin-bottom: $margin-bottom-image-content-block;
    }

    &__clickable {
        @include link-text-decoration(none);
    }
}

.home-carousel {
    .ms-content-block__details {
        .ms-content-block__title {
            @media screen and (min-width: 1100px) {
                text-shadow: 2px 2px $color-black !important;
            }

            @media screen and (min-width: 200px) and (max-width: 1099px) {
                text-shadow: none;
            }
        }
    }
}
