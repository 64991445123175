// B2C
$width-container-b2c: 100%;
$max-width-container-b2c: 600px;
$margin-container-b2c: 30px auto;
$margin-container-b2c__mobile: 0 auto;

// B2C Message
$padding-container-b2c-message: 25px 75px;
$padding-container-b2c-message__tablet: 25px 35px;
$padding-container-b2c-message__mobile: 15px calc(0.5/16 * 100%);
$color-background-b2c-message: $color-background-inverse;

// B2C Message Heading
%heading-b2c-message {
    @extend %heading-inverse;
}

// B2C Message Text
%text-b2c-message {
    @extend %text;
    @extend %text-small;
    @extend %text-inverse;
}

// B2C Message Button
$margin-top-button-b2c-message: 20px;

%button-b2c-message {
    @extend %button-secondary;
    @extend %button-secondary-inverse;

    &:disabled,
    &.disabled {
        @extend %button-secondary-disabled;
    }
}

// B2C Container
$padding-container-b2c-container: 45px 75px 55px;
$padding-container-b2c-container__tablet: 25px 35px 35px;
$padding-container-b2c-container__mobile: 25px calc(0.5/16 * 100%) 35px;
$color-background-b2c-container: $color-background-accent;

// B2C Heading
$margin-bottom-heading-b2c: 25px;

%heading-b2c {
    margin-bottom: $margin-bottom-heading-b2c;
}

// B2C Text
%text-b2c {
    @extend %text;
    @extend %text-small;
}

// B2C Disclaimer Text
$font-size-text-b2c-disclaimer: 12px;

%text-b2c-disclaimer {
    @include text-font-size($font-size-text-b2c-disclaimer);
}

// B2C Input Label
%label-b2c {
    @extend %label;
}

// B2C Input
%input-b2c {
    @extend %input;
}

// B2C Input Group
$margin-bottom-container-b2c-input-group: 10px;

%input-group-b2c {
    margin-bottom: $margin-bottom-container-b2c-input-group;

    label {
        @extend %label-b2c;
    }

    input {
        @extend %input-b2c;
    }
}

// B2C Button
$margin-y-button-b2c: 10px;

%button-b2c {
    @include button-width-full;

    &-primary {
        @extend %button-b2c;
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &-secondary {
        @extend %button-b2c;
        @extend %button-secondary;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }
}

// B2C Icons
$size-container-b2c-icon: 51px;
$font-size-icon-b2c: 16px;
$icon-b2c-show: $icon-show;
$icon-b2c-hide: $icon-hide;

%icon-b2c {
    @include center-flex(both);
    @include link-text-decoration(none);
    width: $size-container-b2c-icon;
    height: $size-container-b2c-icon;
    margin-top: -$size-container-b2c-icon;
    margin-left: auto;
    font-size: $font-size-icon-b2c;

    &-show {
        @include icon-content($icon-b2c-show, before, regular);
    }

    &-hide {
        @include icon-content($icon-b2c-hide, before, regular);
    }
}

%input-b2c-with-icon {
    padding-right: $size-container-b2c-icon !important;
}

// B2C Alert
$margin-bottom-alert-b2c: 10px;

%alert-b2c {
    @extend %alert;
    display: block !important;
    margin-bottom: $margin-bottom-alert-b2c;

    &[aria-hidden='true'] {
        display: none !important;
    }

    &-danger {
        @extend %alert-danger;
    }

    &-success {
        @extend %alert-success;
    }
}
