// Sku Selector
$z-index-container-sku-selector__mobile: 30;
$extra-padding-bottom-sku-selector__mobile: 15px;
$height-container-sku-selector__mobile: 50px + $extra-padding-bottom-sku-selector__mobile;
$margin-top-container-sku-selector__desktop-only: 35px;
$color-background-container-sku-selector__mobile: $color-background;
$box-shadow-container-sku-selector__mobile: 0 0 10px transparentize($color-black, 0.9);

// Sku Selector Price
$padding-container-sku-selector-price__mobile: 15px 25px (15px + $extra-padding-bottom-sku-selector__mobile);
$margin-bottom-container-sku-selector-price: 25px;
$margin-bottom-container-sku-selector-price__tablet: 20px;
$border-top-container-sku-selector-price__mobile: 1px solid $color-border;
$line-height-text-sku-selector-price: 1;

%text-sku-selector-price {
    @extend %text;
    @extend %text-small;
    @include text-line-height($line-height-text-sku-selector-price);
}

// Sku Selector Default Price
$font-weight-text-sku-selector-price-default: $font-weight-bold;

%text-sku-selector-price-default {
    @extend %text-sku-selector-price;
    @include text-font-weight($font-weight-text-sku-selector-price-default);
}

// Sku Selector Club Price
$margin-left-text-sku-selector-price-club: 20px;
$margin-left-text-sku-selector-price-club__mobile: 30px;
$margin-right-text-sku-selector-price-club: 5px;

// Sku Selector Input
$width-input-sku-selector: 60px;
$width-input-sku-selector__mobile: 50px;
$padding-x-input-sku-selector: 3px;
$padding-bottom-input-sku-selector__mobile: 12px + $extra-padding-bottom-sku-selector__mobile;
$text-align-input-sku-selector: center;

%input-sku-selector {
    @extend %input;
    padding-left: $padding-x-input-sku-selector;
    padding-right: $padding-x-input-sku-selector;
    text-align: $text-align-input-sku-selector;
    text-align-last: $text-align-input-sku-selector;

    @include media-breakpoint-down(xs) {
        padding-bottom: $padding-bottom-input-sku-selector__mobile;
    }
}

// Sku Selector Input Price
$top-input-sku-selector-price__mobile: 3px;
$width-input-sku-selector-price: 80px;
$width-input-sku-selector-price__mobile: 50px;
$padding-left-input-sku-selector-price: 30px;
$text-align-input-sku-selector-price: left;

%input-sku-selector-price {
    @extend %input-sku-selector;
    padding-left: $padding-left-input-sku-selector-price;
    text-align: $text-align-input-sku-selector-price;
}

// Sku Selector Input Price Prefix
$content-prefix-input-sku-selector-price: '$';
$top-prefix-input-sku-selector-price: 13px;
$top-prefix-input-sku-selector-price__mobile: 14px;
$left-prefix-input-sku-selector-price: 13px;

%prefix-sku-selector-price {
    @extend %text;
    @extend %text-accent;
    content: $content-prefix-input-sku-selector-price;
}

// Sku Selector Cart Button
$width-button-sku-selector-cart__mobile: 50px;
$padding-x-button-sku-selector-cart: 10px;
$padding-bottom-button-sku-selector-cart: 12px + $extra-padding-bottom-sku-selector__mobile;

$max-width-button-sku-selector-cart-disabled__mobile: 80px;
$padding-button-sku-selector-cart-disabled__mobile: 7px 5px (2px + $extra-padding-bottom-sku-selector__mobile);
$font-size-button-sku-selector-cart-disabled__mobile: 10px;
$line-height-button-sku-selector-cart-disabled__mobile: 15px;

%button-sku-selector-cart {
    @extend %button-primary;

    @include media-breakpoint-down(xs) {
        padding-bottom: $padding-bottom-button-sku-selector-cart;
    }

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}

@mixin button-sku-selector-cart {
    @include center-flex;
    @include icon-sku-selector-cart;
    width: $width-button-sku-selector-cart__mobile;
    padding-left: $padding-x-button-sku-selector-cart;
    padding-right: $padding-x-button-sku-selector-cart;
    font-size: 0;
}

@mixin button-sku-selector-cart-disabled {
    max-width: $max-width-button-sku-selector-cart-disabled__mobile;
    padding: $padding-button-sku-selector-cart-disabled__mobile;
    font-size: $font-size-button-sku-selector-cart-disabled__mobile;
    line-height: $line-height-button-sku-selector-cart-disabled__mobile;
}

// Sku Selector Cart Icon
$icon-sku-selector-cart: $asset-icon-cart;
$width-icon-sku-selector-cart: 18px;
$height-icon-sku-selector-cart: 16px;
$margin-bottom-icon-sku-selector-cart: 2px;

@mixin icon-sku-selector-cart {
    @include icon-image($icon-sku-selector-cart, before, $width-icon-sku-selector-cart, $height-icon-sku-selector-cart);

    &::before {
        @include image-invert;
        margin-bottom: $margin-bottom-icon-sku-selector-cart;
    }
}

// Sku Selector Error
$x-alert-sku-selector__mobile: 15px;
$top-alert-sku-selector: 85px;
$bottom-alert-sku-selector__mobile: calc(100% + 15px);

%alert-sku-selector {
    @extend %alert;

    &-danger {
        @extend %alert-danger;
    }
}
