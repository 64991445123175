.promo-banner-cookie {
    &-container {
        @include center-flex;
        flex-wrap: wrap;
        width: $width-container-banner;
        padding: $padding-container-banner__mobile;
        border-width: $border-width-container-banner;
        border-style: $border-style-container-banner;
        border-color: $color-border-banner;
        background-color: $color-background-banner;

        @include media-breakpoint-up(md) {
            padding: $padding-container-banner;
        }
    }

    &-content {
        @extend %text-banner;
        flex: 1;
        margin-right: $margin-right-container-banner-content;
        text-align: center;

        > *:empty {
            display: none;
        }

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6 {
            color: inherit;
            margin-bottom: 0;
        }

        a {
            @include plain-hover-all {
                color: inherit;
            }
        }
    }

    &-close {
        @extend %icon-banner-close;
    }
}
