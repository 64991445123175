.age-gate {
    &-modal {
        @extend %modal;

        .modal-dialog {
            @extend %modal-dialog;
            max-width: $max-width-container-age-gate-modal-dialog;
        }

        .modal-content {
            @extend %modal-content;
        }

        .modal-body {
            @extend %modal-body;
            padding: $padding-container-age-gate-modal-body__mobile;

            @include media-breakpoint-up(lg) {
                padding: $padding-container-age-gate-modal-body;
            }
        }
    }

    &-container {
        text-align: $text-align-container-age-gate;

        h4 {
            @extend %text-age-gate;
            margin-bottom: $margin-bottom-text-age-gate;
        }

        h2 {
            @extend %heading-age-gate;
            margin-bottom: $margin-bottom-heading-age-gate;
        }
    }

    &-logo {
        max-width: $width-image-age-gate-logo;
        margin: $margin-image-age-gate-logo;
    }

    &-button {
        @extend %button-age-gate;

        &-text {
            display: block;
            font-family: $font-family-button-age-gate-subtext;
            font-size: $font-size-button-age-gate-subtext;
        }

    }

    &-links {
        @extend %text-age-gate-links;
        display: flex;
        flex-direction: column;
        margin-top: $margin-top-container-age-gate-links;

        &:empty {
            display: none;
        }
    }
}
