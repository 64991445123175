// Checkout Error
$margin-checkout-error: 15px 0;

%alert-checkout-error {
    @extend %alert;
    @extend %alert-danger;
}

// Checkout Main
$width-container-checkout-main: calc(9/16 * 100%);
$width-container-checkout-main__desktop: calc(7/16 * 100%);
$width-container-checkout-main__tablet: calc(6/16 * 100%);
$padding-container-checkout-main__xl-only: 15px 0;
$margin-right-container-checkout-main__tablet-up: calc(1/16 * 100%);

// Checkout Side
$padding-container-checkout-side: 15px calc(0.5/16 * 100%);
$padding-container-checkout-side__tablet: 0 calc(0.75/16 * 100%);
$padding-container-checkout-side__mobile: 0 calc(1/16 * 16/14 *  100%);
$margin-container-checkout-side__mobile: 0 calc(-1/16 * 16/14 * 100%);
$color-background-container-checkout-side: $color-background-accent;

// Checkout Side Section
$padding-container-checkout-side-section: 25px 0;

// Checkout Side Heading
$margin-bottom-heading-checkout-side: 15px;

// Checkout Control
$margin-top-container-checkout-control: 20px;

// Checkout Control Button
$margin-bottom-button-checkout-control: 10px;
$margin-right-button-checkout-control: 10px;

// Checkout Control Order Button
%button-checkout-control-order {
    @extend %button-primary;

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}

// Checkout Control Shop Button
%button-checkout-control-shop {
    @extend %button-secondary;

    &:disabled,
    &.disabled {
        @extend %button-secondary-disabled;
    }
}

// Checkout Card
$width-container-checkout-card: calc(100% - 150px);
$width-container-checkout-card__desktop: calc(100% - 100px);
$margin-bottom-container-checkout-card__mobile: 50px;
$padding-container-checkout-card: 25px 0;

// Checkout Card Text
%text-checkout-card {
    @extend %text;
    @extend %text-small;
}

// Checkout Card Heading
%heading-checkout-card {
    @extend %heading-h4;
}

// Checkout Card Button
$top-button-checkout-card__desktop-only: 25px;
$bottom-button-checkout-card__mobile: 25px;

%button-checkout-card {
    @extend %button;
    @extend %button-underlined;

    &:disabled,
    &.disabled {
        @extend %button-disabled;
    }
}

// Checkout Card Input
$margin-bottom-input-checkout-card: 10px;

%input-checkout-card {
    @extend %input;
}

// Checkout Card Radio
$margin-radio-checkout-card: 0 10px 5px 0;
$line-height-radio-checkout-card: 2;

// Checkout Card Label
%label-checkout-card {
    @extend %label;
}

// Checkout Card Error
$margin-bottom-checkout-card-error: 10px;

%alert-checkout-card-error {
    @extend %alert;
    @extend %alert-danger;
}

// Checkout Card Header
$width-container-checkout-card-header__desktop-only: 200px;
$margin-right-container-checkout-card-header__desktop-only: 25px;
$margin-bottom-container-checkout-card-header__mobile: 15px;

// Checkout Card Body
$margin-top-container-checkout-card-body__desktop-only: 5px;

// Checkout Card Footer
$margin-top-container-checkout-card-footer: 25px;

// Checkout Card Footer Button
$margin-right-button-checkout-card-footer: 10px;

// Checkout Card Save Button
%button-checkout-card-save {
    @extend %button-primary;

    &:disabled,
    &.disabled {
        @extend %button-primary-disabled;
    }
}

// Checkout Card Cancel Button
%button-checkout-card-cancel {
    @extend %button-secondary;

    &:disabled,
    &.disabled {
        @extend %button-secondary-disabled;
    }
}

// Checkout Card Address
$margin-bottom-container-checkout-card-address__mobile: 35px;

// Checkout Card Address Add Button
$top-button-checkout-card-address-add: -5px;
$top-button-checkout-card-address-add__mobile: calc(100% + 15px);

%button-checkout-card-address-add {
    @extend %button;

    &:disabled,
    &.disabled {
        @extend %button-disabled;
    }
}

// Checkout Card Address Item
$width-container-checkout-card-address-item__xl-only: calc(100% - 95px);
$margin-left-container-checkout-card-address-item__desktop-only: -20px;

// Checkout Card Delivery
$max-width-container-checkout-card-delivery: 275px;

// Checkout Card Payment
$margin-container-checkout-card-payment: -10px;

// Checkout Card Payment Content
$margin-container-checkout-card-payment-content: 10px;

// Checkout Card Payment Heading
$margin-bottom-heading-checkout-card-payment: 10px;

%heading-checkout-card-payment {
    @extend %heading-h6;
}

// Checkout Card Payment Error
$margin-alert-checkout-card-payment-error: 0 10px;

// Checkout Card Gift
$margin-top-container-checkout-card-gift: 30px;
$margin-top-container-checkout-card-gift__mobile: 15px;
$margin-bottom-container-checkout-card-gift__mobile: 50px;

// Checkout Card Gift Error
$margin-bottom-alert-checkout-card-gift-error: 15px;

// Checkout Card Gift List
$margin-top-container-checkout-card-gift-list: 15px;

// Checkout Card Gift Item
$margin-bottom-container-checkout-card-gift-item: 5px;

// Checkout Card Gift Apply Button
$margin-top-button-checkout-card-gift-apply: 10px;

%button-checkout-card-gift-apply {
    @extend %button;

    &:disabled,
    &.disabled {
        @extend %button-disabled;
    }
}

// Checkout Card Gift Remove Button
%button-checkout-card-gift-remove {
    @extend %button;

    &:disabled,
    &.disabled {
        @extend %button-disabled;
    }
}

// Checkout Card Date of Birth Error
%alert-checkout-card-dob-error {
    @extend %text;
    @extend %text-small;
}

// Checkout Card Terms of Conditions
$margin-bottom-container-checkout-card-toc: 20px;
$margin-top-radio-checkout-card-toc: 1px;
$line-height-radio-checkout-card-toc: 20px;

// Checkout Line Items
$border-top-container-checkout-line-items: 1px solid $color-border-accent;

// Checkout Line Items Text
%text-checkout-line-items {
    @extend %text;
    @extend %text-small;
}

// Checkout Line Items Heading
$width-heading-checkout-line-items: calc(100% - 110px);
$width-heading-checkout-line-items__mobile: calc(100% - 95px);

%heading-checkout-line-items {
    @extend %heading-h5;
}

// Checkout Line Items Button
%button-checkout-line-items {
    @extend %button;
    @extend %button-underlined;

    &.disabled,
    &:disabled {
        @extend %button-disabled;
    }
}

// Checkout Line Items List
$margin-top-container-checkout-line-items-list: 35px;

// Checkout Line Items List Title
%heading-checkout-line-items-list {
    @extend %text-appellation;
}

// Checkout Line Item
$margin-bottom-container-checkout-line-item: 15px;

// Checkout Line Item Content
$margin-right-container-checkout-line-item-content: 15px;

// Checkout Line Item Product
$line-height-text-checkout-line-item-product: 1.3;
$lines-text-checkout-line-item-product: 2;

%text-checkout-line-item-product {
    @include text-line-height($line-height-text-checkout-line-item-product);
}

// Checkout Line Item Quantity
$width-container-checkout-line-item-quantity: 40px;

// Checkout Line Item Quantity Prefix
$content-prefix-checkout-line-item-quantity: 'x';
$margin-right-prefix-checkout-line-item-quantity: 3px;

// Checkout Line Item Price
$width-container-checkout-line-item-price: 100px;
$width-container-checkout-line-item-price__desktop: 60px;
$width-container-checkout-line-item-price__tablet: 100px;
$width-container-checkout-line-item-price__mobile: 60px;
$margin-bottom-container-checkout-line-item-price: 15px;
$text-align-container-checkout-line-item-price: right;

// Checkout Line Item Savings
$content-text-checkout-line-item-savings: 'savings';
$top-text-checkout-line-item-savings: 18px;
$margin-left-text-checkout-line-item-savings: 3px;
$font-size-text-checkout-line-item-savings: 11px;

%text-checkout-line-item-savings {
    @extend %text;
    @extend %text-accent;
    @include text-font-size($font-size-text-checkout-line-item-savings);
}

// Checkout Prop 65 Icon
$max-width-icon-checkout-p65: 50px;
$margin-right-icon-checkout-p65: 15px;

%text-checkout-p65 {
    @extend %text-appellation;
    @extend %text-appellation-accent;
}
